import printer from "../../../views/pages/printerManagement/index.vue";

export default [
  {
    path: "printers-management",
    name: "printer_management",
    component: printer,
    meta: {
      headerClass: "header",
      auth_roles: ["admin", "hr_manager"],
      auth_type: ["pos_user"],
    },
  },
];
