import { createRouter, createWebHistory } from "vue-router";
import routes from "./routes";
import swal from "sweetalert2";

export const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "active",
  routes,
});

// Before each route evaluaptes...
router.beforeEach((routeTo, _, next) => {
  if (routeTo.path == "/login" && localStorage["pos_station"]) {
    router.push("/dashboard/home");
    return;
  } 
  else if (routeTo.path == "/login" && !localStorage["pos_station"]) {
    next();
  }
  else if(
    routeTo.path =='/dashboard/accounting' && JSON.parse(localStorage.pos_station)?.pos_station?.has_full_accounting ==0||
    routeTo.path =="/dashboard/floors" && JSON.parse(localStorage.pos_station)?.pos_station?.has_floors == 0 ||
    (routeTo.path.includes("/dashboard/pos-kitchen/") || routeTo.path =="/dashboard/kitchens-management" )  && JSON.parse(localStorage.pos_station)?.pos_station?.pos_type?.id != 2
    ){
    router.push("/dashboard/home");
    return;
  }
  const publicPages = ["/login", "/forgot-password"];
  const authPage = !publicPages.includes(routeTo.path);
console.log("path :",routeTo.path)
  console.log("authPage", authPage);


  if(!authPage){
    console.log("public page")
    next()
  }else if (authPage && !localStorage.pos_station) {
    console.log(" step cond ");
    router.push("/login");
    // next();
  } 
  else if (localStorage.pos_station) {
    console.log("ok 1");
    const decoded_user_data = JSON.parse(localStorage.pos_station);
    console.log("decoded_user_data", decoded_user_data);
    const user_roles = decoded_user_data?.roles.map(
      (item) => item.pos_role.code
    );
    const page_roles = routeTo.meta.auth_roles;
    const auth_type = routeTo.meta.auth_type;
    let auth_status = false;
    console.log({ route_meta: routeTo.meta });
    for (let i = 0; i < user_roles?.length; i++) {
      if (
        page_roles?.includes(user_roles[i]) &&
        auth_type.includes("pos_user")
      ) {
        // console.log('auth_type == "pos_user"', auth_type.includes("pos_user"));
        auth_status = true;
        break;
      } else {
        auth_status = false;
      }
    }
    if (auth_status) {
      console.log("auth complete");
      
      next();
    } else {
      console.log("blocked")
      router.push("/dashboard/home");
      // setTimeout(() => {
      //   swal.fire({
      //     position: "top-end",
      //     icon: "error",
      //     title: "You are not allowed to go here",
      //     showConfirmButton: false,
      //     timer: 1500,
      //   });
      // }, 1000);
    }
  }

  
});

export default router;
