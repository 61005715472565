import hrList from "../../../views/pages/hr List/hrList.vue";

export default [
  {
    path: "hrList",
    name: "hrList",
    component: hrList,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      auth_roles: ["admin", "hr_manager"],
      auth_type: ["pos_user"],
    },
  },
];
