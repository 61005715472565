<template>
  <!-- Main Wrapper -->
  <div class="modal fade" id="add-supplier" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add Supplier</h5>
          <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="addSupplier()">
              <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="  col-12">
                  <div class="form-group">
                    <label>Supplier Name</label>
                    <input v-model="name" type="text" />
                  </div>
                </div>
                <div class="  col-12">
                  <div class="form-group">
                    <label>Email</label>
                    <input v-model="email" type="text" />
                  </div>
                </div>
                <div class="  col-12">
                  <div class="form-group">
                    <label>Phone</label>
                    <input v-model="tel" type="text" />
                  </div>
                </div>
               
           
              </div>
            </div>
          </div>
          <div class="col-lg-12">
            <button type="submit" class="btn btn-submit me-2">Submit</button>
            <a class="btn btn-cancel" data-bs-dismiss="modal">Cancel</a>
          </div>
          </form>
        
        </div>
      </div>
    </div>
  </div>

</template>
<script>
export default {
  data() {
    return {
      title: "Supplier Management",
      title1: "Add/Update Customer",
      Country: ["Choose Country", "India", "USA"],
      City: ["Choose City", "City 1", "City 2"],
      name :"",
      email : "",
      tel : ""
    };
  },
  methods:{
   async addSupplier(){
      const data = {
        name : this.name,
        email : this.email,
        tel : this.tel
      }
      const response = await this.http.post("pos-suppliers" , data, null, true)
      setTimeout(() => {
        document.querySelector(".btn-cancel").click()
        
      }, 200);
      this.name =""
      this.email = ""
      this.tel = ""
      console.log(response);
    }
  },
  name: "addsupplier",
};
</script>
