<template>
    <!-- Main Wrapper -->

    <pageaddheader :title="title" />

    <!-- /product list -->
    <div class="card">
        <div class="card-body">
            <div class="table-top">
                <div class="search-set">

                    <div class="search-input">
                        <a class="btn btn-searchset"><img src="/assets/img/icons/search-white.svg" alt="img" /></a>
                        <div id="DataTables_Table_0_filter" class="dataTables_filter">
                            <label>
                                <input type="search" v-model="search_term"
                                    @input="search({ search: search_term, col: 'name' })"
                                    class="form-control form-control-sm" placeholder="Search..."
                                    aria-controls="DataTables_Table_0" />
                            </label>
                        </div>
                    </div>
                </div>
                <div class="wordset">
                    <a @click="OpenPrinterModal()" class="btn btn-add-modal" href="javascript:void(0);">
                        <vue-feather type="plus" />
                    </a>
                </div>
            </div>
            <!-- /Filter -->
            <div class="card" id="filter_inputs" :style="{
                display: filter ? 'block' : 'none',
            }">
                <div class="card-body pb-0">
                    <div class="row">
                        <div class="col-lg-2 col-sm-6 col-12">
                            <div class="form-group">
                                <input type="text" placeholder="Enter product Code" />
                            </div>
                        </div>
                        <div class="col-lg-2 col-sm-6 col-12">
                            <div class="form-group">
                                <input type="text" placeholder="Enter product" />
                            </div>
                        </div>
                        <div class="col-lg-2 col-sm-6 col-12">
                            <div class="form-group">
                                <input type="text" placeholder="Enter Phone" />
                            </div>
                        </div>
                        <div class="col-lg-2 col-sm-6 col-12">
                            <div class="form-group">
                                <input type="text" placeholder="Enter Email" />
                            </div>
                        </div>
                        <div class="col-lg-1 col-sm-6 col-12 ms-auto">
                            <div class="form-group">
                                <a class="btn btn-filters ms-auto"><img src="/assets/img/icons/search-whites.svg"
                                        alt="img" /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- /Filter -->
            <table style="table-layout: auto">
                <thead class="ant-table-thead">
                    <tr>


                        <th class="ant-table-cell " colstart="2" colend="2">
                            <!----><!---->
                            <span class="ant-table-column-title">{{
                                $t("tables.pos_printer.no")
                            }}</span>
                            <!---->
                        </th>
                        <th class="ant-table-cell " colstart="2" colend="2">
                            <!----><!---->
                            <span class="ant-table-column-title">{{
                                $t("tables.pos_printer.name")
                            }}</span>
                            <!---->
                        </th>

                        <th class="ant-table-cell " colstart="2" colend="2">
                            <!----><!---->
                            <span class="ant-table-column-title">{{
                                $t("tables.pos_printer.kitchen")
                            }}</span>
                            <!---->
                        </th>
                        <th class="ant-table-cell " colstart="2" colend="2">
                            <!----><!---->
                            <span class="ant-table-column-title">{{
                                $t("tables.pos_printer.created")
                            }}</span>
                            <!---->
                        </th>
                        <th class="ant-table-cell " colstart="2" colend="2">
                            <!----><!---->
                            <span class="ant-table-column-title">{{
                                $t("tables.pos_printer.action")
                            }}</span>
                            <!---->
                        </th>






                    </tr>
                </thead>
                <tbody class="ant-table-tbody">
                    <!---->
                    <tr v-for="(printerData, index) in printers" :key="printerData.id" class="ant-table-row ant-table-row-level-0">


                        <td class="ant-table-cell">
                            <!---->{{ index + 1
                            }}<!---->
                        </td>


                        <td class="ant-table-cell">
                            <!---->{{ printerData?.name
                            }}<!---->
                        </td>
                        <td class="ant-table-cell">
                            <!---->{{ printerData?.pos_kitchens[0]?.name
                            }}<!---->
                        </td>
                        <td class="ant-table-cell">
                            <!---->{{ new Date(printerData?.created).toLocaleString("en-GB")
                            }}<!---->
                        </td>

                        <td style="gap: 10px;" class="ant-table-cell d-flex justify-content-start align-item-center">

                            <a class="" @click="OpenPrinterEditModal(printerData)" href="javascript:void(0);">
                                <vue-feather type="edit" stroke="black" />
                            </a>

                            <a @click="deleteItem(printerData)" href="javascript:void(0);">
                                <vue-feather type="trash" stroke="red" />
                            </a>
                        </td>
                    </tr>

                    <!---->
                </tbody>
                <!---->
            </table>

            <nav class="flex justify-center my-4" v-if="tot > 1">
                <ul class="inline-flex -space-x-px text-base h-10">
                    <li v-for="page_number in tot" @click="handlePagination(page_number)" :key="page_number">
                        <a :class="{
                            'bg-blue-600 text-white hover:text-gray-100 hover:bg-blue-900 ':
                                page_number == page,
                        }"
                            class="flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">{{
                                page_number }}</a>
                    </li>
                </ul>
            </nav>
            <a-pagination class="d-flex justify-content-center mt-4" @change="onChange" v-model:current="page"
                v-model:pageSize="limit" :total="total" />
        </div>
    </div>




    <!-- Modal trigger button -->


    <!-- Modal Body -->
    <div class="modal fade" id="PrinterModal" tabindex="-1" role="dialog" aria-labelledby="modalTitleId" aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-md" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalTitleId">
                        {{ $t("menu.operations.items.printer") }}
                    </h5>
                </div>
                <div class="modal-body">

                    <div class="mb-3">
                        <label for="" class="form-label">{{ $t("tables.pos_printer.name") }}</label>
                        <input type="text" class="form-control" v-model="printer.name" id="" aria-describedby="helpId"
                            placeholder="" />

                    </div>
                    <div class="mb-3">


                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" v-model="printer.kitchenRadio" :value="true" id="" />
                            <label class="form-check-label" for="">{{ $t("tables.pos_printer.kitchen") }}</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" checked v-model="printer.kitchenRadio" :value="false" id="" />
                            <label class="form-check-label" for="">{{ $t("tables.pos_printer.cashier") }}</label>
                        </div>
                    </div>
                    <div class="mb-4" v-if="printer?.kitchenRadio">
                        <label for="" class="form-label">{{ $t("tables.pos_printer.kitchen") }}</label>
                        <select class="form-select " v-model="printer.kitchen" name="" id="">
                            <option v-for="(pos_kitchen, index) in pos_kitchens" :key="index" :value="pos_kitchen.id">
                                {{ pos_kitchen.name }}</option>

                        </select>
                    </div>

                    <div class="input-group mb-3">
                        <input type="text" class="form-control" disabled placeholder="HP Thermal 1432"
                            aria-label="Recipient's username" aria-describedby="button-addon2">
                        <button class="btn btn-outline-secondary" type="button" id="button-addon2">  <vue-feather type="search" stroke="black" /> </button>
                    </div>






                </div>
                <div class="modal-footer">
                    <button @click="add()" type="button" class="btn btn-primary">{{ $t("popups.add") }}</button>
                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">
                        {{ $t("popups.cancel") }}
                    </button>
                </div>
            </div>
        </div>
    </div>


    <!-- edit Modal  -->
    <div class="modal fade" id="PrinterEditModal" tabindex="-1" role="dialog" aria-labelledby="modalTitleId" aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-md" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalTitleId">
                        {{ $t("menu.operations.items.printer") }}
                    </h5>
                </div>
                <div class="modal-body">

                    <div class="mb-3">
                        <label for="" class="form-label">{{ $t("tables.pos_printer.name") }}</label>
                        <input type="text" class="form-control" v-model="printer.name" id="" aria-describedby="helpId"
                            placeholder="" />

                    </div>
                    <div class="mb-3">


                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" v-model="printer.kitchenRadio" :value="true" id="" />
                            <label class="form-check-label" for="">{{ $t("tables.pos_printer.kitchen") }}</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" checked v-model="printer.kitchenRadio" :value="false" id="" />
                            <label class="form-check-label" for="">{{ $t("tables.pos_printer.cashier") }}</label>
                        </div>
                    </div>
                    <div class="mb-4" v-if="printer?.kitchenRadio">
                        <label for="" class="form-label">{{ $t("tables.pos_printer.kitchen") }}</label>
                        <select class="form-select " v-model="printer.kitchen" name="" id="">
                            <option v-for="(pos_kitchen, index) in pos_kitchens" :key="index" :value="pos_kitchen.id">
                                {{ pos_kitchen.name }}</option>

                        </select>
                    </div>

                    <div class="input-group mb-3">
                        <input type="text" class="form-control" disabled placeholder="HP Thermal 1432"
                            aria-label="Recipient's username" aria-describedby="button-addon2">
                        <button class="btn btn-outline-secondary" type="button" id="button-addon2">  <vue-feather type="search" stroke="black" /> </button>
                    </div>






                </div>
                <div class="modal-footer">
                    <button @click="edit(this.printer)"  type="button" class="btn btn-primary">{{ $t("popups.edit") }}</button>
                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">
                        {{ $t("popups.cancel") }}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!-- Optional: Place to the bottom of scripts -->
</template>
  
<script>
import { Modal } from "bootstrap"

export default {
    data() {
        return {
            page: 1,
            limit: 10,
            total: 0,
            filter: false,
            title: this.$t("menu.operations.items.printers"),
            PrinterModal: null,
            PrinterEditModal :null,
            mainPage: null,
            printers: [],
            printer: {},
           
            data: {},
            categories: [],
            pos_kitchens: [],
            pos_station_id: null,
            pos_station: {}
        };
    },
    methods: {
        async deleteItem(record) {
            // Use sweetalert2
            this.$swal
                .fire({
                    title: this.$t("messages.alert.are_you_sure"),
                    text: this.$t("messages.alert.unrevertable"),
                    showCancelButton: true,
                    confirmButtonColor: "#FF9F43",
                    cancelButtonColor: "#d33",
                    confirmButtonText:   this.$t("popups.delete"),
                })
                .then((result) => {
                if (result.isConfirmed) {
                        this.http.delete(`pos-printers`, record.id).then((res) => {
                            this.getPrinters();
                            this.getPosKitchensSelect();
                            this.$swal.fire(this.$t("messages.deleted"));
                        });
                    }
                });
        },
        getPosKitchensSelect(printer) {
            const loggeduser = localStorage.pos_station;
            const decoded_user_data = JSON.parse(loggeduser);
            this.http.get('pos-kitchens/select', {
                printer: true
            }, null, true).then((res) => {
                this.pos_kitchens = res.data
                if(printer)
                this.pos_kitchens.unshift(printer)
                console.log('pos kitchen :', this.pos_kitchens)
            })
        },
        OpenPrinterModal() {
            
            this.printer ={}
            this.getPosKitchensSelect()
            
            this.PrinterModal.show()
        },
     
        add(){
            console.log("printer :",this.printer)
            this.http.post("pos-printers",this.printer,null,true).then((res)=>{
                console.log("res :", res )
                this.PrinterModal.hide()
                this.getPrinters()
            })
        },

        OpenPrinterEditModal(record){
            if(record.pos_kitchens[0]){
                this.getPosKitchensSelect(record.pos_kitchens[0])
                console.log("REDDD",record);
                record.kitchenRadio =true
                record.kitchen= record.pos_kitchens[0].id
                console.log(" kitchen",record)
                // record.kitchen = record.pos_kitchens[0].id
            }
            else{
                this.getPosKitchensSelect()
                record.kitchenRadio =false

            }

            this.printer =Object.assign({}, record);
            this.PrinterEditModal.show()
        },
         edit(record) {
            console.log("edit record :",record)
            this.http.put('pos-printers',record.id, record).then((res)=>{
                if(res){
                    
                    Swal.fire(this.$t("messages.success"))
                    this.PrinterEditModal.hide()
                       this.getPrinters()  

                }
            })

        },

        setModalData(product) {
            // for make edit updated after update data
            this.data = Object.assign({}, product);
        },
        async getPrinters() {
            this.http
                .post(`pos-printers/paginate`, {
                    page: this.page,
                    limit: this.limit,
                }, null, true)
                .then((res) => {
                    this.total = res.tot;
                    this.printers = res.data;
                });
        },
        search(options) {
            this.http
                .post(`pos-printers/search`, {
                    col: options.col,
                    search: options.search,
                    page: 1,
                    limit: 25,
                    lang: localStorage.current_language,
                    pos_station_id : this.$store.state.currentUser.pos_station_id

                })
                .then((res) => {
                    this.printers = res.data;
                });
        },
        onChange(pageNumber, pageSize) {
            console.log("page :", pageNumber, "total :", pageSize);
            this.getPrinters(); /* add your function that render you table data */
        },

        handlePagination(page) {
            this.page = page;
            this.get();
        },
    },
    async mounted() {
        
        const modal = new Modal("#PrinterModal")
        const modal2 = new Modal("#PrinterEditModal")
        this.PrinterModal =modal
        this.PrinterEditModal =modal2
        this.pos_station = JSON.parse(localStorage.pos_station)
        console.log("pos station store :", this.pos_station)
        this.getPrinters();


        console.log("main page :", this.mainPage)
        //   this.mainPage= this.mainPage.find((e) => {if(e.id ==this.$route.params.id){return e}
        // } )
        console.log("category 1 :", this.mainPage)
        this.getPosKitchensSelect()
    },
    name: "productlist",
};
</script>
  