<template>
  <!-- Header -->
  <div :class="$route.meta.headerClass">
    <headerlogo1 />

    <RouterLink id="mobile_btn" class="mobile_btn" to="/">
      <span class="bar-icon">
        <span></span>
        <span></span>
        <span></span>
      </span>
    </RouterLink >

    <headermenu />

    <mobilemenu />
  </div>
  <!-- Header -->
</template>
<script>
export default {
  components: {},
};
</script>
