import { createStore } from "vuex";

import modules from "./modules";

const store = createStore({
  state: {
    baseUrl: "https://smartpos.smart-node.net/api/",
    mediaUrl: "https://smartpos.smart-node.net/",
    serverUrl: "https://smartpos.smart-node.net/",
    // baseUrl: "http://localhost:5000/api/",
    // mediaUrl: "http://localhost:5000",
    // serverUrl: "http://localhost:5000",

    currentUser: {},
    token: null,
  },
  mutations: {
    SET_AUTHENTICATED(state, value) {
      state.authenticated = value;
    },
    SET_USER(state, value) {
      console.log(state.user);
      state.user = value;
    },
    addUserData(state, value) {
      state.currentUser = value;

      localStorage.setItem("pos_station", JSON.stringify(value));
      console.log("userData :", state.currentUser);
    },
  },
  actions: {},
  modules,
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== "production",
});

export default store;
