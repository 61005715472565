<template>
  <!-- Main Wrapper -->

  
        <pageaddheader :title="title" :title1="title1" />

        <!-- /product list -->
        <div class="card">
          <div class="card-body">
            <div class="table-top">
              <div class="search-set">
                
                <div class="search-input">
                  <a class="btn btn-searchset"><img src="/assets/img/icons/search-white.svg" alt="img" /></a>
                  <div id="DataTables_Table_0_filter" class="dataTables_filter">
                    <label>
                      <input type="search" v-model="search_term" @input="search({ search: search_term, col: 'name' })"
                        class="form-control form-control-sm" placeholder="Search..." aria-controls="DataTables_Table_0" />
                    </label>
                  </div>
                </div>
              </div>
              <div class="wordset">
                <a class="btn btn-add-modal" href="javascript:void(0);" data-bs-toggle="modal"
                  data-bs-target="#add-supplier">
                  <vue-feather type="plus" />
                </a>
              </div>
            </div>
            <!-- /Filter -->
            
            <!-- /Filter -->
            <table style="table-layout: auto">
              <thead class="ant-table-thead">
                <tr>
                  

                  <th class="ant-table-cell ant-table-column-has-sorters" colstart="2" colend="2">
                    <!----><!---->
                    <span class="ant-table-column-title">{{
                      $t("purchases.suppliers.name")
                    }}</span>
                    <!---->
                  </th>
                  <th class="ant-table-cell ant-table-column-has-sorters" colstart="3" colend="3">
                    <!----><!---->
                    <span class="ant-table-column-title">{{
                      $t("purchases.suppliers.email")
                    }}</span>

                    <!---->
                  </th>

                  <th class="ant-table-cell ant-table-column-has-sorters" colstart="8" colend="8">
                    <!----><!---->
                    <span class="ant-table-column-title">{{
                      $t("purchases.suppliers.phone")
                    }}</span>
                    <!---->
                  </th>

                  <th class="ant-table-cell ant-table-column-has-sorters" colstart="8" colend="8">
                    <!----><!---->
                    <span class="ant-table-column-title">{{
                      $t("purchases.suppliers.actions")
                    }}</span>
                    <!---->
                  </th>
                </tr>
              </thead>
              <tbody class="ant-table-tbody">
                <!---->
                <tr v-for="supplier in suppliers" :key="supplier.id" class="ant-table-row ant-table-row-level-0">

                  <td class="ant-table-cell">
                    <!---->{{ supplier?.name
                    }}<!---->
                  </td>

                  <td class="ant-table-cell">
                    <!---->{{ supplier?.email
                    }}<!---->
                  </td>

                  <td class="ant-table-cell">
                    <!---->{{ supplier?.tel
                    }}<!---->
                  </td>

                  <td class="ant-table-cell d-flex justify-content-start">
                    <a @click="setModalData(supplier)" href="javascript:void(0);" data-bs-toggle="modal"
                      data-bs-target="#edit-supplier">
                      <vue-feather type="edit" stroke="black" />
                    </a>

                    <a class="mx-2" href="javascript:void(0);" @click="deleteItem(supplier)">
                      <vue-feather type="trash" stroke="red" />
                    </a>
                  </td>
                </tr>

                <!---->
              </tbody>
              <!---->
            </table>

            <a-pagination class="d-flex justify-content-center mt-4" @change="onChange" v-model:current="page"
              v-model:pageSize="limit" :total="total" />
          </div>
        </div>
        <!-- /product list -->

        <!-- add modal -->

        <!-- Main Wrapper -->
        <div class="modal fade" id="add-supplier" tabindex="-1" aria-hidden="true">
          <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">
                  {{ this.$t("purchases.suppliers.add") }}
                </h5>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div class="modal-body">
                <form @submit.prevent="add()">
                  <div class="card">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12">
                          <div class="form-group">
                            <label>{{
                              this.$t("purchases.suppliers.name")
                            }}</label>
                            <input v-model="data.name" type="text" />
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="form-group">
                            <label>{{
                              this.$t("purchases.suppliers.email")
                            }}</label>
                            <input v-model="data.email" type="text" />
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="form-group">
                            <label>{{
                              this.$t("purchases.suppliers.phone")
                            }}</label>
                            <input v-model="data.tel" type="text" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <button type="submit" class="btn btn-submit me-2">
                      {{ $t("popups.submit") }}
                    </button>
                    <a class="btn btn-cancel add-cancel" data-bs-dismiss="modal">{{ $t("popups.cancel") }}</a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <!-- end add modal -->

        <!-- start add modal -->
        <!-- Main Wrapper -->
        <div class="modal fade" id="edit-supplier" tabindex="-1" aria-hidden="true">
          <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">
                  {{ this.$t("purchases.suppliers.edit") }}
                </h5>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div class="modal-body">
                <form @submit.prevent="edit()">
                  <div class="card">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12">
                          <div class="form-group">
                            <label>{{
                              this.$t("purchases.suppliers.name")
                            }}</label>
                            <input v-model="data.name" type="text" />
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="form-group">
                            <label>{{
                              this.$t("purchases.suppliers.email")
                            }}</label>
                            <input v-model="data.email" type="text" />
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="form-group">
                            <label>{{
                              this.$t("purchases.suppliers.phone")
                            }}</label>
                            <input v-model="data.tel" type="text" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <button type="submit" class="btn btn-submit me-2">
                      {{ $t("popups.submit") }}
                    </button>
                    <a class="btn btn-cancel edit-cancel" data-bs-dismiss="modal">{{ $t("popups.cancel") }}</a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <!-- end edit modal -->

  <addsuppliers />
  <supplierlistmodal></supplierlistmodal>
</template>

<script>
import addsuppliers from "./addsupplier.vue";
import jwt_decode from "jwt-decode";

export default {
  components: { addsuppliers },
  data() {
    return {
      page: 1,
      limit: 10,
      total: 0,
      filter: false,
      title: this.$t("purchases.suppliers.title"),
      title1: this.$t("purchases.suppliers.sub_title"),
      suppliers: [],
      data: {},
    };
  },
  methods: {
    async deleteItem(supplier) {
      // Use sweetalert2
      this.$swal
        .fire({
          title: this.$t("messages.alert.are_you_sure"),
          text: this.$t("messages.alert.unrevertable"),
          showCancelButton: true,
          confirmButtonColor: "#FF9F43",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.http.delete("pos-suppliers", supplier.id).then((res) => {
              this.getSuppliers();
              this.$swal.fire("Deleted!", "Your file has been deleted.");
            });
          }
        });
    },

    async add() {
      const loggeduser = localStorage.pos_station;
      const decoded_user_data = JSON.parse(loggeduser);
      this.data.pos_station_id = decoded_user_data?.pos_station_id
      const response = await this.http.post("pos-suppliers", this.data, null, true);
      this.getSuppliers();

      setTimeout(() => {
        document.querySelector(".btn-cancel").click();
      }, 200);

      this.data = {};
      console.log(response);
    },

    async edit() {
      this.http.put("pos-suppliers", this.data.id, this.data).then((res) => {
        Swal.fire(this.$t("messages.success"))

        console.log("put");
        this.getSuppliers();

        setTimeout(() => {
          document.querySelector(".btn-cancel").click();
        }, 200);
        console.log(res);
      });
    },
    setModalData(supplier) {
      // for make edit updated after update data
      this.data = Object.assign({}, supplier);
    },
    async getSuppliers() {
      const loggeduser = localStorage.pos_station;
      const decoded_user_data = JSON.parse(loggeduser);
      this.http
        .post(`pos-suppliers/paginate`, {
          page: this.page,
          limit: this.limit,
          pos_station_id: decoded_user_data?.pos_station_id

        })
        .then((res) => {
          this.total = res.tot;
          this.suppliers = res.data;
        });
    },
    search(options) {
      const loggeduser = localStorage.pos_station;
      const decoded_user_data = JSON.parse(loggeduser);
      this.http
        .post(`pos-suppliers/search`, {
          col: options.col,
          search: options.search,
          page: 1,
          limit: 25,
          lang: localStorage.current_language,
          pos_station_id: decoded_user_data?.pos_station_id

        })
        .then((res) => {
          this.suppliers = res.data;
        });
    },
    onChange(pageNumber, pageSize) {
      console.log("page :", pageNumber, "total :", pageSize);
      this.getSuppliers(); /* add your function that render you table data */
    },
  },
  created() {
    this.getSuppliers();
  },
  name: "supplierlist",
};
</script>
