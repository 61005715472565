export default [
  {
    path: "products_list",
    component: () => import("../../views/pages/products/productList.vue"),
    meta: {
      headerClass: "header",
      sidebarClass: "",
      auth_roles: ["admin", "manager"],
      auth_type: ["pos_user"],
    },
  },
  {
    path: "pos_products",
    component: () => import("../../views/pages/products/PosProducts.vue"),
    meta: {
      headerClass: "header",
      sidebarClass: "",
      auth_roles: ["admin", "manager"],
      auth_type: ["pos_user"],
    },
  },
];
