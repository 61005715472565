<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <headerpos></headerpos>
    <div class="page-wrapper ms-0">
      <div class="content">
        <div class="row">
          <div class="col-lg-12 col-sm-12 tabs_wrapper">
            <div class="page-header">
              <div class="page-title">
                <h4>{{ this.$route.params.type_id == 1 ? this.$t("pos_kitchen.pos_kitchen.title") :
                  this.$route.params.type_id == 2 ? this.$t("pos_kitchen.pos_bar.title") : "" }}</h4>
                <h6>{{ this.$route.params.type_id == 1 ? this.$t("pos_kitchen.pos_kitchen.subtitle") :
                  this.$route.params.type_id == 2 ? this.$t('pos_kitchen.pos_bar.subtitle') : "" }}</h6>
              </div>
              <div class="page-button">
                <button class="btn btn-lg btn-primary " :class="this.order_basket.length == 0 ? 'disabled' : ''"
                  @click="saleOrderDetailsCheck(this.order_basket)"> add </button>
              </div>
            </div>

            <div class="tabs_container">
              <div data-tab="fruits">
                <div class="row">
                  <div v-for="(item, index) in sale_order_details" :key="index" class="col-lg-3 col-sm-6 d-flex">
                    <div @click="addItem(item, index)" class="productset flex-fill" ref="product_set"
                      :class="item.class ? 'active ' : ''">
                      <div class="productsetimg">
                        <img :src="state.mediaUrl + item.products_img?.split('public/')[1]" alt="img" />
                        <!-- <h6>Qty: 5.00 </h6> -->
                        <div class="check-product">
                          <i class="fa fa-check"></i>
                        </div>
                      </div>
                      <div class="productsetcontent">
                        <!-- <h5>Fruits</h5> -->
                        <h4>{{ item.products_name }}</h4>
                        <h4> Sale Order No : {{ item.sale_order_details_sale_order_id }}</h4>
                        <h4> Quantity : {{ item.sale_order_details_qty }} </h4>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  <posmodal></posmodal>

  <div v-if="pos_price_list" class="floating-btn">

    <a type="button" href="javascript:void(0);"><i class=" "></i> {{ pos_price_list.name }} </a>

  </div>
</template>
<script>


import store from "@/state/store";
var state = store.state;

export default {
  setup() {
    //const count = ref(1);
    // const increment = async (index) => {
    //   order_basket[index].qty++;
    // };
    // const decrement = async (index) => {
    //   this.order_basket[index].count--;
    // };

    return {};
  },
  data() {
    return {
      active_id: 1,
      product_active_id: "",
      categories: [],
      order_basket: [],
      pos_products: [],
      sale_order_details: [],
      pos_price_list: [],
      Product: ["Product", "Barcode"],
      Walk: ["Walk-in Customer", "Chris Moris"],
      settings: {
        itemsToShow: 2,
        snapAlign: "center",
      },
      state: state


    };
  },
  mounted() {
    this.getSaleOrderDetails();
    this.getProductPriceList();

    console.log('routes params', this.$route.params.type_id)





    console.log("first active_id :", this.active_id);

  },
  computed: {


  },
  methods: {
    getSaleOrderDetails() {
      const loggeduser = localStorage.pos_station;
      const decoded_user_data = JSON.parse(loggeduser);
      this.http.get(`pos-sale-order-details?pos_station_id=${decoded_user_data.pos_station_id}&pos_kitchen_types_id=${this.$route.params.type_id}&`, null, null, true).then((res) => {

        this.sale_order_details = res.data
        console.log('sales :', this.sale_order_details)

      })
    },
    getProductPriceList() {
      const loggeduser = localStorage.pos_station;
      const decoded_user_data = JSON.parse(loggeduser);
      console.log("user data :", decoded_user_data);
      this.http
        .get(
          `pos-stations-price-list/pos-stations/${decoded_user_data.pos_station_id}`
        )
        .then((res) => {
          this.pos_price_list = res.data;
          console.log("price list :", this.pos_price_list);
          console.log("refs :", this.$refs.product_set)


          this.getProductsByCategoryId(1);
        });
    },
    changeActiveId(id) {
      console.log("id :", id);
      this.active_id = id;
      console.log("active id :", this.active_id);
    },


    getProductsByCategoryId(id, index) {
      this.active_id = id;
      this.categories.map((e, index) => {
        console.log("index :", index);
        e.class = this.active_id === e.id ? "active" : "";
        console.log("pos items :", e);
      });
      this.http.get(`products/category/${id}`, null, null, true).then((res) => {
        console.log("categories : ", res.data);
        this.pos_products = res.data;
        this.pos_products.map((e) => {
          e.class = "";
        });

      });
    },
    addItem(item, index) {
      var result = this.$refs.product_set[index].classList.toggle('active')
      if (result) {
        this.order_basket.push(item)
        console.log("basket :", this.order_basket)
      }
      else {
        this.order_basket = this.order_basket.filter((obj) => obj.sale_order_details_id !== item.sale_order_details_id);
        console.log("basket :", this.order_basket)
      }
    },

    saleOrderDetailsCheck(order) {
      Swal.fire({
        title: this.$t("messages.alert.are_you_sure"),
        text: this.$t("messages.alert.unrevertable"),
        showCancelButton: true,
        confirmButtonColor: "#FF9F43",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t("messages.alert.complete_it"),
      }).then((result) => {
        if (result.isConfirmed) {
          console.log("old order :", order);
          this.http.post(`pos-sale-order-details/checked`, order, null, true).then((res) => {
            console.log(res.data)
            this.order_basket = [];
            this.getSaleOrderDetails()
          })

        }
      });
    },

  },

  name: "pos",
};
</script>


<style scoped>
.page-button .btn {
  padding: 0.7rem 2.9rem;
  overflow: hidden;
  -webkit-transition-duration: 0.4s;
  /* Safari */
  transition-duration: 0.4s;
  position: relative;


}

.page-button .btn::after {
  content: "";
  background: #ffad5f;
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -55px !important;
  margin-top: -120%;
  opacity: 0;
  transition: all 0.5s ease-in;
  border-radius: 14.7px;
}

.page-button .btn:active::after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s
}

.price-list h3 {
  font-size: 20px;
  font-weight: 700;
  color: #5E5873;
  margin-bottom: 0;
}

.floating-btn {
  position: fixed;
  right: 4%;
  bottom: 3%;
  z-index: 999;
}

.floating-btn a {
  display: block;
  color: white;
  background-color: #5E5873;
  padding: 10px;
  border-radius: 10%;
  font-size: 17px;
  font-weight: 700;
}

.floating-btn a:hover {
  transform: scale(1.10);
  transition: 0.3s all ease-in;
}

.b-color {
  background-color: #ff9f43;
}
</style>