import store from "@/state/store";
import jwt_decode from "jwt-decode";
import { socket } from "@/socket";

export default {
  install(Vue) {
    var self = Vue.config.globalProperties;
    window.login = {
      loginUser(email, pass) {
        return new Promise((resolve) => {
          console.log("login clicked");
          self.http.post("pos-users/login", { email, pass }).then((res) => {
            if (res.status) {
              console.log("logged user step 10 :", res);
              socket.connect();
              let user_data = jwt_decode(res.data.token).user_data;
              user_data.pass = self.$e.encrypt(pass);
              
              store.commit("addUserData", user_data);
              window.loginDone = true;
              resolve(res);
            } else {
              resolve(res)
              // document.getElementById("email").innerHTML = "Email is not valid";
              console.log("gg error")
            }
          });

        });
      }
    }
    Vue.config.globalProperties.login = window.login;
  },
};
