import Index from "../../../views/pages/dashboard/index.vue";

export default [
  
  {
    path: "home",
    component: Index,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      auth_roles: ["admin", "hr_manager", "owner"],
      auth_type: ["pos_user", "user"],
    },
  },
];
