<template>
    <!-- Main Wrapper -->
  
          <pageaddheader @click.prevent="addPosEmployees()" :title="this.$t(title)" :items="items" />
  
          <!-- /product list -->
          <div class="card">
            <div class="card-body">
              <div class="table-top">
                <div class="search-set">
  
                  <div class="search-input">
                    <a class="btn btn-searchset"><img src="/assets/img/icons/search-white.svg" alt="img" /></a>
                    <div id="DataTables_Table_0_filter" class="dataTables_filter">
                      <label>
                        <input type="search" v-model="search" @keyup="searchPosEmployees({ search: search, col: 'name' })"
                          class="form-control form-control-sm" placeholder="Search..." aria-controls="DataTables_Table_0" />
                      </label>
                    </div>
                  </div>
                </div>
                <div class="wordset">
                  <ul>
                    <li>
                      <a data-bs-toggle="tooltip" data-bs-placement="top" title="pdf"><img src="/assets/img/icons/pdf.svg"
                          alt="img" /></a>
                    </li>
                    <li>
                      <a data-bs-toggle="tooltip" data-bs-placement="top" title="excel"><img
                          src="/assets/img/icons/excel.svg" alt="img" /></a>
                    </li>
                    <li>
                      <a data-bs-toggle="tooltip" data-bs-placement="top" title="print"><img
                          src="/assets/img/icons/printer.svg" alt="img" /></a>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- /Filter -->
  
              <!-- /Filter -->
              <div class="table-responsive">
                <a-table :columns="columns" :data-source="realData" :pagination="false">
                  <template #bodyCell="{ column, record }">
  
                    <template v-if="column.key === 'action'">
                      <a href="javascript:void(0);" @click.prevent="editPosEmployees(record)">
                        <img src="/assets/img/icons/edit.svg" alt="img" />
                      </a>
                      <a class="me-3 confirm-text" href="javascript:void(0);" @click="showAlert(record)">
                        <img src="/assets/img/icons/delete.svg" alt="img" />
                      </a>
                    </template>
                  </template>
                </a-table>
                <a-pagination class="d-flex justify-content-center mt-4" @change="onChange" v-model:current="page"
                  v-model:pageSize="limit" :total="total" />
              </div>
            </div>
          </div>
          <!-- /product list -->
    
  </template>
  <script>
  import { ref } from "vue";
  
  const currentDate = ref(new Date());
  // const columns =
  // you must start with empty Array for the a-table compoent to work
  var realData = [];
  
  export default {
    data() {
      return {
  
        page: 1,
        limit: 10,
        total: 0,
        search: '',
        realData,
        Select: '',
        filter: false,
  
        title: "menu.hr_menu.title",
        items: [
          {
            text: "menu.hr_menu.add",
            addname: "",
          },
        ],
  
        columns: [
  
          {
            title: this.$t("tables.hrList.id"),
            dataIndex: "id",
          },
          {
            title: this.$t("tables.hrList.pos_station"),
            dataIndex: "pos_station"
          },
          {
            title: this.$t("tables.hrList.name"),
            dataIndex: "name",
  
          },
          {
            title: this.$t("tables.hrList.pos_job_title"),
            dataIndex: "pos_job_titles",
  
          },
          {
            title: this.$t("tables.hrList.birth_date"),
            dataIndex: "birthdate",
          },
          {
            title: this.$t("tables.hrList.basic_salary"),
            dataIndex: "basic_salary",
  
          },
          {
            title: this.$t("tables.hrList.created"),
            dataIndex: "created",
  
          },
          {
            title: this.$t("tables.hrList.updated"),
            dataIndex: "updated",
          },
  
          {
            title: this.$t("tables.hrList.action"),
            key: "action",
          },
        ],
        startdate: currentDate,
        Disable: ["Disable", "Enable"],
      };
    },
    mounted() {
      //   this.getPosUsers();
      this.getPosJobTitles()
      this.getPosEmployees()
  
      this.$t(this.items[0].text)
  
  
      console.log("ggg");
    },
    methods: {
      onChange(pageNumber, pageSize) {
        console.log("page :", pageNumber, "total :", pageSize)
        this.getPosEmployees()
      },
      showAlert(record) {
        // Use sweetalert2
        Swal.fire({
          title: this.$t("messages.alert.are_you_sure"),
          text: this.$t("messages.alert.unrevertable"),
          showCancelButton: true,
          confirmButtonColor: "#FF9F43",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        }).then((result) => {
          if (result.isConfirmed) {
            console.log('pop confirmed :', result)
            this.deletePosEmployees(record)
          }
        });
      },
      addPosEmployees() {
        const loggeduser = localStorage.pos_station;
        const decoded_user_data = JSON.parse(loggeduser);
        console.log(' ff :', decoded_user_data)
        var select_job = this.Select
        console.log('vv :', select_job)
        var data = {
          title: "menu.hr_menu.add",
          inputs: [
  
            {
              label: 'tables.hrList.name',
              type: 'text',
              model: 'name',
              // required: true,
              // invalid_msg: "you must enter a name",
              // pattern: '[a-z]{4,8}'
  
  
            },
  
            {
              label: "tables.hrList.basic_salary",
              type: "number",
              model: "basic_salary",
              required: true,
  
  
            },
            {
              label: 'tables.hrList.birth_date',
              type: "date",
              model: 'birthdate',
  
            },
  
            {
              label: "tables.hrList.pos_job_title",
              type: "select",
              options: select_job,
              model: "pos_job_titles",
  
  
            },
  
  
  
  
          ],
          buttons: [
            {
              text: "popups.submit",
              closer: true,
              color: "primary",
              handler: (obj) => {
                console.log("OK Clicked", obj);
                obj.pos_station_id = decoded_user_data.pos_station_id
                this.http.post(`pos-employees`, obj, null, true).then((res) => {
                  console.log(res.data)
                  Swal.fire(this.$t("messages.success")).then(() => {
                    this.getPosEmployees()
                  })
                })
  
              },
            },
            {
              text: "popups.cancel",
              closer: true,
              color: "light",
              handler: () => {
                console.log("Like Clicked");
              },
            },
          ],
        };
        this.popup.modal(data);
  
      },
      editPosEmployees(options) {
        console.log('edit options :', options)
        var select_job = this.Select
        console.log('vv :', select_job)
        var data = {
          title: "menu.hr_menu.edit",
          inputs: [
  
            {
              label: 'tables.hrList.name',
              type: 'text',
              model: 'name',
              value: options.name
  
            },
  
            {
              label: "tables.hrList.basic_salary",
              type: "number",
              model: "basic_salary",
              value: options.basic_salary
  
            },
            {
              label: 'tables.hrList.birth_date',
              type: "date",
              model: 'birthdate',
              value: options.birthdate
  
            },
  
            {
              label: "tables.hrList.pos_job_title",
              type: "select",
              options: select_job,
              model: "pos_job_titles",
              value: options.pos_job_titles
  
  
  
            },
  
  
  
  
          ],
          buttons: [
            {
              text: "popups.submit",
              closer: true,
              color: "primary",
              handler: (obj) => {
                console.log("OK Clicked", obj);
                this.http.put(`pos-employees`, options.id, obj).then((res) => {
  
                  console.log(res.data)
                  Swal.fire(this.$t("messages.success")).then(() => {
                    this.getPosEmployees()
                  })
                })
  
              },
            },
            {
              text: "popups.cancel",
              closer: true,
              color: "light",
              handler: () => {
                console.log("Like Clicked");
              },
            },
          ],
        };
        this.popup.modal(data);
  
      },
      getPosEmployees() {
        // i empty the table content every time i call pos-user api
        this.realData = []
        this.http.post(`pos-employees/paginate`, { page: this.page, limit: this.limit }, null, true)
          .then((res) => {
            this.total = res.tot
            console.log("res :", res.data);
            res.data.map((e) => {
              this.realData.push({
                id: e.id,
                pos_station: e.pos_station?.name,
                pos_job_titles: e.pos_job_title?.name,
                name: e.name,
                birthdate: e.birthdate,
                basic_salary: e.basic_salary,
                // pos_user_roles: e.pos_user_roles.map(function (role) {  return ( role.pos_role.code.charAt(0).toUpperCase() + role.pos_role.code.slice(1) ); }).join(", ") .toString(),
                created: new Date(e.created).toLocaleString("en-GB").toString(),
                updated: new Date(e.updated).toLocaleString("en-GB").toString(),
              });
            });
  
            // console.log("real data :", this.realData);
          });
      },
      getPosJobTitles() {
  
        this.http.get(`pos-job-titles`, null, null, true).then((res) => {
          this.Select = res.data
          this.Select = this.Select.map(item => ({ value: item.id, label: item.name }))
  
          console.log('fff :', this.Select)
        })
      },
      searchPosEmployees(options) {
  
        this.http.post(`pos-employees/search`, { "col": options.col, "search": options.search, "page": 1, "limit": 25, lang: localStorage.current_language }, null, true).then((res) => {
          this.realData = []
          console.log('search res', res)
          res.data.map((e) => {
            this.realData.push({
              id: e.id,
              pos_station: e.pos_station.name,
              pos_job_titles: e.pos_job_titles,
              name: e.name,
              birthdate: e.birthdate,
              basic_salary: e.basic_salary,
              // pos_user_roles: e.pos_user_roles.map(function (role) {  return ( role.pos_role.code.charAt(0).toUpperCase() + role.pos_role.code.slice(1) ); }).join(", ") .toString(),
              created: new Date(e.created).toLocaleString("en-GB").toString(),
              updated: new Date(e.updated).toLocaleString("en-GB").toString(),
            });
          });
  
        })
  
      },
      deletePosEmployees(record) {
        this.http.delete(`pos-employees`, record.id).then(() => {
  
          console.log('record :', record)
          Swal.fire("Deleted!", "Your file has been deleted.");
  
          this.getPosEmployees()
        })
      },
  
  
    },
  
  
  };
  </script>
    