<template>
    <div class="row">
      <div class="col-lg-3 col-sm-6 col-12">
          <div class="dash-widget dash3">
            <div class="dash-widgetimg">
              <span><img src="/assets/img/icons/dash4.svg" alt="img" /></span>
            </div>
            <div class="dash-widgetcontent">
              <h5>
                $<vue3-autocounter class="counter" ref="counter" :startAmount="0.0" :delay="3" :endAmount="statistics_data?.total_sale_order"
                  :duration="5" :autoinit="true" />
              </h5>
  
              <h6> {{this.$t("charts.monthly_sales_amount")}}</h6>
            </div>
          </div>
        </div>
          <div class="col-lg-3 col-sm-6 col-12">
          <div class="dash-widget dash2">
            <div class="dash-widgetimg">
              <span><img src="/assets/img/icons/dash3.svg" alt="img" /></span>
            </div>
            <div class="dash-widgetcontent">
              <h5>
                $<vue3-autocounter class="counter" ref="counter" :startAmount="0.0" :delay="3" :endAmount="statistics_data?.total_price_purchase"
                  :duration="5" :autoinit="true" />
              </h5>
  
              <h6>{{ this.$t("charts.monthly_purchases_amount") }}</h6>
            </div>
          </div>
        </div>
      <div class="col-lg-3 col-sm-6 col-12">
        <div class="dash-widget">
          <div class="dash-widgetimg">
            <span><img src="/assets/img/icons/dash1.svg" alt="img" /></span>
          </div>
          <div class="dash-widgetcontent">
            <h5>
              $<vue3-autocounter class="counter" ref="counter" :startAmount="0.0" :delay="3"
                :endAmount="0.0" :duration="5" :autoinit="true" />
            </h5>
  
            <h6>{{this.$t("charts.monthly_purchases_due")}}</h6>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6 col-12">
        <div class="dash-widget dash1">
          <div class="dash-widgetimg">
            <span><img src="/assets/img/icons/dash2.svg" alt="img" /></span>
          </div>
          <div class="dash-widgetcontent">
            <h5>
              $<vue3-autocounter class="counter" ref="counter" :startAmount="0.0" :delay="3" :endAmount="0.0"
                :duration="5" :autoinit="true" />
            </h5>
  
            <h6>{{ this.$t("charts.monthly_sales_due") }}</h6>
          </div>
        </div>
      </div>
  
  
      <div class="col-lg-3 col-sm-6 col-12 d-flex">
        <div class="dash-count">
          <div class="dash-counts">
            <h4>{{ statistics_data.employees_count }}</h4>
            <h5>{{ this.$t("charts.employees") }}</h5>
          </div>
          <div class="dash-imgs">
            <vue-feather type="user"></vue-feather>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6 col-12 d-flex">
        <div class="dash-count das1">
          <div class="dash-counts">
            <h4>{{ statistics_data.suppliers_count }}</h4>
            <h5>{{this.$t("charts.suppliers")}}</h5>
          </div>
          <div class="dash-imgs">
            <vue-feather type="user-check"></vue-feather>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6 col-12 d-flex">
        <div class="dash-count das2">
          <div class="dash-counts">
            <h4>{{ statistics_data.total_purchases_invoices }}</h4>
  
            <h5>{{this.$t("charts.purchase_invoices")}}</h5>
          </div>
          <div class="dash-imgs">
            <vue-feather type="file-text"></vue-feather>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6 col-12 d-flex">
        <div class="dash-count das3">
          <div class="dash-counts">
            <h4>{{ statistics_data.total_sales_invoices }}</h4>
            <h5>{{ this.$t("charts.sales_invoice") }}</h5>
          </div>
          <div class="dash-imgs">
            <vue-feather type="file"></vue-feather>
          </div>
        </div>
      </div>
    </div>
    <!-- Button trigger modal -->
    <div class="row">
      <div class="col-lg-7 col-sm-12 col-12 d-flex">
        <div class="card flex-fill">
          <div class="card-header pb-0 d-flex justify-content-between align-items-center">
            <h5 class="card-title mb-0">{{ this.$t("charts.purchase_and_sales") }}</h5>
            <div class="graph-sets">
              <ul>
                <li class="me-4">
                  <span>{{this.$t("charts.sales")}}</span>
                </li>
                <li class="ms-2">
                  <span>{{ this.$t("charts.purchases") }}</span>
                </li>
              </ul>
              <div class="dropdown">
                <button class="btn btn-white btn-sm dropdown-toggle" type="button" id="dropdownMenuButton"
                  data-bs-toggle="dropdown" aria-expanded="false">
                  2022
                  <img src="/assets/img/icons/dropdown.svg" alt="img" class="ms-2" />
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <li>
                    <a href="javascript:void(0);" class="dropdown-item">2022</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);" class="dropdown-item">2021</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);" class="dropdown-item">2020</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card-body" style="overflow: scroll">
            <apexchart v-if="sales_chart.length != 0" type="bar" height="300" :options="basicAreaChart.chartOptions"
              :series="basicAreaChart.series"></apexchart>
          </div>
        </div>
      </div>
      <div class="col-lg-5 col-sm-12 col-12 d-flex">
        <div class="card flex-fill">
          <div class="card-header pb-0 d-flex justify-content-between align-items-center">
            <h4 class="card-title mb-0">{{ this.$t("charts.recently_added_products") }}</h4>
            <div class="dropdown">
              <a href="javascript:void(0);" data-bs-toggle="dropdown" aria-expanded="false" class="dropset">
                <i class="fa fa-ellipsis-v"></i>
              </a>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <li>
                  <router-link to="/pos_products" class="dropdown-item">{{this.$t("charts.product_list")}}</router-link>
                </li>
                <li>
                  <router-link to="/pos_products" class="dropdown-item">{{this.$t("charts.product_add")}}</router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive dataview datatableIndex">
              <a-table :columns="columns" :data-source="data" @change="onChange">
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key === 'id'">
                    <div class="productimgname">
                      <router-link to="pos_products" class="product-img">
                        <img :src="$store.state.mediaUrl+'/' + record?.image?.split('public/')[1]" alt="product" />
                      </router-link>
                      <router-link to="productlist">{{
                        record.Products
                      }}</router-link>
                    </div>
                  </template>
                </template>
              </a-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="card mb-0">
      <div class="card-body">
        <h4 class="card-title">Expired Products</h4>
        <div class="table-responsive dataview datatableIndex">
          <a-table :columns="column" :data-source="datas" @change="onChange">
            <template #bodyCell="{ column, record }">
              <template v-if="column.key === 'image'">
                <div class="productimgname">
                  <router-link to="productlist" class="product-img">
                    <img :src="'/assets/img/product/' + record.image
                      " alt="product" />
                  </router-link>
                  <router-link to="productlist">{{
                    record.ProductName
                  }}</router-link>
                </div>
              </template>
            </template>
          </a-table>
        </div>
      </div>
    </div> -->
  </template>
  <script>
  import { ref } from "vue";
  import { basicAreaChart } from "./data";
  const currentDate = ref(new Date());
  const columns = [
    {
      title: "Sno",
      dataIndex: "Sno",
      sorter: {
        compare: (a, b) => {
          a = a.Sno.toLowerCase();
          b = b.Sno.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Products",
      dataIndex: "Products",
      key: "id",
      sorter: {
        compare: (a, b) => {
          a = a.Products.toLowerCase();
          b = b.Products.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Price",
      dataIndex: "Price",
      sorter: {
        compare: (a, b) => {
          a = a.Price.toLowerCase();
          b = b.Price.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
  ];
  const column = [
    {
      title: "Sno",
      dataIndex: "SNo",
      sorter: {
        compare: (a, b) => {
          a = a.SNo.toLowerCase();
          b = b.SNo.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Product Code",
      dataIndex: "ProductCode",
      sorter: {
        compare: (a, b) => {
          a = a.ProductCode.toLowerCase();
          b = b.ProductCode.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Product Name",
      dataIndex: "ProductName",
      key: "image",
      sorter: {
        compare: (a, b) => {
          a = a.ProductName.toLowerCase();
          b = b.ProductName.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Brand Name",
      dataIndex: "BrandName",
      sorter: {
        compare: (a, b) => {
          a = a.BrandName.toLowerCase();
          b = b.BrandName.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Category Name",
      dataIndex: "CategoryName",
      sorter: {
        compare: (a, b) => {
          a = a.CategoryName.toLowerCase();
          b = b.CategoryName.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Expiry Date",
      dataIndex: "ExpiryDate",
      sorter: {
        compare: (a, b) => {
          a = a.ExpiryDate.toLowerCase();
          b = b.ExpiryDate.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
  ];
  const datas = [
    {
      id: 1,
      SNo: "1",
      ProductCode: "IT0001",
      image: "product2.jpg",
      ProductName: "Orange",
      BrandName: "N/D",
      CategoryName: "Fruits",
      ExpiryDate: "12-12-2022",
    },
    {
      id: 2,
      SNo: "2",
      ProductCode: "IT0002",
      image: "product3.jpg",
      ProductName: "Pineapple",
      BrandName: "N/D",
      CategoryName: "Fruits",
      ExpiryDate: "25-11-2022",
    },
    {
      id: 3,
      SNo: "3",
      ProductCode: "IT0003",
      image: "product4.jpg",
      ProductName: "Stawberry",
      BrandName: "N/D",
      CategoryName: "Fruits",
      ExpiryDate: "19-11-2022",
    },
    {
      id: 4,
      SNo: "4",
      ProductCode: "IT0004",
      image: "product5.jpg",
      ProductName: "Avocat",
      BrandName: "N/D",
      CategoryName: "Fruits",
      ExpiryDate: "20-11-2022",
    },
  ];
  export default {
    data() {
      return {
        basicAreaChart: basicAreaChart,
        data: "",
        columns,
        datas,
        column,
        startdate: currentDate,
        statistics_data: [],
        recently_products: [],
        purchases_chart: [],
        sales_chart: [],
      };
    },
    computed: {
      purchaseData() {
        return this.purchases_chart;
      },
      salesData() {
        return this.sales_chart;
      },
    },
    methods: {
      async getStatisticsData() {
        const res = await this.http.get("statistics/count",null,null,true);
        this.statistics_data = res.data;
        console.log("chart :", this.statistics_data)
      },
  
      async getPurchasesChart() {
        const res = await this.http.post("pos-purchases-order/chart", {
          type: 2,
          tab: "pos_purchases_order",
        }, null, true);
        this.purchases_chart = res.data;
  
        this.basicAreaChart.series.push({
          name: "Purchases Order",
          data: this.purchases_chart.map((chart) => (chart.y ? chart.y : 100)),
        });
        this.basicAreaChart.chartOptions.xaxis.categories =
          this.purchases_chart.map((chart) => (chart.label ? chart.label : 0));
      },
      async getSalesChart() {
        const res = await this.http.post("pos-purchases-order/chart", {
          type: 2,
          tab: "pos_purchases_order",
        }, null, true);
        this.sales_chart = res.data;
  
        this.basicAreaChart.series.push({
          name: "Sales Order",
          data: this.sales_chart.map((chart) => (chart.y ? chart.y : 70)),
        });
      },
      async getRecuntlyProducts() {
        const res = await this.http.get("statistics/recently-products", null, null, true);
        this.recently_products = res.data;
        this.data = this.recently_products.map((product) => {
          return {
            id: product?.id,
            image: product?.img,
            Products: product?.name,
            Price: product?.sales_price,
          };
        });
      },
    },
    created() {
      this.getStatisticsData();
      this.getRecuntlyProducts();
      this.getPurchasesChart();
      this.getSalesChart();
    },
  };
  </script>
  