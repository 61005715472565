import Pos from "../../../views/pages/pos/pos.vue";

export default [
  {
    path: "/pos",
    name: "pos",
    component: Pos,
    meta: {
      headerClass: "header",
      auth_roles: ["admin", "hr_manager"],
      auth_type: ["pos_user"],
    },
  },
];

