import Accounting from "../../../views/pages/accounting/index.vue";

export default [
  {
    path: "accounting",
    name: "accounting",
    component: Accounting,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      auth_roles: ["admin", "hr_manager"],
      auth_type: ["pos_user"],
    },
  },
];
