// import general_routes from "./routes/general";
import purchase from "./routes/purchase";
import categories from "./routes/categories";
import products from "./routes/products";
import archives from "./routes/archives";

import home from "./routes/project_routes/home";
import pos from "./routes/project_routes/pos";
import posKitchen from "./routes/project_routes/pos_kitchen";
import hrList from "./routes/project_routes/hrList";
import floors from "./routes/project_routes/floors";
// import floorTablesManagement from "./routes/project_routes/floorTablesManagement";
import priceList from "./routes/project_routes/priceList";
// import pos_stations from "./routes/project_routes/pos_stations";
// import kitchensManagement from "./routes/project_routes/kitchensManagement";
import payroll from "./routes/payroll";
import pos_hr from "./routes/pos_hr";
import kitchensManagement from "./routes/project_routes/kitchensManagement";
import userList from "./routes/project_routes/userList";
import topic from "./routes/project_routes/topic";
import recon_profile from "./routes/project_routes/recon_profile";
import reconcilaition from "./routes/project_routes/reconcilaition";
import accounting from "./routes/project_routes/accounting";
import tax from "./routes/project_routes/tax";
import printer from "./routes/project_routes/printer";

export default [
  // ...general_routes,

  {
    path: '/login',
    component: () => import("@/views/pages/auth/login.vue"),
  },
 
  ...pos,
{
  path:"/",
  redirect:"/dashboard/home"
},
  {
    path:"/dashboard",
    name: "dashboard",
    component: () => import("@/components/layouts/layout.vue"),
    children:[
      ...home,
      ...purchase,
      ...products,
      ...categories,
      ...archives,
      ...printer,
      ...accounting,
      ...hrList,
      ...floors,
      ...kitchensManagement,
      ...priceList,
      // ...pos_stations,
      ...payroll,
      ...pos_hr,
      ...userList,
      ...topic,
      ...recon_profile,
      ...reconcilaition,
      ...tax

    ]

  },
 

];
