// import Addpurchase from "../../views/pages/purchase/addpurchase.vue";
// import Editpurchase from "../../views/pages/purchase/editpurchase.vue";
// import Editpurchasereturn from "../../views/pages/return/editpurchasereturn.vue";
// import Purchasereturnlist from "../../views/pages/return/purchasereturnlist.vue";
import Addsupplier from "../../views/pages/purchase/suppliers/addsupplier.vue";
import Supplierlist from "../../views/pages/purchase/suppliers/supplierlist.vue";
import PurchasesList from "../../views/pages/purchase/PosPurchasesOrder/PosPurchasesOrderPage.vue";
// import EditSupplier from '../../views/pages/purchase/suppliers/EditSupplier.vue'

export default [
  {
    path: "suppliers_list",
    component: Supplierlist,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      auth_roles: ["admin", "manager"],
      auth_type: ["pos_user"],
    },
  },
  {
    path: "add_supplier",
    component: Addsupplier,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      auth_roles: ["admin", "manager"],
      auth_type: ["pos_user"],
    },
  },
  {
    path: "purchases_list",
    component: PurchasesList,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      auth_roles: ["admin", "manager"],
      auth_type: ["pos_user"],
    },
  },
];
