<template>
  <!-- Header Menu -->
  <ul class="nav user-menu">
    <!-- Search -->
    
    <!-- /Search -->
   
    <!-- Flag -->
    <li class="nav-item dropdown has-arrow flag-nav nav-item-box">
      <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="javascript:void(0);" role="button">
        <vue-feather type="globe"></vue-feather>
      </a>
      <div class="dropdown-menu dropdown-menu-right">
        <a href="javascript:void(0);" v-for="(entry, i) in languages" :key="`Lang${i}`" :value="entry"
          @click="setLanguage(entry.language, entry.title, entry.iso)" :class="{ active: lan === entry.language }"
          class="dropdown-item" data-lang="en" title="English">
          <flag :iso="entry.iso" height="16" class="me-2" />
          <span class="align-middle">{{ entry.title }}</span>
        </a>
      </div>
    </li>

    <!-- /Flag -->
    <li class="nav-item nav-item-box">
      <a href="javascript:void(0);" id="btnFullscreen" @click="initFullScreen">
        <vue-feather type="maximize"></vue-feather>
      </a>
    </li>

    <!-- Notifications -->

    <!-- <li class="nav-item nav-item-box">
      <router-link to="generalsettings"
        ><vue-feather type="settings"></vue-feather
      ></router-link>
    </li> -->
    <li class="nav-item dropdown has-arrow main-drop">
      <a href="javascript:void(0);" class="dropdown-toggle nav-link userset" data-bs-toggle="dropdown">
        <span class="user-info">
          <span class="user-letter">
            <img src="/assets/img/profiles/avator1.jpg" alt="" class="img-fluid" />
          </span>
          <span class="user-detail">
            <span class="user-name">{{ user?.user_data?.name }} </span>
            <span class="user-role">{{
                            this.$i18n.locale == 'en' ?  user?.user_data?.roles.map((item) =>  item.pos_role.name_en ).toString() :user?.user_data?.roles.map((item) =>  item.pos_role.name ).toString()

            }}</span>
          </span>
        </span>
      </a>
      <div class="dropdown-menu menu-drop-user">
        <div class="profilename">
          <div class="profileset">
            <span class="user-img"><img src="/assets/img/profiles/avator1.jpg" alt="" />
              <span class="status online"></span></span>
            <div class="profilesets">
              <h6>{{ user?.user_data?.name }}</h6>
              <h5>
                {{
                 this.$i18n.locale == 'en' ?  user?.user_data?.roles.map((item) =>  item.pos_role.name_en ).toString() :user?.user_data?.roles.map((item) =>  item.pos_role.name).toString()
                }}
              </h5>
            </div>
          </div>
          <hr class="m-0" />
          <router-link class="dropdown-item" to="profile"><vue-feather type="user"></vue-feather>{{$t("messages.profile")}}</router-link>
          <router-link class="dropdown-item" to="generalsettings"><vue-feather
              type="settings"></vue-feather>{{$t("messages.settings")}}</router-link>
          <hr class="m-0" />
          <a class="dropdown-item logout pb-0" @click="logout()"><img src="/assets/img/icons/log-out.svg" class="me-2"
              alt="img" />{{$t("messages.alert.logout")}}</a>
        </div>
      </div>
    </li>
  </ul>
  <!-- /Header Menu -->
</template>
<script>
import headernotification from "@/assets/json/headernotification.json";
import "js-loading-overlay";
import { socket } from "@/socket";


export default {
  data() {
    return {
      headernotification: headernotification,
      user: [],
      languages: [
        {
          iso: "us",
          language: "us",
          title: "English",
        },
        {
          iso: "sa",
          language: "ar",
          title: "Arabic",
        },
      ],
    };
  },
  methods: {
    logout() {
      Swal.fire({
        title: this.$t("messages.alert.are_you_sure"),
        text: this.$t("messages.alert.logout"),
        showCancelButton: true,
        confirmButtonColor: "#FF9F43",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("messages.alert.cancel"),
        confirmButtonText: this.$t("messages.alert.logout"),
      }).then((result) => {
        if (result.isConfirmed) {
          socket.disconnect();

          localStorage.removeItem("pos_station"),

            this.$router.push("/login");
        }
      });
    },
    setLanguage(locale, country, flag) {
      const loading_options = {
        spinnerIcon: "ball-beat",
        overlayBackgroundColor: "#ffffff",
        overlayOpacity: 1,
        spinnerColor: "#FF9F43",
        spinnerSize: "2x",
        overlayIDName: "overlay",
        spinnerIDName: "spinner",
      };
      JsLoadingOverlay.show(loading_options);
      if (locale == "ar") {
        localStorage.setItem("current_language", "ar");
        this.$i18n.locale = "ar";
        let html = document.querySelector("#lang-changer");
        html.setAttribute("href", "/css/style_rtl.css");
        setTimeout(() => {
          JsLoadingOverlay.hide();
        }, 300);
      } else {
        localStorage.setItem("current_language", "en");
        this.$i18n.locale = "en";
        let html = document.querySelector("#lang-changer");
        html.setAttribute("href", "/css/style.css");
        setTimeout(() => {
          JsLoadingOverlay.hide();
        }, 300);
      }
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    // logout() {
    //   localStorage.removeItem("pos_station");
    //   this.$router.push("/login");

    // },
  },
  created() {
    this.user = { user_data: JSON.parse(localStorage.pos_station) };
    console.log("step 999:", this.user)

    
  },
};
</script>
