<template>
    <!-- Main Wrapper -->
   
          <pageaddheader :title="title"  />
  
          <!-- /product list -->
          <div class="card">
            <div class="card-body">
              
                <form @submit.prevent="submitTax()">
                    <div class="row mb-3">


                        <label class="form-label">
        
                           {{$t( "tax.price_including_vat")}}
                        </label>
                        <div class="form-check">
                            <input  class="form-check-input" type="radio" :value="true" v-model="price_including_tax" id="" />
                            <label class="form-check-label" for="">  {{$t( "tax.yes")}} </label>
                        </div>
                        <div class="form-check">
                            <input
                                class="form-check-input"
                                type="radio"
                                v-model="price_including_tax"
                                :value="false"
                                id=""
                                
                                
                            />
                            <label class="form-check-label" for="">
                                {{$t( "tax.no")}}
                            </label>
                        </div>

                    </div>
                    <div class="row mb-3">

                        <div class="mb-3">
                            <label for="" class="form-label">{{ $t("tax.percentage") }}</label>
                            <input
                                type="number"
                                class="form-control"
                                v-model="percentage"
                                placeholder=""
                            />
                        </div>
                        
                    </div>

                    <row class="mb3">
                        <button  type="submit"   class="btn btn-primary" >
                            {{$t("popups.submit")}}
                        </button>
                        
                    </row>


                </form>
                
            </div>
          </div>
          <!-- /product list -->
  
      
  
  </template>
  
  <script>
  export default {
    data() {
      return {
       
        title: this.$t("tax.title"),
        price_including_tax:null,
        percentage:0,
        pos_station:{},
        suppliers: [],
        categories: [],
        category_products: [],
        data: {
         
        },
      };
    },
    methods: {

        submitTax(){

            this.http.put(`pos-stations`,this.pos_station.pos_station.id,{ tax: this.percentage, price_including_tax: this.price_including_tax  }).then((res)=>{
                Swal.fire(this.$t("messages.success"))

                this.pos_station.pos_station.tax = this.percentage 
                this.pos_station.pos_station.price_including_tax = this.price_including_tax 
                localStorage.pos_station = JSON.stringify(this.pos_station)
                console.log("res" , res.data)
            })
        }
 


  
  
    },
    created() {
        this.pos_station =JSON.parse(localStorage.pos_station);
        this.percentage = this.pos_station.pos_station.tax
        this.price_including_tax =this.pos_station.pos_station.price_including_tax
    },
    name: "PurshcesList",
  };
  </script>
  