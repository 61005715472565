export default [
  {
    path: "category_list",
    
    component: () => import("../../views/pages/categories/CategoriesList.vue"),
    meta: {
      headerClass: "header",
      sidebarClass: "",
      auth_roles: ["admin", "manager"],
      auth_type: ["pos_user"],
    },
  },
  {
    path: "category_list/:id",
    sensitive: true ,
    name:"productsByCategoryId",
    component: () => import("../../views/pages/categories/products/index.vue"),
    meta: {
      headerClass: "header",
      sidebarClass: "",
      auth_roles: ["admin", "manager"],
      auth_type: ["pos_user"],
    },
  }
];
