import userlist from "../../../views/pages/users/userlists.vue";

export default [
  {
    path: "userlist",
    name: "userlist",
    component: userlist,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      auth_roles: ["admin", "hr_manager"],
      auth_type: ["pos_user"],
    },
  },
];
