<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <headerpos></headerpos>


    <div class="page-wrapper ms-1 me-1 ">
      <b-tabs class="mt-4 " >
        <b-tab active>
          <template  v-slot:title>
            <div @click="activate_pos.active()" class="">

            <span  class="d-inline-block d-sm-none">
              <i class="fas fa-home"></i>
            </span>
            <span class="d-none d-sm-inline-block tab-text">{{
              this.$t("pos.pos_station")
            }}</span>

            </div>
          </template>
          <pos-station-tab :activate-tab="activate_pos" />
        </b-tab>
        <b-tab>
          <template   v-slot:title>
            <div @click="activate_sales.active()" class="">

            <span class="d-inline-block d-sm-none">
              <i class="fas fa-home"></i>
            </span>
            <span class="d-none d-sm-inline-block tab-text">{{
              this.$t("pos.sales")
            }}</span>
            </div>
          </template>
          <my-sales-tab :activate-tab="activate_sales" />

        </b-tab>
      </b-tabs>
    </div>
  </div>

  <posmodal></posmodal>
</template>
<script>
import "vue3-carousel/dist/carousel.css";

import posStationTab from "./pos_station_tab/posStationTab.vue";
import mySalesTab from "./my_sales_tab/mySalesTab.vue";

export default {
  setup() {
    return {};
  },

  data() {
    return {
      activate_pos: {},
      activate_sales:{},
      active_id: 1,
      product_active_id: "",
      categories: [],
      customer: {},
      printer: {},
      floors: [],

      floor_tables: [],
      order_basket: [],
      pos_products: [],
      pos_sale_orders: [],
      pos_sale_order_details: [],
      pos_sale_order_page: 1,
      pos_sale_order_limit: 20,
      pos_sale_order_total: 0,
      pos_sale_order_details_page: 1,
      pos_sale_order_details_limit: 40,
      pos_sale_order_details_total: 0,

      pos_price_list: [],
      pos_station: {},
      received_amount: 0.0,
      add_modal: null,
      qr_modal: null,
      sale_order_modal: null,
      sale_order_detail_modal: null,
      Product: ["Product", "Barcode"],
      Walk: ["Walk-in Customer", "Chris Moris"],
      settings: {
        itemsToShow: 2,
        snapAlign: "start",
      },

      breakpoints: {
        575: {
          itemsToShow: 3,
          snapAlign: "start",
        },
        // 700px and up
        767: {
          itemsToShow: 3,
          snapAlign: "start",
        },
        // 1024 and up
        1024: {
          itemsToShow: 7,
          snapAlign: "start",
        },
      },
    };
  },
  mounted() { },
  computed: {},
  watch: {},
  methods: {


 
  },
  components: {
    mySalesTab,
    posStationTab,
  },
  name: "pos",
};
</script>

<style scoped></style>
