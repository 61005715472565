export default [
  {
    path: "recon_profile/:data",
    name: "profile",
    component: () =>
      import("../../../views/pages/reconcilaitions/recon_profile.vue"),
    meta: {
      headerClass: "header",
      sidebarClass: "",
      auth_roles: ["admin", "hr_manager"],
      auth_type: ["pos_user"],
    },
  },
];