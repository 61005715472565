export default [
  {
    path: "archive_category",
    component: () =>
      import("../../views/pages/archive/CategoriesManagements.vue"),
    meta: {
      headerClass: "header",
      sidebarClass: "",
      auth_roles: ["admin", "manager"],
      auth_type: ["pos_user"],
    },
  },
  {
    path: "archive_shelves",
    component: () => import("../../views/pages/archive/ShelvesManagemets.vue"),
    meta: {
      headerClass: "header",
      sidebarClass: "",
      auth_roles: ["admin", "manager"],
      auth_type: ["pos_user"],
    },
  },
  {
    path: `seleves_folders/:id`,
    component: () => import("../../views/pages/archive/ShelvesFolders.vue"),
    meta: {
      headerClass: "header",
      sidebarClass: "",
      auth_roles: ["admin", "manager"],
      auth_type: ["pos_user"],
    },
  },

  {
    path: `archive_documents_management`,
    component: () =>
      import("../../views/pages/archive/DocumentsManagements.vue"),
    meta: {
      headerClass: "header",
      sidebarClass: "",
      auth_roles: ["admin", "manager"],
      auth_type: ["pos_user"],
    },
  },
];
