<template>
    <div class="content">
        <div class="row" :style="this.$i18n.locale == 'ar' ? 'direction : rtl' : 'direction : ltr'">
            <div class="col-lg-8 col-sm-12 tabs_wrapper">
                <div class="page-header">

                </div>
                <div class="owl-caousel-tab">
                    <carousel :wrap-around="false" :settings="settings" :breakpoints="breakpoints"
                        :dir="this.$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
                        <slide class="tabs owl-product border-0" v-for="(item, index) in categories" :key="index">
                            <li :id="item.id" :class="{ active: item.active }"
                                @click="getProductsByCategoryId(item.id, index)">
                                <div class="product-details">

                                    <h6>{{ item.name }}</h6>
                                </div>
                            </li>
                        </slide>
                        <template #addons>
                            <navigation />
                        </template>
                    </carousel>
                </div>
                <div class="tabs_container">
                    <div data-tab="fruits">
                        <div class="row">
                            <div v-for="(item, index) in pos_products" :key="index" class="col-lg-3 col-sm-6 d-flex">
                                <div @click="addItem(item)" class="productset flex-fill" :class="{ active: item.active }">
                                    <div class="productsetimg">
                                        <img :src="state.mediaUrl + '/' + item.img?.split('public/')[1]" alt="img" style="height: 200px;" />
                                        <!-- <h6>Qty: 5.00 </h6> -->
                                        <div class="check-product">
                                            <i class="fa fa-check"></i>
                                        </div>
                                    </div>
                                    <div class="productsetcontent">
                                        <!-- <h5>Fruits</h5> -->
                                        <h4>{{ item.name }}</h4>
                                        <h6>{{ item.sales_price }}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-12">
                <div class="order-list">
                    <div class="orderid">
                        <!-- <h4>Order List</h4>
                    <h5>Transaction id : #65565</h5> -->
                    </div>
                    <div class="actionproducts"></div>
                </div>
                <div class="card card-order">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <div class="d-flex justify-content-between   ">
                                    <button class="btn btn-scanner-set" style="margin-left: 0"> <img
                                            src="/assets/img/icons/scanner1.svg" alt="img" class="me-2" />Scan
                                        bardcode</button>



                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="split-card"></div>
                    <div class="card-body pt-0">
                        <div class="totalitem">
                            <h4>
                                {{ this.$t("pos.total_items") }}
                                {{ order_basket.length == 0 ? 0 : order_basket.length }}
                            </h4>
                            <a @click="deleteAll()" href="javascript:void(0);">{{
                                this.$t("pos.clear_all")
                            }}</a>
                        </div>
                        <div class="product-table">
                            <ul :style="this.pos_price_list?.pos_price_list_products.some( (e) => e.product_id == item.id )
                                ? 'background-color: #dff0ff;'
                                : ''" class="product-lists" v-for="(item, index) in order_basket" :key="item.id">
                                <li>
                                    <!-- this.pos_price_list?.pos_price_list_products.some(
                                (e) => e.product_id == item.id
                            )
                                ? 'background-color: #dff0ff;'
                                : '' -->
                                    <div class="productimg">
                                        <div class="productimgs">
                                            <img :src="state.mediaUrl + '/' + item.img?.split('public/')[1]" alt="img" />
                                        </div>
                                        <div class="productcontet">
                                            <h4>
                                                {{ item.name }}
                                                <a href="javascript:void(0);" class="ms-2" data-bs-toggle="modal"
                                                    data-bs-target="#edit"><img src="/assets/img/icons/edit-5.svg"
                                                        alt="img" /></a>
                                            </h4>
                                            <div class="productlinkset">
                                                <h5>PT001</h5>
                                            </div>
                                            <div class="increment-decrement">
                                                <div class="input-groups">
                                                    <input type="button" value="-" class="button-minus dec button"
                                                        @click="item.qty--" />
                                                    <div class="ps-2 pe-2">{{ item.qty }}</div>
                                                    <input type="button" value="+" class="button-plus inc button"
                                                        @click="item.qty++" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <div class="price-section">
                                        <span>
                                            {{ (item.qty * item.original_price).toFixed(2) }}
                                        </span>
                                        <span>
                                            {{ (item.qty * item.sales_price).toFixed(2) }}
                                        </span>
                                    </div>
                                </li>
                                <li>
                                    <a class="confirm-text" href="javascript:void(0);" @click="showAlert(index)"><img
                                            src="/assets/img/icons/delete-2.svg" alt="img" /></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="split-card"></div>
                    <div class="card-body pt-0 pb-2">
                        <div class="setvalue">
                            <ul class="" :style="$i18n.locale == 'ar' ? 'direction: rtl' : ''">
                                <li class="">
                                    <h5>{{ this.$t("pos.sub_total") }}</h5>
                                    <h6>{{ subtotal.toFixed(2) }}</h6>
                                </li>
                                <li>
                                    <h5>{{ this.$t("pos.tax") }}</h5>
                                    <h6>{{ (total - subtotal).toFixed(2) }}</h6>
                                </li>
                                <li class="total-value">
                                    <h5>{{ this.$t("pos.total") }}</h5>
                                    <h6>{{ total.toFixed(2) }}</h6>
                                </li>
                                <li class="difference-value">
                                    <h5> {{ this.$t('pos.difference') }} </h5>
                                    <input class="form-control" v-model="received_amount" @keyup="shownum()" type="number">
                                </li>
                            </ul>
                        </div>
                        <div class="setvaluecash">
                            <ul v-if="order_basket.length == 0 || received_amount == 0">
                                <li>
                                    <a disabled href="javascript:void(0);" class="paymentmethod">
                                        <img src="/assets/img/icons/cash.svg" alt="img" class="me-2" />
                                        {{ this.$t("pos.cash") }}
                                    </a>
                                </li>

                                <li>
                                    <a disabled href="javascript:void(0);" class="paymentmethod">
                                        <img src="/assets/img/icons/debitcard.svg" alt="img" class="me-2" />
                                        {{ this.$t("pos.debit") }}
                                    </a>
                                </li>
                                <li>
                                    <a disabled href="javascript:void(0);" class="paymentmethod">
                                        <img src="/assets/img/icons/scan.svg" alt="img" class="me-2" />
                                        {{ this.$t("pos.mada") }}
                                    </a>
                                </li>
                            </ul>
                            <ul v-else>
                                <li>
                                    <a @click="orderCash2()" href="javascript:void(0);" class="paymentmethod">
                                        <img src="/assets/img/icons/cash.svg" alt="img" class="me-2" />
                                        {{ this.$t("pos.cash") }}
                                    </a>
                                </li>

                                <li>
                                    <a @click="order(order_basket)" href="javascript:void(0);" class="paymentmethod">
                                        <img src="/assets/img/icons/debitcard.svg" alt="img" class="me-2" />
                                        {{ this.$t("pos.debit") }}
                                    </a>
                                </li>
                                <li>
                                    <a @click="qr()" href="javascript:void(0);" class="paymentmethod">
                                        <img src="/assets/img/icons/scan.svg" alt="img" class="me-2" />
                                        {{ this.$t("pos.mada") }}
                                    </a>
                                </li>
                            </ul>
                        </div>


                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="addModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Add Customer</h5>
                        <button style="margin: 0" type="button" class="btn-close" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <form class="inputs need-validation" novalidate>
                            <div class="mb-3">
                                <label for="message-text" class="col-form-label">
                                    Customer Name
                                </label>

                                <input class="form-control" type="text" v-model="customer.name" required />
                                <div class="invalid-feedback">You Must Enter A Name</div>
                            </div>
                            <div class="mb-3">
                                <label for="message-text" class="col-form-label">
                                    Customer Email
                                </label>

                                <input class="form-control" type="email" v-model="customer.email" />
                                <div class="valid-feedback"></div>
                            </div>
                            <div class="mb-3">
                                <label for="message-text" class="col-form-label">
                                    Customer Phone Number
                                </label>

                                <input class="form-control" type="text" v-model="customer.tel" required="" />
                                <div class="invalid-feedback">You Must Enter A Number</div>
                            </div>
                            <div class="mb-3">
                                <label for="" class="col-form-label"> Floor </label>

                                <select class="form-select form-select-lg" @change="changeTable(printer.floor)"
                                    v-model="printer.floor" name="" id="">
                                    <option v-for="(floor, index) in floors" :key="index" :value="floor.id">
                                        {{ floor.name }}
                                    </option>
                                </select>
                            </div>
                            <div class="mb-3">
                                <label for="" class="col-form-label"> Floor Tables </label>

                                <select class="form-select form-select-lg" v-model="printer.table" name="" id="">
                                    <option v-for="(floor_table, index) in floor_tables" :key="index"
                                        :value="floor_table.id">
                                        {{ floor_table.name }}
                                    </option>
                                </select>
                            </div>
                        </form>
                    </div>
                    <div class="buttons modal-footer d-flex">
                        <button @click="submitCash()" class="btn btn-success b-color mx-1" type="button">
                            Add
                        </button>

                        <button @click="submitCashSkip()" type="button" class="btn btn-danger mx-1" aria-label="Close">
                            skip</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="qrModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">QR Code</h5>

                    </div>
                    <div class="modal-body">
                        <div class="qr-image">
                            <img src="/assets/img/QR_code/qr-code.png" alt="">
                        </div>

                    </div>
                    <div class="buttons modal-footer d-flex">
                        <!-- <button  class="btn btn-success b-color mx-1" type="button">
              Add
            </button> -->
                        <button style="margin: 0" type="button" class="btn btn-danger" data-bs-dismiss="modal"
                            aria-label="Close"> close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";
import jwt_decode from "jwt-decode";
import { Modal } from "bootstrap";
import store from "@/state/store";
var state = store.state;
export default {

    components: {
        Carousel,
        Slide,
        Navigation,

    },

    mounted() {
        this.activateTab.active = this.getCategories
        const loggeduser = localStorage.pos_station;
        const decoded_user_data = JSON.parse(loggeduser);
        this.pos_station = decoded_user_data

        console.log("pos station :", this.pos_station)

        this.getCategories();
        this.getProductPriceList();
        this.getPosStationFloors();
    },
    data() {
        return {
            active_id: 1,
            product_active_id: "",
            categories: [],
            customer: {},
            printer: {},
            floors: [],
            state: state,
            floor_tables: [],
            order_basket: [],
            pos_products: [],
            pos_sale_orders: [],
            pos_sale_order_details: [],
            pos_sale_order_page: 1,
            pos_sale_order_limit: 20,
            pos_sale_order_total: 0,

            pos_price_list: [],
            pos_station: {},

            received_amount: 0.0,
            add_modal: null,
            qr_modal: null,
            sale_order_modal: null,
            sale_order_detail_modal: null,
            Product: ["Product", "Barcode"],
            Walk: ["Walk-in Customer", "Chris Moris"],
            settings: {
                itemsToShow: 2,
                snapAlign: "start",
            },

            breakpoints: {
                575: {
                    itemsToShow: 3,
                    snapAlign: "start",
                },
                // 700px and up
                767: {
                    itemsToShow: 3,
                    snapAlign: "start",
                },
                // 1024 and up
                1024: {
                    itemsToShow: 7,
                    snapAlign: "start",
                },
            },
        };
    },
    props: {
        activateTab: Object
    },
    computed: {

        total() {
            return this.order_basket.reduce((a, b) => a + +b?.sales_price * b?.qty, 0);
        },
        subtotal() {
            return this.order_basket.reduce((a, b) => a + +b?.original_price * b?.qty, 0);

        },
        
    },
    methods: {
        onChange(pageNumber, pageSize) {
            console.log("page :", pageNumber, "total :", pageSize)
        },



        shownum() {
            console.log('recived ammount :', this.received_amount)
        },
        qr() {
            var modal = new Modal("#qrModal")

            this.qr_modal = modal
            modal.show()
        },
        changeTable(record) {
            console.log("gggg :", record);
            this.http
                .get(`pos-station-floor-tables/floor?floor_id=${record}&`)
                .then((res) => {
                    this.floor_tables = res.data;
                });
        },
        getPosStationFloors() {
            const loggeduser = localStorage.pos_station;
            const decoded_user_data = JSON.parse(loggeduser);
            this.http
                .get(
                    `pos-station-floors?pos_station_id=${decoded_user_data.pos_station_id}`
                )
                .then((res) => {
                    this.floors = res.data;
                    console.log("floors :", this.floors);
                });
        },
        getProductPriceList() {
            const loggeduser = localStorage.pos_station;
            const decoded_user_data = JSON.parse(loggeduser);
            console.log("user data :", decoded_user_data);
            this.http
                .get(
                    `pos-stations-price-list/pos-stations`,null , null, true
                )
                .then((res) => {
                    this.pos_price_list = res.data;
                    console.log("price list :", this.pos_price_list);

                });
        },

        showAlert(index) {
            // Use sweetalert2


            this.order_basket.splice(index, 1);
        },
        deleteAll() {
            Swal.fire({
                title: this.$t("messages.alert.are_you_sure"),
                text: this.$t("messages.alert.unrevertable"),
                showCancelButton: true,
                confirmButtonColor: "#FF9F43",
                cancelButtonColor: "#d33",
                confirmButtonText: this.$t("messages.alert.delete_it"),
            }).then((result) => {
                if (result.isConfirmed) {
                    this.order_basket = [];
                    this.pos_products.map((e) => (e.class = ""));
                    console.log("ff");

                }
            });
        },
        getProductsByCategoryId(id) {
            console.log("step 100000 :", id)
            this.active_id = id;
            this.categories.forEach((e, index) => e.active = e.id == id)

            this.http.get(`pos-products/category/${id}`, null, null, true).then((res) => {
                console.log("products : ", res.data);
                this.pos_products = res.data;
            });
        },
        addItem(item) {
            const data = Object.assign({}, item);
            console.log("posStation :", this.pos_station)
            console.log('add item :', item)
            // console.log('price-list :', this.pos_price_list.pos_price_list_products)
            if (this.pos_station?.pos_station?.price_including_tax) {
                // pos_station.pos_station.price_including_tax ? ((item.original_price*item.qty)/(1+(pos_station.pos_station.tax/100))).toFixed(2) : (item.original_price* item.qty).toFixed(2)
                console.log("current data :", data)
                if(this.pos_price_list.length == 0){
                    console.log("ggg")
                    data.sales_price = (Number(data.sales_price) );
                    data.original_price = Number(data.sales_price)
                    
                }
                 else if (this.pos_price_list.pos_price_list_products.some((e) => e.product_id == data.id)) {
                    console.log("step 10 ")


                    if (this.pos_price_list.price_list_type.discount == null) {
                        console.log("price list with price_including_tax 33 :",this.price_list)
                        data.sales_price = (Number(data.sales_price) - (Number(data.sales_price) * (Number(this.pos_price_list.price_list_type.percentage) / 100)));
                        data.original_price = Number(data.sales_price)
                    } else if (this.pos_price_list.price_list_type.percentage == null) {
                        data.sales_price = Number(data.sales_price) - Number(this.pos_price_list.price_list_type.discount);
                        data.original_price = Number(data.sales_price)
                    }


                }
                console.log("ggg")

                console.log("original_price :" ,data.original_price, "tax :",data.tax,"sales price :",data.sales_price , "pos station tax :,",this.pos_station.pos_station.tax )
                
                data.original_price = Number(((data.sales_price*1)/(1+(this.pos_station.pos_station?.tax/100))))
                data.tax = (Number(data.sales_price) * (Number(this.pos_station?.pos_station?.tax) / 100))
                data.sales_price = (Number(data.sales_price))
                console.log("data :", data)

            }
            else {

                console.log("step 150");

                console.log('g44')
                if(this.pos_price_list.length == 0){
                    console.log("eess")
                    data.sales_price = (Number(data.sales_price) );
                    data.original_price = Number(data.sales_price)
                }
               else if (this.pos_price_list.pos_price_list_products.some((e) => e.product_id == data.id)) {

                 

                   
                    if (this.pos_price_list.price_list_type.discount == null) {
                        data.sales_price = (Number(data.sales_price) - (Number(data.sales_price) * (Number(this.pos_price_list.price_list_type.percentage) / 100)));
                        data.original_price = Number(data.sales_price)
                    } else if (this.pos_price_list.price_list_type.percentage == null) {
                        data.sales_price = Number(data.sales_price) - Number(this.pos_price_list.price_list_type.discount);
                        data.original_price = Number(data.sales_price)
                    }


                }
                else {
                    data.original_price = Number(data.sales_price)
                }
                // data.tax = (Number(data.sales_price) * (Number(this.pos_station?.pos_station?.tax) / 100))
                // data.sales_price = (Number(data.sales_price) - data.tax)
                console.log("original_price :" ,data.original_price, "tax :",data.tax,"sales price :",data.sales_price , "pos station tax :,",this.pos_station.pos_station.tax )

                data.original_price = Number((data.original_price*1))
                data.tax = (Number(data.sales_price) * (Number(this.pos_station?.pos_station?.tax) / 100))
                data.sales_price = (Number(data.sales_price)) + Number(data.sales_price)* (this.pos_station?.pos_station?.tax/100)
                console.log("data :", data)
                console.log("tax 001",this.pos_station.pos_station.tax)


            }



            console.log("pos products : ", this.pos_products);
            console.log({ data });
            console.log({ basket: this.order_basket });
            let found = false;
            this.order_basket.forEach((e) => {
                if (e.id === data.id) {
                    e.qty += 1;
                    found = true;
                }
            });
            if (found) return;
            data.class = "active";

            this.order_basket.push({
                id: data.id,
                name: data.name,
                original_price: Number(data.original_price).toFixed(2),
                sales_price: Number(data.sales_price).toFixed(2),
                tax: Number(data.tax).toFixed(2),
                img: data.img,
                qty: 1,
            });

            console.log("order basket  :", this.order_basket);
        },
        getCategories() {
            const loggeduser = localStorage.pos_station;
            const decoded_user_data = JSON.parse(loggeduser);
            this.http
                .get(
                    `pos-categories`,null,null,true
                )
                .then((res) => {
                    console.log("categories :", res.data);
                    this.categories = res.data;

                    if (this.categories.length > 0) {

                        console.log("category :", this.categories[0].id)
                        this.getProductsByCategoryId(this.categories[0].id, 0)

                    }
                    // this.categories.map((e, index) => {
                    //   console.log("index :", index);
                    //   e.class = this.active_id === e.id ? "active" : "";
                    //   console.log("pos items :", e);
                    // });


                });
        },


        orderCash2() {
            const modal = new Modal("#addModal");
            this.add_modal = modal;
            modal.show();
            console.log();
        },
        submitCashSkip() {
            const loggeduser = localStorage.pos_station;
            const decoded_user_data = JSON.parse(loggeduser);
            this.customer.pos_station_id = decoded_user_data.pos_station_id;
            console.log("basket price",this.order_basket.map((e) => ({    product_id: e.id,   qty: e.qty,     unit_price: e.sales_price,    })))

            this.http.post(`pos-sale-orders/cash`, {

                orders: this.order_basket.map((e) => ({    product_id: e.id,   qty: e.qty,     unit_price: e.sales_price,    })),
                pos_user_id: decoded_user_data?.id,
                pos_station: decoded_user_data?.pos_station_id,
                received_amount: this.received_amount,
                total_amount: this.total.toFixed(2)
            })
                .then((res) => {
                    Swal.fire(this.$t("messages.success"));
                    this.add_modal.hide();
                    this.order_basket = [];
                    this.customer.name = '';
                    this.customer.email = '';
                    this.customer.tel = '';
                    this.printer.floor = '';
                    this.printer.table = '';
                });
            console.log("basket :", this.order_basket);
        },
        submitCash() {
            const loggeduser = localStorage.pos_station;
            const decoded_user_data = JSON.parse(loggeduser);
            this.customer.pos_station_id = decoded_user_data.pos_station_id;
            console.log("basket price",this.order_basket.map((e) => ({    product_id: e.id,   qty: e.qty,     unit_price: e.sales_price,    })))
            this.http.post(`pos-sale-orders/cash`, {
                customer: this.customer,
                orders: this.order_basket.map((e) => ({
                    product_id: e.id,
                    qty: e.qty,
                    unit_price: e.sales_price,
                })),
                pos_station: decoded_user_data?.pos_station_id,
                pos_user_id: decoded_user_data?.id,
                printer: this.printer,
                received_amount: this.received_amount,
                total_amount: this.total.toFixed(2),

            })
                .then((res) => {
                    Swal.fire(this.$t("messages.success"));
                    this.add_modal.hide();
                    this.order_basket = [];
                    this.customer.name = '';
                    this.customer.email = '';
                    this.customer.tel = '';
                    this.printer.floor = '';
                    this.printer.table = '';
                });
            console.log("basket :", this.order_basket);
        },
    },

}
</script>


<style scoped>
.price-section {
    display: flex;
}

.price-section span {
    margin-right: 12px;
}

.difference-value {
    justify-content: space-between;
}

.difference-value h5 {
    font-size: 16px;
}

.difference-value input {
    width: 50%;
}

.price-list h3 {
    font-size: 20px;
    font-weight: 700;
    color: #5e5873;
    margin-bottom: 0;
}

.floating-btn {
    position: fixed;

    bottom: 3%;
    z-index: 999;
}

.floating-btn a {
    display: block;
    color: white;
    background-color: #5e5873;
    padding: 10px;
    border-radius: 10%;
    font-size: 12px;
    font-weight: 700;
}

.floating-btn a i {
    width: 100%;
}

.floating-btn a:hover {
    transform: scale(1.05);
    transition: 0.3s all ease-in;
}



.b-color {
    background-color: #ff9f43;
}

.qr-image {
    width: 485px;
    margin: auto;
}

.qr-image img {
    width: 100%;
}
</style>