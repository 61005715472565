<template>
  <div class="content">
    <div class="row" :style="this.$i18n.locale == 'ar' ? 'direction : rtl' : 'direction : ltr'">


      <div class="ant-table mb-3">
        <!---->
        <div class="ant-table-container">
          <div class="ant-table-content">
            <table style="table-layout: auto">
              <colgroup></colgroup>
              <thead class="ant-table-thead">
                <tr>
                  <th class="ant-table-cell" colstart="0" colend="0">
                    <!---->{{ $t("sale_orders.table.id")
                    }}<!---->
                  </th>
                  
                  <th class="ant-table-cell" colstart="0" colend="0">
                    <!---->{{ $t("sale_orders.table.total")
                    }}<!---->
                  </th>
                  
                  
                  <th class="ant-table-cell" colstart="12" colend="12">
                    <!---->{{ $t("sale_orders.table.created")
                    }}<!---->
                  </th>
                  <th class="ant-table-cell" colstart="1" colend="1">
                    <!---->{{ $t("sale_orders.table.customer")
                    }}<!---->
                  </th>
                  
                  
                    <th class="ant-table-cell" colstart="1" colend="1">
                      
                    </th>

                </tr>
              </thead>
              <tbody class="ant-table-tbody">
                <!---->
                <tr v-for="(pos_sale_order, index) in this.pos_sale_orders" :key="index"
                  class="ant-table-row ant-table-row-level-0">
                  <td class="ant-table-cell">
                    <!---->{{ index+1
                    }}<!---->
                  </td>

                  <td class="ant-table-cell">
                    <!---->{{
                      
                      pos_sale_order.total
                      }}<!---->
                  </td>
                  
                  <td class="ant-table-cell">
                    <!---->{{
                      new Date(pos_sale_order.created).toLocaleString(
                        "en-GB"
                        )
                      }}<!---->
                  </td>
                  <td class="ant-table-cell">
                    <!---->{{ pos_sale_order?.custmor?.name
                    }}<!---->
                  </td>
                  
                  <td class="ant-table-cell">
                    <!----> <button @click="getSaleOrderDetail(pos_sale_order)" type="button" name="" id=""
                      class="btn btn-primary btn-block"> {{ this.$t("sale_orders.table.details")
                      }} </button>
                    <!---->
                  </td>
                  

                </tr>
                <!---->
              </tbody>
              <!---->
            </table>
          </div>
        </div>
        <!---->
      </div>

      <a-pagination class="d-flex justify-content-center mt-4" @change="onChange" v-model:current="pos_sale_order_page"
        v-model:pageSize="pos_sale_order_limit" :total="pos_sale_order_total" />
    </div>

    <div class="modal fade" id="posSaleOrderDetailsModal" tabindex="-1" role="dialog" aria-labelledby="modalTitleId"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalTitleId">
              {{ $t("sale_order_details.title") }}
            </h5>
           
          </div>
          <div class="modal-body">
            <div class="ant-table mb-3">
              <!---->
              <div class="ant-table-container">
                <div class="ant-table-content">
                  <table style="table-layout: auto">
                    <colgroup></colgroup>
                    <thead class="ant-table-thead">
                      <tr>
                        <th class="ant-table-cell" colstart="0" colend="0">
                          <!---->{{ $t("sale_order_details.table.id")
                          }}<!---->
                        </th>
                        <th class="ant-table-cell" colstart="1" colend="1">
                          <!---->{{ $t("sale_order_details.table.product")
                          }}<!---->
                        </th>
                        <th class="ant-table-cell" colstart="1" colend="1">
                          <!---->{{ $t("sale_order_details.table.qty")
                          }}<!---->
                        </th>
                        <th class="ant-table-cell" colstart="2" colend="2">
                          <!---->{{
                            $t("sale_order_details.table.unit_price")
                          }}<!---->
                        </th>
                        <th class="ant-table-cell" colstart="2" colend="2">
                          <!---->{{
                            $t("pos.sub_total")
                          }}<!---->
                        </th>

                        


                      </tr>
                    </thead>
                    <tbody class="ant-table-tbody">
                      <!---->
                      <tr v-for="(pos_sale_order_detail, index) in this.pos_sale_order_details" :key="index"
                        class="ant-table-row ant-table-row-level-0">
                        <td class="ant-table-cell">
                          <!---->{{ pos_sale_order_detail.id
                          }}<!---->
                        </td>

                        <td class="ant-table-cell">
                          <!---->{{ pos_sale_order_detail?.product?.name
                          }}<!---->
                        </td>
                        <td class="ant-table-cell">
                          {{ pos_sale_order_detail?.qty }}
                        </td>
                        <td class="ant-table-cell">
                          <!---->{{
                            pos_sale_order_detail?.unit_price
                          }}<!---->
                        </td>
                        
                        <td class="ant-table-cell">
                          <!---->{{
                           ( pos_sale_order_detail.unit_price * pos_sale_order_detail?.qty).toFixed(2)
                          }}<!---->
                        </td>

     

                      </tr>
                      <tr>
                        <td colspan="4"> </td>
                        <td class="ant-table-cell fw-bold"> {{totalOrder.toFixed(2)}}</td>
                        </tr>
                      <!---->
                    </tbody>
                    <!---->
                  </table>
                </div>
              </div>
              <!---->
            </div>


          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              Close
            </button>

            .
          </div>

          
        </div>
      </div>
    </div>



  </div>
</template>

<script>
import jwt_decode from "jwt-decode";
import { Modal } from "bootstrap";
export default {

  data() {
    return {



      pos_sale_orders: [],
      pos_sale_order_details: [],
      pos_sale_order_page: 1,
      pos_sale_order_limit: 20,
      pos_sale_order_total: 0,
      pos_sale_order_details_page: 1,
      pos_sale_order_details_limit: 40,
      pos_sale_order_details_total: 0,
      totalOrder:0,
      pos_station: {},
      add_modal: null,
      qr_modal: null,
      sale_order_modal: null,
      sale_order_detail_modal: null,
      Product: ["Product", "Barcode"],
      Walk: ["Walk-in Customer", "Chris Moris"],
      settings: {
        itemsToShow: 2,
        snapAlign: "start",
      },

      breakpoints: {
        575: {
          itemsToShow: 3,
          snapAlign: "start",
        },
        // 700px and up
        767: {
          itemsToShow: 3,
          snapAlign: "start",
        },
        // 1024 and up
        1024: {
          itemsToShow: 7,
          snapAlign: "start",
        },
      },
    };
  },
  props: {
    activateTab: Object
  },
  mounted() {
    this.activateTab.active = this.getSaleOrder
    const loggeduser = localStorage.pos_station;
    const decoded_user_data = JSON.parse(loggeduser);
    this.pos_station = decoded_user_data?.pos_station

    console.log("pos station :", decoded_user_data)


    this.getSaleOrder()


  },
  methods: {
    onChange(pageNumber, pageSize) {
      console.log("page :", pageNumber, "total :", pageSize)
      this.getSaleOrder()
    },



    getSaleOrder() {
      const loggeduser = localStorage.pos_station;
      const decoded_user_data = JSON.parse(loggeduser);

      // this.sale_order_modal.show()
      this.http.post("pos-sale-orders/history", { page: this.pos_sale_order_page, limit: this.pos_sale_order_limit, pos_user_id: decoded_user_data.id }, null, true).then((res) => {
        this.pos_sale_orders = res.data
        this.pos_sale_order_total = res.tot
      })

    },
    getSaleOrderDetail(record) {
      var modal = new Modal("#posSaleOrderDetailsModal")
      console.log(record)
      this.http.post("pos-sale-order-details/sales", { page: this.pos_sale_order_details_page, limit: this.pos_sale_order_details_limit, sale_order_id: record.id }, null, true).then((res) => {
        console.log("res :", res.data)
        this.pos_sale_order_details = res.data
        
        this.pos_sale_order_details_total = res.tot
        this.totalOrder =0
        this.pos_sale_order_details.forEach(item => this.totalOrder += item.unit_price * item.qty  )
      })
      this.sale_order_detail_modal = modal
      this.sale_order_detail_modal.show()
    },












  },

}
</script>

<style></style>