<template>
  <!-- show payment Modal -->
  <div class="modal fade" id="showpayment" tabindex="-1" aria-labelledby="showpayment" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Show Payments</h5>
          <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Reference</th>
                  <th>Amount</th>
                  <th>Paid By</th>
                  <th>Paid By</th>
                </tr>
              </thead>
              <tbody>
                <tr class="bor-b1">
                  <td>2022-03-07</td>
                  <td>INV/SL0101</td>
                  <td>$ 0.00</td>
                  <td>Cash</td>
                  <td>
                    <a class="me-2" href="javascript:void(0);">
                      <img src="/assets/img/icons/printer.svg" alt="img" />
                    </a>
                    <a class="me-2" href="javascript:void(0);" data-bs-target="#editpayment" data-bs-toggle="modal"
                      data-bs-dismiss="modal">
                      <img src="/assets/img/icons/edit.svg" alt="img" />
                    </a>
                    <a class="me-2 confirm-text" href="javascript:void(0);">
                      <img src="/assets/img/icons/delete.svg" alt="img" />
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- show payment Modal -->

  <!-- show payment Modal -->
  <div class="modal fade" id="createpayment" tabindex="-1" aria-labelledby="createpayment" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Create Payment</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-6 col-sm-12 col-12">
              <div class="form-group">
                <label>Customer</label>
                <div class="input-groupicon">
                  <datepicker v-model="startdate" placeholder="Choose Date" class="picker cal-icon" :editable="true"
                    :clearable="false" />
                  <div class="addonset">
                    <img src="/assets/img/icons/calendars.svg" alt="img" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-sm-12 col-12">
              <div class="form-group">
                <label>Reference</label>
                <input type="text" value="INV/SL0101" />
              </div>
            </div>
            <div class="col-lg-6 col-sm-12 col-12">
              <div class="form-group">
                <label>Received Amount</label>
                <input type="text" value="0.00" />
              </div>
            </div>
            <div class="col-lg-6 col-sm-12 col-12">
              <div class="form-group">
                <label>Paying Amount</label>
                <input type="text" value="0.00" />
              </div>
            </div>
            <div class="col-lg-6 col-sm-12 col-12">
              <div class="form-group">
                <label>Payment type</label>
                <vue-select :options="Cash" placeholder="Cash" />
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group mb-0">
                <label>Note</label>
                <textarea class="form-control"></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-submit">Submit</button>
          <button type="button" class="btn btn-cancel" data-bs-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- show payment Modal -->

  <!-- edit payment Modal -->
  <div class="modal fade" id="editpayment" tabindex="-1" aria-labelledby="editpayment" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Edit Payment</h5>
          <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-6 col-sm-12 col-12">
              <div class="form-group">
                <label>Customer</label>
                <div class="input-groupicon">
                  <datepicker v-model="startdate" placeholder="Choose Date" class="picker" :editable="true"
                    :clearable="false" />
                  <div class="addonset">
                    <img src="/assets/img/icons/datepicker.svg" alt="img" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-sm-12 col-12">
              <div class="form-group">
                <label>Reference</label>
                <input type="text" value="INV/SL0101" />
              </div>
            </div>
            <div class="col-lg-6 col-sm-12 col-12">
              <div class="form-group">
                <label>Received Amount</label>
                <input type="text" value="0.00" />
              </div>
            </div>
            <div class="col-lg-6 col-sm-12 col-12">
              <div class="form-group">
                <label>Paying Amount</label>
                <input type="text" value="0.00" />
              </div>
            </div>
            <div class="col-lg-6 col-sm-12 col-12">
              <div class="form-group">
                <label>Payment type</label>
                <vue-select :options="Cash" placeholder="Cash" />
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group mb-0">
                <label>Note</label>
                <textarea class="form-control"></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-submit">Submit</button>
          <button type="button" class="btn btn-cancel" data-bs-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- edit payment Modal -->
</template>
<script>
import { ref } from "vue";
const currentDate = ref(new Date());
export default {
  data() {
    return {
      startdate: currentDate,
      Cash: ["Cash", "Online", "Inprogress"],
    };
  },
};
</script>
