<template>
  <!-- Main Wrapper -->
  
        <pageaddheader @click.prevent="addPriceListProducts()" :title="title ? title : ' '" :items="items" />

        <!-- /product list -->
        <div class="card">
          <div class="card-body">
            <div class="table-top">
              <div class="search-set">

                <div class="search-input">
                  <a class="btn btn-searchset"><img src="/assets/img/icons/search-white.svg" alt="img" /></a>
                  <div id="DataTables_Table_0_filter" class="dataTables_filter">
                    <label>
                      <input type="search" v-model="search"
                        @keyup="searchPriceListProducts({ search: search, col: 'name' })"
                        class="form-control form-control-sm" placeholder="Search..." aria-controls="DataTables_Table_0" />
                    </label>
                  </div>
                </div>
              </div>
              <div class="wordset">
                <ul>
                  <li>
                    <a data-bs-toggle="tooltip" data-bs-placement="top" title="pdf"><img src="/assets/img/icons/pdf.svg"
                        alt="img" /></a>
                  </li>
                  <li>
                    <a data-bs-toggle="tooltip" data-bs-placement="top" title="excel"><img
                        src="/assets/img/icons/excel.svg" alt="img" /></a>
                  </li>
                  <li>
                    <a data-bs-toggle="tooltip" data-bs-placement="top" title="print"><img
                        src="/assets/img/icons/printer.svg" alt="img" /></a>
                  </li>
                </ul>
              </div>
            </div>
            <!-- /Filter -->

            <!-- /Filter -->
            <div class="table-responsive">
              <a-table :columns="columns" :data-source="realData" :pagination="false">
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key === 'id'">
                    <span class="badges bg-lightgreen" v-if="record.status === 'Active'">{{ record.status ?
                      this.$t("dashboard.active") : this.$t("dashboard.inActive") }}</span>
                    <span class="badges bg-lightred" v-else="record.status === 'In Active'">{{ record.status ?
                      this.$t("dashboard.active") : this.$t("dashboard.inActive") }}</span>
                  </template>
                  <template v-if="column.key === 'action'">

                    <a class="me-3 confirm-text" href="javascript:void(0);" @click="showAlert(record)">
                      <img src="/assets/img/icons/delete.svg" alt="img" />
                    </a>
                  </template>
                  <template v-else-if="column.key === 'profile'">
                    <a href="javascript:void(0);" class="profile">
                      <img src="/assets/img/icons/menu-icon-04.svg" alt="">
                    </a>
                  </template>
                </template>
              </a-table>
              <a-pagination class="d-flex justify-content-center mt-4" @change="onChange" v-model:current="page"
                v-model:pageSize="limit" :total="total" />
            </div>
          </div>
        </div>
        <!-- /product list -->
    
</template>
<script>
import { ref } from "vue";
import jwt_decode from "jwt-decode";

const currentDate = ref(new Date());
// const columns = 


// you must start with empty Array for the a-table compoent to work
var realData = [];

export default {
  data() {
    return {

      page: 1,
      limit: 10,
      total: 0,
      search: '',
      realData,
      Select: '',
      filter: false,

      title: "menu.products_menu.items.priceListProducts",
      items: [
        {
          text: "dashboard.pos_price_list_products.add_product",
          addname: "",
        },
      ],
      
      columns: [

        {
          title: this.$t("tables.posPriceListProduct.id"),
          dataIndex: "id",
        },


        {
          title: this.$t("tables.posPriceListProduct.product"),
          dataIndex: "product_id",

        },

        {
          title: this.$t("tables.posPriceListProduct.created"),
          dataIndex: "created",

        },
        {
          title: this.$t("tables.posPriceListProduct.updated"),
          dataIndex: "updated",
        },

        {
          title: this.$t("tables.posPriceListProduct.action"),
          key: "action",
        },
      ],
      startdate: currentDate,
      Disable: ["Disable", "Enable"],
    };
  },
  mounted() {
    //   this.getPosUsers();
    this.getPosStationsPriceListById(this.$route.params.id)
    this.getPosStationPriceListProductsType()
    this.getPriceListProducts({ id: this.$route.params.id })

    this.$t(this.items[0].text)


    console.log("ggg");
  },
  methods: {
    onChange(pageNumber, pageSize) {
      console.log("page :", pageNumber, "total :", pageSize)
      this.getPriceListProducts({ id: this.$route.params.id })
    },
    showAlert(record) {
      // Use sweetalert2
      Swal.fire({
        title: this.$t("messages.alert.are_you_sure"),
        text: this.$t("messages.alert.unrevertable"),
        showCancelButton: true,
        confirmButtonColor: "#FF9F43",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          console.log('pop confirmed :', result)
          this.deletePriceListProducts(record)
        }
      });
    },
    getPosStationsPriceListById(id) {
      this.http.get(`pos-stations-price-list/${id}`, null, null, true).then((res) => {
        console.log('pos-stations-price-list res: ', res.data)
        this.title = res.data.name

      })
    },
    addPriceListProducts() {
      const loggeduser = localStorage.pos_station;
      const decoded_user_data = JSON.parse(loggeduser);
      console.log(' ff :', decoded_user_data)
      var select = this.Select
      console.log('vv :', select)
      var data = {
        title: "dashboard.pos_price_list_products.add_product",
        inputs: [

          {
            label: 'tables.posPriceListProduct.product',
            type: 'select',
            options: select,
            model: 'product_id',

          },






        ],
        buttons: [
          {
            text: "popups.submit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              obj.pos_station_price_list_id = this.$route.params.id
              console.log("OK Clicked", obj);
              obj.pos_stations_id = decoded_user_data.pos_station_id
              this.http.post(`pos-price-list-products`, obj, null, true).then((res) => {
                console.log(res.data)
                Swal.fire(this.$t("messages.success")).then(() => {
                  this.getPriceListProducts({ id: this.$route.params.id })
                  this.getPosStationPriceListProductsType()
                })
              })

            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "light",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);

    },

    getPriceListProducts(options) {
      // i empty the table content every time i call pos-user api
      this.realData = []
      this.http.post(`pos-price-list-products/pos_station_price_list/${options.id}`, { page: this.page, limit: this.limit }, null, true)
        .then((res) => {
          this.total = res.tot
          console.log("res :", res.data);
          res.data.map((e,index) => {
            this.realData.push({
              id: index+ 1,

              product_id: e.product.name,


              created: new Date(e.created).toLocaleString("en-GB").toString(),
              updated: new Date(e.updated).toLocaleString("en-GB").toString(),
            });
          });

          // console.log("real data :", this.realData);
        });
    },
    getPosStationPriceListProductsType() {

      this.http.get(`pos-stations-price-list/select`, { pos_station_price_list_id: this.$route.params.id}, null, true).then((res) => {
        this.Select = res.data


        console.log('fff :', this.Select)
      })
    },
    searchPriceListProducts(options) {

      this.http.post(`pos-price-list-products/search`, { "search": options.search, "page": 1, "limit": 25, 'posStationPriceList': this.$route.params.id }, null, true).then((res) => {
        this.realData = []
        console.log('search res', res)
        res.data.map((e,index) => {
          this.realData.push({
            id: index+ 1,

            product_id: e.product.name,
            // pos_user_roles: e.pos_user_roles.map(function (role) {  return ( role.pos_role.code.charAt(0).toUpperCase() + role.pos_role.code.slice(1) ); }).join(", ") .toString(),
            created: new Date(e.created).toLocaleString("en-GB").toString(),
            updated: new Date(e.updated).toLocaleString("en-GB").toString(),
          });
        });

      })

    },
    deletePriceListProducts(record) {
      this.http.delete(`pos-price-list-products`, record.id).then(() => {

        console.log('record :', record)
        Swal.fire("Deleted!", "Your file has been deleted.");

        this.getPriceListProducts({ id: this.$route.params.id })
        this.getPosStationPriceListProductsType()

      })
    },


  },


};
</script>
  

<style scoped>
.profile img {
  width: 26px;
}
</style>