<template>
  <!-- Main Wrapper -->

  <div class="col-lg-12">
    <div class="row chat-window">

      <!-- Chat Content -->
      <div class="col-12  chat-cont-right">
        <!-- Chat History -->
        <div class="card mb-0">
          <div class="card-header msg_head">
            <div class="d-flex bd-highlight">
              <a id="back_user_list" href="javascript:void(0)" class="back-user-list">
                <i class="fas fa-chevron-left"></i>
              </a>
              <div class="img_cont">
                <img class="rounded-circle user_img" src="/assets/img/customer/no-imagecustomer.png" alt="" />

              </div>
              <div class="user_info">
                <span><strong id="receiver_name">{{ $t("topics.pos_platform_technical_support") }}</strong></span>
                <p class="mb-0">{{ $t("messages.messages") }}</p>
              </div>
            </div>
          </div>

          <div class="card-body msg_card_body chat-scroll" ref="scrollElement" style="min-height: 440px;">
            <ul class="list-unstyled">
              <li v-for="(message, index) in messages" :key="index" :class="message.source ? 'received' : 'sent'"
                class="media d-flex ">
                <div class="avatar flex-shrink-0">
                  <img src="/assets/img/customer/no-imagecustomer.png" alt="User Image"
                    class="avatar-img rounded-circle" />

                </div>
                <div class="media-body flex-grow-1">
                  <div class="msg-box">
                    <div>
                      <p>{{ message.chat }}</p>
                      <ul class="chat-msg-info">
                        <li>
                          <div class="chat-time">
                            <span>{{ new Date(message.created).toLocaleString("en-GB") }}</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>

            </ul>
          </div>

          <div class="card-footer">
            <form @submit.prevent="msg({ chat: userInput })">

              <div class="input-group">
                <input v-model="userInput" class="form-control type_msg mh-auto empty_check"
                  :placeholder="$t('topics.type_your_message')" />
                <button class="btn btn-primary btn_send">
                  <vue-feather type="send"></vue-feather>
                </button>
              </div>

            </form>
          </div>
        </div>
      </div>
      <!-- Chat Content -->
    </div>
  </div>
</template>
<script>
import { socket } from "@/socket";

export default {
  name: "chat",
  data() {
    return {
      userInput: "",
      sender_name: "",
      receiver_name: "",
      current_route_params: "",
      messages: [
        { user: "", chat: "Hello. What can I do for you?", source: 1, has_images: 0, created: "8:30 AM" },
        { user: "", chat: "OMG", source: 1, has_images: 0, created: "8:30 AM" },
        { user: "", chat: "im your guy :)", source: 0, has_images: 0, created: "8:30 AM" },
        // ... other messages ...
      ],
    }
  },
  methods: {
    scrollToBottom() {
      const scrollElement = this.$refs.scrollElement;
      if (scrollElement) {
        // Scroll to the bottom of the container
        scrollElement.scrollTop = scrollElement.scrollHeight;
      }
    },
    async msg(obj) {

      obj.has_images = 0
      obj.topic_id = Number(this.$route.params.topic_id)
      obj.source = 1
      console.log(obj)
      this.userInput = ""



      setTimeout(() => {
        this.scrollToBottom()
      }, 200);
      socket.emit('chat', obj)


    },
    getChat() {
      this.http.get(`topics/chat?id=${this.$route.params.topic_id}&`, null, null, true).then((res) => {
        this.receiver_name = res.data?.name
        this.messages = res.data?.msgs
        this.messages.forEach((e, index) => {
          if (e.source)
            this.messages[index].user = this.receiver_name

          else
            this.messages[index].user = this.sender_name

        })



      })
    }
  },
  created() {

  },
  mounted() {
    this.current_route_params = this.$route.params.topic_id
    const loggeduser = localStorage.pos_station;
    const decoded_user_data = JSON.parse(loggeduser);
    this.sender_name = decoded_user_data?.name
    const scrollElement = this.$refs.scrollElement;


    setTimeout(() => {
      this.scrollToBottom()
    }, 200);
    console.log('sender name : ', this.sender_name)
    this.getChat()


    socket.emit("add_me_to_room", Number(this.$route.params.topic_id))
    socket.on("chat", async (obj) => {

      if (!obj.source)
        obj.user = this.receiver_name

      else
        obj.user = this.sender_name


      if (scrollElement.scrollTop > scrollElement.scrollHeight * 0.70) {
        // Scroll to the bottom of the container
        this.messages.push(obj)
        setTimeout(() => {
          scrollElement.scrollTop = scrollElement.scrollHeight;
        }, 200);
      }
      else {
        this.messages.push(obj)

      }


      console.log(obj)


    })

    // setInterval(() => {
    //   console.log("scroll :", scrollElement.scrollTop)
    //   console.log("scrollHeight :", scrollElement.scrollHeight)
    //   console.log("ClientHeight :", scrollElement.clientHeight)
    // }, 1000);

  },
  unmounted() {
    console.log('routes')
    socket.emit("remove_me_from_room", this.current_route_params)

  },
};
</script>
