export default [
  {
    path: "pos_hr_departments",
    component: () => import("../../views/pages/posHr/PosHrDeparments.vue"),
    meta: {
      headerClass: "header",
      sidebarClass: "",
      auth_roles: ["admin", "hr_manager"],
      auth_type: ["pos_user"],
    },
  },
  {
    path: "pos_hr_employees",
    component: () =>
      import("../../views/pages/posHr/PosHrEmployees/PosHrEmployees.vue"),
    meta: {
      headerClass: "header",
      sidebarClass: "",
      auth_roles: ["admin", "hr_manager"],
      auth_type: ["pos_user"],
    },
  },
  {
    path: "pos_hr_employees/:id",
    component: () =>
      import("../../views/pages/posHr/PosHrEmployees/EmployeeProfile.vue"),
    meta: {
      headerClass: "header",
      sidebarClass: "",
      auth_roles: ["admin", "hr_manager"],
      auth_type: ["pos_user"],
    },
  },
  {
    path: "pos_hr_departments/:id",
    component: () => import("../../views/pages/posHr/PosDepartmentsKpis.vue"),
    meta: {
      headerClass: "header",
      sidebarClass: "",
      auth_roles: ["admin", "hr_manager"],
      auth_type: ["pos_user"],
    },
  },
  {
    path: "pos_hr_holidays",
    component: () =>
      import("../../views/pages/posHr/PosHrHolidays/PosHrHolidaysPage.vue"),
    meta: {
      headerClass: "header",
      sidebarClass: "",
      auth_roles: ["admin", "hr_manager"],
      auth_type: ["pos_user"],
    },
  },
  {
    path: "pos_hr_leaves",
    component: () =>
      import("../../views/pages/posHr/PosHrLeaves/PosHrLeavesPage.vue"),
    meta: {
      headerClass: "header",
      sidebarClass: "",
      auth_roles: ["admin", "hr_manager"],
      auth_type: ["pos_user"],
    },
  },
  {
    path: "pos_hr_transfer",
    component: () =>
      import("../../views/pages/posHr/PosHrTransfeer/PosHrTransfeer.vue"),
    meta: {
      headerClass: "header",
      sidebarClass: "",
      auth_roles: ["admin", "hr_manager"],
      auth_type: ["pos_user"],
    },
  },
  {
    path: "pos_hr_complains",
    component: () =>
      import("../../views/pages/posHr/PosHrComplains/PosHrComplainsPage.vue"),
    meta: {
      headerClass: "header",
      sidebarClass: "",
      auth_roles: ["admin", "hr_manager"],
      auth_type: ["pos_user"],
    },
  },
  {
    path: "pos_hr_awards",
    component: () =>
      import("../../views/pages/posHr/PosHrAwards/PosHrAwardsPage.vue"),
    meta: {
      headerClass: "header",
      sidebarClass: "",
      auth_roles: ["admin", "hr_manager"],
      auth_type: ["pos_user"],
    },
  },
  {
    path: "pos_hr_performance_managements",
    component: () =>
      import(
        "../../views/pages/posHr/PosHrPerformanceManagements/PosHrPerformanceManagements.vue"
      ),
    meta: {
      headerClass: "header",
      sidebarClass: "",
      auth_roles: ["admin", "hr_manager"],
      auth_type: ["pos_user"],
    },
  },
  {
    path: "pos_hr_performance_managements/:id",
    component: () =>
      import(
        "../../views/pages/posHr/PosHrPerformanceManagements/EmployeePeformanceManagements.vue"
      ),
    meta: {
      headerClass: "header",
      sidebarClass: "",
      auth_roles: ["admin", "hr_manager"],
      auth_type: ["pos_user"],
    },
  },
];
