<template>
    <!-- Modal -->
    <div class="modal fade" :id="modalId" tabindex="-1" role="dialog" aria-labelledby="form-builder-title"
        aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <!-- if you want close the modal by clicking outside of it, remove data-bs-backdrop and data-bs-keyboard  -->
        <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header" :dir="this.$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
                    <h5 class="modal-title" id="form-builder-title">{{ formSchema.title }}</h5>
                </div>
                <div class="modal-body" :dir="this.$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
                    <div class="container-fluid">
                        <form ref="form_builder" class="need-validation " novalidate>
                            <div class="mb-3  row" v-for="(formInput, index) in formSchema.data" :key="index">
                                <template v-if="formInput.type == 'text'">
                                    <label :for="formInput.model" class="">{{ this.$t(formInput?.label) }}</label>
                                    <input type="text" class="form-control" :id="formInput.model + '-form-builder'"
                                        :name="formInput.model" v-model="formInput.value"
                                        :required="formInput.required ? formInput.required : false" />
                                    <div class="invalid-feedback">
                                        {{ formInput?.invalid_msg ? this.$t(formInput?.invalid_msg) : 'Name is Required .'
                                        }}
                                    </div>

                                </template>
                                <!------------------>
                                <!------------------>
                                <!------------------>
                                <!------------------>
                                <!------------------>
                                <template v-else-if="formInput.type == 'email'">
                                    <label :for="formInput.model + '-form-builder'" class="form-label">
                                        {{ this.$t(formInput?.label) }}
                                    </label>
                                    <input type="email" class="form-control" :id="formInput.model + '-form-builder'"
                                        :name="formInput.model" v-model="formInput.value"
                                        :required="formInput.required ? formInput.required : false" />
                                    <div class="invalid-feedback">
                                        {{ formInput?.invalid_msg ? this.$t(formInput?.invalid_msg) : 'Email is Required .'
                                        }}
                                    </div>
                                </template>
                                <!------------------>
                                <!------------------>
                                <!------------------>
                                <!------------------>
                                <!------------------>
                                <template v-else-if="formInput.type == 'password'">
                                    <label :for="formInput.model + '-form-builder'" class="form-label">
                                        {{ this.$t(formInput?.label) }}
                                    </label>
                                    <input type="password" class="form-control" :id="formInput.model + '-form-builder'"
                                        :name="formInput.model" v-model="formInput.value"
                                        :required="formInput.required ? formInput.required : false" />
                                    <div class="invalid-feedback">
                                        {{ formInput?.invalid_msg ? this.$t(formInput?.invalid_msg) : 
                                        'Password is Required.' }}
                                    </div>
                                </template>
                                <!------------------>
                                <!------------------>
                                <!------------------>
                                <!------------------>
                                <!------------------>
                                <template v-else-if="formInput.type == 'number'">
                                    <label :for="formInput.model + '-form-builder'" class="form-label">
                                        {{ this.$t(formInput?.label) }}
                                    </label>
                                    <input type="number" class="form-control" :id="formInput.model + '-form-builder'"
                                        :name="formInput.model" v-model="formInput.value"
                                        :required="formInput.required ? formInput.required : false" />
                                    <div class="invalid-feedback">
                                        {{ formInput?.invalid_msg ? this.$t(formInput?.invalid_msg) : 
                                            'This Field is Required.' }}
                                    </div>
                                </template>
                                <!------------------>
                                <!------------------>
                                <!------------------>
                                <!------------------>
                                <!------------------>
                                <template v-else-if="formInput.type == 'tel'">
                                    <label :for="formInput.model + '-form-builder'" class="form-label">
                                        {{ this.$t(formInput?.label) }}
                                    </label>
                                    <input type="tel" class="form-control" :id="formInput.model + '-form-builder'"
                                        :name="formInput.model" v-model="formInput.value"
                                        :required="formInput.required ? formInput.required : false" />
                                    <div class="invalid-feedback">
                                        {{ formInput?.invalid_msg ? this.$t(formInput?.invalid_msg) : 
                                            'This Field is Required.' }}
                                    </div>
                                </template>
                                <!------------------>
                                <!------------------>
                                <!------------------>
                                <!------------------>
                                <!------------------>
                                <template v-else-if="formInput.type == 'ckEditor'">
                                    <label :for="formInput.model + '-form-builder'" class="form-label">
                                        {{ this.$t(formInput?.label) }}
                                    </label>
                                    <div class="row justify-content-center">
                                        <ckeditor :editor="editor" class="form-control" v-model="formInput.value"
                                            :config="editorConfig" required>
                                        </ckeditor>
                                    </div>

                                    <div class="invalid-feedback">
                                        {{ formInput?.invalid_msg ? this.$t(formInput?.invalid_msg) : 
                                            'This Field is Required.' }}
                                    </div>
                                </template>
                                <!------------------>
                                <!------------------>
                                <!------------------>
                                <!------------------>
                                <!------------------>
                                <template v-else-if="formInput.type == 'file'">
                                    <label :for="formInput.model" class="form-label"> {{ formInput.label }} </label>
                                    <input class="form-control" type="file" :id="formInput.model" :name="formInput.model"
                                        @change="getFileInputValue($event)"
                                        :required="formInput.required ? formInput.required : false">
                                    <div class="invalid-feedback">
                                        {{ formInput?.invalid_msg ? this.$t(formInput?.invalid_msg) :
                                             'This Field is Required.' }}
                                    </div>

                                </template>
                                <!------------------>
                                <!------------------>
                                <!------------------>
                                <!------------------>
                                <!------------------>
                                <template v-else-if="formInput.type == 'select'">
                                    <label :for="formInput.model + '-form-builder'" class="form-label">{{
                                        this.$t(formInput?.label)
                                    }}</label>

                                    <select class="form-select form-select-md" v-model="formInput.value"
                                        :id="formInput.model + '-form-builder'" :name="formInput.model"
                                        :required="formInput.required ? formInput.required : false">
                                        <option v-for="(item, index) in formInput.options" :key="index" :value="item.value">
                                            {{ item.label }}</option>
                                    </select>

                                    <div class="invalid-feedback">
                                        {{ formInput?.invalid_msg ? this.$t(formInput?.invalid_msg)
                                            : 'This Field is Required.' }}
                                    </div>
                                </template>
                                <!------------------>
                                <!------------------>
                                <!------------------>
                                <!------------------>
                                <!------------------>
                                <template v-else-if="formInput.type == 'checkbox'">

                                    <div class="form-check">
                                        <label class="form-check-label" :for="formInput.model + '-form-builder'">
                                            {{ this.$t(formInput?.label) }}
                                        </label>
                                        <input class="form-check-input" type="checkbox" v-model="formInput.value"
                                            :id="formInput.model + '-form-builder'"
                                            :required="formInput.required ? formInput.required : false">
                                    </div>
                                    <div class="invalid-feedback">
                                        {{ formInput?.invalid_msg ? this.$t(formInput?.invalid_msg) : 
                                            'This Field is Required.' }}
                                    </div>
                                </template>
                                <!------------------>
                                <!------------------>
                                <!------------------>
                                <!------------------>
                                <!------------------>
                                <template v-else-if="formInput.type == 'radio'">
                                    <label class="form-label">{{ this.$t(formInput?.label) }} </label>
                                    <div class="form-check" v-for="(item, index) in formInput.options" :key="index">
                                        <input class="form-check-input" type="radio" v-model="formInput.value"
                                            :id="item.label + '-radio'" :value="item.value"
                                            :required="formInput.required ? formInput.required : false">
                                        <label class="form-check-label" :for="item.label + '-radio'">
                                            {{ item.label }}
                                        </label>
                                    </div>

                                    <div class="invalid-feedback">
                                        {{ formInput?.invalid_msg ? this.$t(formInput?.invalid_msg) :
                                             'This Field is Required.' }}
                                    </div>




                                </template>
                            </div>



                        </form>
                    </div>
                </div>
                <div class="modal-footer" :dir="this.$i18n.locale == 'ar' ? 'rtl' : 'ltr'">



                    <button v-for="(button, index) in formSchema.buttons" @click="formSub(button)" ref="formBuilderButtons"
                        :key="index" type="button" :class="`btn btn-${button.color} ${button?.cssClass || ''}  mx-1  `">{{
                            button.text }}</button>

                </div>
            </div>
        </div>
    </div>

    <!-- Modal trigger button -->
</template>
<script>
// import { Modal } from 'bootstrap';
import CKEditor from "@ckeditor/ckeditor5-vue";
import { ClassicEditor } from "@ckeditor/ckeditor5-editor-classic";
import { Essentials } from "@ckeditor/ckeditor5-essentials";
import { Bold, Italic } from "@ckeditor/ckeditor5-basic-styles";
import { Link } from "@ckeditor/ckeditor5-link";
import { Paragraph } from "@ckeditor/ckeditor5-paragraph";
import { Heading } from "@ckeditor/ckeditor5-heading";
import { Alignment } from "@ckeditor/ckeditor5-alignment";
import { List } from "@ckeditor/ckeditor5-list";

export default {
    data() {
        return {

            editor: ClassicEditor,
            editorData: "<p>Content of the editor.</p>",
            editorConfig: {
                language: this.$i18n.locale,
                plugins: [
                    Essentials,
                    Bold,
                    Italic,
                    Link,
                    Paragraph,
                    Heading,
                    Alignment,
                    List,
                ],
                toolbar: {
                    items: [
                        "heading",
                        "|",
                        "bold",
                        "italic",
                        "|",
                        "alignment",
                        "link",
                        "bulletedList",
                        "numberedList",
                        "undo",
                        "redo",
                    ],
                },
                alignment: {
                    options: ["right", "center", "left"],
                },

                heading: {
                    options: [
                        {
                            model: "paragraph",
                            title: "text",
                            class: "ck-heading_paragraph",
                        },
                        {
                            model: "heading1",
                            view: "h1",
                            title: "Heading 1",
                            class: "ck-heading_heading1",
                        },
                        {
                            model: "heading2",
                            view: "h2",
                            title: "Heading 2",
                            class: "ck-heading_heading2",
                        },
                    ],
                },
            },

            formBuilderResult: {},
            selected_file: null,
            completed_form_data: {},
            formModal: null

        };
    },
    components: {
        ckeditor: CKEditor.component
    },

    props: {
        formSchema: Object,
        modalId: String,
        modal_instance: Object
    },
    methods: {
        formSub(button) {
        

            // console.log('data :', this.completed_form_data)

           
            if (button.closer && button.task =="add") {
                console.log('ref form :', this.$refs.form_builder)

                if (!this.$refs.form_builder.checkValidity()) {
                    this.$refs.form_builder.classList.add('was-validated')



                    return;
                }

                var data1 = {}
                this.formSchema.data.forEach((input) => {
                    if (input.type == 'file') {
                        console.log("step 100 ", this.selected_file)
                        data1[input.model] = this.selected_file
                    }
                    else {
                        data1[input.model] = input.value
                    }
                })
                this.modal_instance.hide()
            }
            else if(button.closer && button.task == "exit") {
            this.modal_instance.hide()
            }
             button.handler(data1)



        },
        getFileInputValue(event) {
            //get the file input value
            const file = event.target.files;
            //assign it to our reactive reference value 
            this.selected_file = file[0]
            console.log("files :", this.selected_file)
        }
    },
    mounted() {
        console.log('form modal in component :', this.modal_instance)
        console.log("form builder Array :", this.formSchema.data);
        console.log('form builder data :', this.formBuilderResult)
        console.log('ref btn', this.$refs.formBuilderButtons)
    },
};
</script>

<style >
#container {
    width: 1000px;
    margin: 20px auto;
}

.ck.ck-editor {
    width: 100%;
}

.ck-editor__editable[role="textbox"] {
    /* editing area */
    min-height: 400px;
}

.ck-content .image {
    /* block images */
    max-width: 80%;
    margin: 20px auto;
}
</style>
