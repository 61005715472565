<template>
  <!-- Main Wrapper -->
 
        <pageaddheader  :title="this.$t(title)"  :items="items" @click="addKitchens()"  />

        <!-- /product list -->
        <div class="card">
          <div class="card-body">
            <div class="table-top">
              <div class="search-set">

                <div class="search-input">
                  <a class="btn btn-searchset"><img src="/assets/img/icons/search-white.svg" alt="img" /></a>
                  <div id="DataTables_Table_0_filter" class="dataTables_filter">
                    <label>
                      <input type="search" v-model="search" @keyup="searchKitchens({ search: search, col: 'name' })"
                        class="form-control form-control-sm" placeholder="Search..." aria-controls="DataTables_Table_0" />
                    </label>
                  </div>
                </div>
              </div>
              <div class="wordset">
                <ul>
                  <li>
                    <a data-bs-toggle="tooltip" data-bs-placement="top" title="pdf"><img src="/assets/img/icons/pdf.svg"
                        alt="img" /></a>
                  </li>
                  <li>
                    <a data-bs-toggle="tooltip" data-bs-placement="top" title="excel"><img
                        src="/assets/img/icons/excel.svg" alt="img" /></a>
                  </li>
                  <li>
                    <a data-bs-toggle="tooltip" data-bs-placement="top" title="print"><img
                        src="/assets/img/icons/printer.svg" alt="img" /></a>
                  </li>
                </ul>
              </div>
            </div>
            <!-- /Filter -->

            <!-- /Filter -->
            <div class="table-responsive">
              <a-table :columns="columns" :data-source="realData" :pagination="false">
                <template #bodyCell="{ column, record }">

                  <template v-if="column.key === 'action'">
                    <a href="javascript:void(0);" @click.prevent="editKitchens(record)">
                      <img src="/assets/img/icons/edit.svg" alt="img" />
                    </a>
                    <a class="me-3 confirm-text" href="javascript:void(0);" @click="showAlert(record)">
                      <img src="/assets/img/icons/delete.svg" alt="img" />
                    </a>
                  </template>
                </template>
              </a-table>
              <a-pagination class="d-flex justify-content-center mt-4" @change="onChange" v-model:current="page"
                v-model:pageSize="limit" :total="total" />
            </div>
          </div>
        </div>
        <!-- /product list -->
  
</template>
<script>
import { ref } from "vue";

const currentDate = ref(new Date());
// const columns =
// you must start with empty Array for the a-table compoent to work
var realData = [];

export default {
  data() {
    return {

      page: 1,
      limit: 10,
      total: 0,
      search: '',
      realData,
      Select: '',
      filter: false,

      title: "menu.kitchen_management.title",
      items: [
        {
          text: "menu.kitchen_management.add",
          addname: "",
        },
      ],

      columns: [

        {
          title: this.$t("tables.kitchens.id"),
          dataIndex: "no",
        },
        {
          title: this.$t("tables.kitchens.name"),
          dataIndex: "name",

        },
        {
          title: this.$t("tables.kitchens.pos_station"),
          dataIndex: "pos_station"
        },

        {
          title: this.$t("tables.kitchens.created"),
          dataIndex: "created",

        },
        {
          title: this.$t("tables.kitchens.updated"),
          dataIndex: "updated",
        },
        {
          key:"action"
        }

      ],
      startdate: currentDate,
      Disable: ["Disable", "Enable"],
    };
  },
  mounted() {
    //   this.getPosUsers();

    this.getKitchens()

    this.$t(this.items[0].text)


    console.log("ggg");
  },
  methods: {
    onChange(pageNumber, pageSize) {
      console.log("page :", pageNumber, "total :", pageSize)
      this.getKitchens()
    },
    showAlert(record) {
      // Use sweetalert2
      Swal.fire({
        title: this.$t("messages.alert.are_you_sure"),
        text: this.$t("messages.alert.unrevertable"),
        showCancelButton: true,
        confirmButtonColor: "#FF9F43",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          console.log('pop confirmed :', result)
          this.deleteKitchens(record)
        }
      });
    },
    addKitchens() {
      const loggeduser = localStorage.pos_station;
      const decoded_user_data = JSON.parse(loggeduser);
      console.log(' ff :', decoded_user_data)
      var select_job = this.Select
      console.log('vv :', select_job)
      var data = {
        title: "menu.kitchen_management.add",
        inputs: [

          {
            label: 'name',
            type: 'text',
            model: 'name',
            required: true,
            invalid_msg: "messages.validation.require_name",


          },





        ],
        buttons: [
          {
            text: "popups.submit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              console.log("OK Clicked", obj);
              obj.pos_station_id = decoded_user_data.pos_station_id
              this.http.post(`pos-kitchens`, obj, null, true).then((res) => {
                console.log(res.data)
                Swal.fire(this.$t("messages.success")).then(() => {
                  this.getKitchens()
                })
              })

            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "light",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);

    },
    editKitchens(options) {
      console.log('edit options :', options)
      var select_job = this.Select
      console.log('vv :', select_job)
      var data = {
        title: "menu.dashboard.edit_floor",
        inputs: [

          {
            label: 'name',
            type: 'text',
            model: 'name',
            value: options.name,
            required: true,
            invalid_msg: "messages.validation.require_name",



          },



        ],
        buttons: [
          {
            text: "popups.cancel",
            closer: true,
            color: "primary",
            handler: (obj) => {
              console.log("OK Clicked", obj);
              this.http.put(`pos-kitchens`, options.id, obj).then((res) => {

                console.log(res.data)
                Swal.fire(this.$t("messages.success")).then(() => {
                  this.getKitchens()
                })
              })

            },
          },
          {
          text: "popups.cancel",
            closer: true,
            color: "light",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);

    },
    getKitchens() {
      const loggeduser = localStorage.pos_station;
      const decoded_user_data = JSON.parse(loggeduser);
      // i empty the table content every time i call pos-user api
      this.realData = []
      this.http.post(`pos-kitchens/paginate`,
        {
          page: this.page,
          limit: this.limit,
          pos_station_id: decoded_user_data.pos_station_id
        }, null, true
      ).then((res) => {
        this.total = res.tot
        console.log("res :", res.data);
        res.data.map((e,index) => {
          this.realData.push({
            id: e.id,
            no: index+ 1,
            name: e.name,
            pos_station: e.pos_station.name,
            pos_station_id: e.pos_station_id,
            created: new Date(e.created).toLocaleString("en-GB").toString(),
            updated: new Date(e.updated).toLocaleString("en-GB").toString(),
          });
        });

        // console.log("real data :", this.realData);
      });
    },

    searchKitchens(options) {
      const loggeduser = localStorage.pos_station;
      const decoded_user_data = JSON.parse(loggeduser);
      console.log('search op :', options)
      this.http.post(`pos-kitchens/search`, { "col": options.col, "search": options.search, "pos_station_id": decoded_user_data.pos_station_id, "page": this.page, "limit": 25, "lang": localStorage.current_language }, null, true).then((res) => {
        this.realData = []
        console.log('search res', res)
        res.data.map((e,index) => {
          this.realData.push({
            id: index+ 1,
            name: e.name,
            pos_station: e.pos_station.name,
            pos_station_id: e.pos_station_id,
            created: new Date(e.created).toLocaleString("en-GB").toString(),
            updated: new Date(e.updated).toLocaleString("en-GB").toString(),
          });

        });

      })

    },
    deleteKitchens(record) {
      this.http.delete(`pos-kitchens`, record.id).then(() => {

        console.log('record :', record)
        Swal.fire("Deleted!", "Your file has been deleted.");

        this.getKitchens()
      })
    },


  },


};
</script>
    