<template>
  <!-- Main Wrapper -->
  
        <pageaddheader @click.prevent="addPosUsers()" :title="title" :title1="title1" :items="items" />

        <!-- /product list -->
        <div class="card">
          <div class="card-body">
            <div class="table-top">
              <div class="search-set">
                <div class="search-input">
                  <a class="btn btn-searchset"><img src="/assets/img/icons/search-white.svg" alt="img" /></a>
                  <div id="DataTables_Table_0_filter" class="dataTables_filter">
                    <label>
                      <input type="search" v-model="searchText" @keyup="
                                              searchPosUsers({ search: searchText, col: 'name' })
                                              " class="form-control form-control-sm" placeholder="Search..."
                        aria-controls="DataTables_Table_0" />
                    </label>
                  </div>
                </div>
              </div>
              <div class="wordset">
                <ul>
                  <li>
                    <a data-bs-toggle="tooltip" data-bs-placement="top" title="pdf"><img src="/assets/img/icons/pdf.svg"
                        alt="img" /></a>
                  </li>
                  <li>
                    <a data-bs-toggle="tooltip" data-bs-placement="top" title="excel"><img
                        src="/assets/img/icons/excel.svg" alt="img" /></a>
                  </li>
                  <li>
                    <a data-bs-toggle="tooltip" data-bs-placement="top" title="print"><img
                        src="/assets/img/icons/printer.svg" alt="img" /></a>
                  </li>
                </ul>
              </div>
            </div>
            <!-- /Filter -->
            <div class="card" id="filter_inputs" :style="{
                          display: filter ? 'block' : 'none',
                        }">
              <div class="card-body pb-0">
                <div class="row">
                  <div class="col-lg-2 col-sm-6 col-12">
                    <div class="form-group">
                      <input type="text" placeholder="Enter User Name" />
                    </div>
                  </div>
                  <div class="col-lg-2 col-sm-6 col-12">
                    <div class="form-group">
                      <input type="text" placeholder="Enter Phone" />
                    </div>
                  </div>
                  <div class="col-lg-2 col-sm-6 col-12">
                    <div class="form-group">
                      <input type="text" placeholder="Enter Email" />
                    </div>
                  </div>
                  <div class="col-lg-2 col-sm-6 col-12">
                    <div class="form-group">
                      <datepicker v-model="startdate" placeholder="Choose Date" class="picker cal-icon" :editable="true"
                        :clearable="false" />
                    </div>
                  </div>
                  <div class="col-lg-2 col-sm-6 col-12">
                    <div class="form-group">
                      <vue-select :options="Disable" placeholder="Disable" />
                    </div>
                  </div>
                  <div class="col-lg-1 col-sm-6 col-12 ms-auto">
                    <div class="form-group">
                      <a class="btn btn-filters ms-auto"><img src="/assets/img/icons/search-whites.svg" alt="img" /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Filter -->
            <div class="table-responsive">
              <a-table :columns="columns" :data-source="realData" :pagination="false">
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key === 'action'">
                    <a href="javascript:void(0);" @click.prevent="editPosUsers(record)">
                      <img src="/assets/img/icons/edit.svg" alt="img" />
                    </a>
                    <!-- </router-link> -->
                    <!-- <a class="me-3 confirm-text" href="javascript:void(0);" @click="showAlert(record)">
                      <img src="/assets/img/icons/delete.svg" alt="img" />
                    </a> -->
                  </template>
                </template>
              </a-table>
              <a-pagination class="d-flex justify-content-center mt-4" @change="onChange" v-model:current="page"
                v-model:pageSize="limit" :total="total" />
            </div>
          </div>
        </div>
        <!-- /product list -->

</template>
<script>
import { ref } from "vue";
const currentDate = ref(new Date());


// you must start with empty Array for the a-table compoent to work
var realData = [];

export default {
  data() {
    return {
      page: 1,
      limit: 20,
      total: 0,
      searchText: "",

      realData,
      filter: false,
      title: "userList.user_list",
      title1: "userList.manage_user",
      items: [
        {
          text: "userList.add",
          addname: "",
        },
      ],
      
      columns: [
        {
          title: "tables.userList.id",
          dataIndex: "no",
        },
        {
          title: "tables.userList.name",
          dataIndex: "name",
          // sorter: {
          //   compare: (a, b) => {
          //     a = a.Username.toLowerCase();
          //     b = b.Username.toLowerCase();
          //     return a > b ? -1 : b > a ? 1 : 0;
          //   },
          // },
        },
        {
          title: "tables.userList.email",
          dataIndex: "email",
          // sorter: {
          //   compare: (a, b) => {
          //     a = a.email.toLowerCase();
          //     b = b.email.toLowerCase();
          //     return a > b ? -1 : b > a ? 1 : 0;
          //   },
          // },
        },

        {
          title: "tables.userList.phone",
          dataIndex: "tel",
          // sorter: (a, b) => a.Phone.localeCompare(b.Phone),
        },
        {
          title: "tables.userList.role",
          dataIndex: "pos_user_roles",
          // sorter: {
          //   compare: (a, b) => {
          //     a = a.Role.toLowerCase();
          //     b = b.Role.toLowerCase();
          //     return a > b ? -1 : b > a ? 1 : 0;
          //   },
          // },
        },
        {
          title: "tables.userList.created",
          dataIndex: "created",
          // sorter: {
          //   compare: (a, b) => {
          //     a = a.CreatedOn.toLowerCase();
          //     b = b.CreatedOn.toLowerCase();
          //     return a > b ? -1 : b > a ? 1 : 0;
          //   },
          // },
        },
      


        {
          title: "tables.userList.action",
          key: "action",
          sorter: true,
        },
      ],
      startdate: currentDate,
      Disable: ["Disable", "Enable"],
    };
  },
 async mounted() {
   this.getPosUsers();
    this.getPosRoles();
    console.log("ggg");
    this.columns.forEach((obj) => {
    obj.title = this.$t(obj.title)
  });
  },
  watch:{

    

  },
  methods: {
    onChange(pageNumber, pageSize) {
      console.log("page :", pageNumber, "limit :", pageSize);
      this.getPosUsers();
    },
    showAlert(record) {
      // Use sweetalert2
      Swal.fire({
        title: this.$t("messages.alert.are_you_sure"),
        text: this.$t("messages.alert.unrevertable"),
        showCancelButton: true,
        confirmButtonColor: "#FF9F43",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          console.log("pop confirmed :", result);
          this.deletePosUser(record);
        }
      });
    },
    addPosUsers() {
      var data = {
        title: "userList.add",
        inputs: [
          {
            label: "tables.userList.name",
            type: "text",
            model: "name",
          },

          {
            label: "tables.userList.email",
            type: "email",
            model: "email",
          },
          {
            label: "tables.userList.phone",
            type: "tel",
            model: "tel",
          },
          {
            label: "tables.userList.pass",
            type: "password",
            model: "pass",
          },
          {
            label: "tables.userList.passConfirmed",
            type: "password",
            model: "passConfirmed",
          },
          {
            label: "tables.userList.role",
            type: "checkbox",

            options: this.Select.map((e) => ({
              check_id: e.id,
              label: e.label,
            })),
            model: "role_id",
          },
        ],
        buttons: [
          {
            text: "popups.submit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              console.log("OK Clicked", obj);

              if (obj.pass === obj.passConfirmed) {
                this.http.post(`pos-users`, obj, null, true).then((res) => {
                  console.log(res);
                  Swal.fire(this.$t("messages.success")).then(() => {
                    this.getPosUsers();
                  });
                });
              } else {
                Swal.fire(
                  this.$t("messages.errors.error"),
                  this.$t("messages.errors.password_confirmed")
                );
              }
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "light",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    editPosUsers(options) {
      console.log("edit record :", options);
      console.log("edit select:", this.Select);
      var options_roles = options.pos_roles
      var select_options = this.Select.map((e) => ({
        check_id: e.id,
        label: e.label,
      }));
        
      console.log("selected options :", select_options);
      console.log("fff ", options_roles);
      var data = {
        title: "userList.edit",
        inputs: [

          {
            label: "tables.userList.name",
            type: "text",
            model: "name",
            value: options.name,
          },

          {
            label: "tables.userList.email",
            type: "email",
            model: "email",
            value: options.email,
          },
          {
            label: "tables.userList.phone",
            type: "tel",
            model: "tel",
            value: options.tel,
          },
          {
            label: "tables.userList.role",
            type: "checkbox",
            options: this.Select.map((e) => ({
              check_id: e.id,
              label: e.label,
              checked: options_roles.includes(e.code),
            })),

            model: "role_id",
          },
          {
            label: "tables.userList.newPass",
            type: "password",
            model: "pass",
          },
          {
            label: "tables.userList.oldPass",
            type: "password",
            model: "old_pass",
          },

        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              console.log("OK Clicked", obj);
          
                if (obj.pass) {
                  this.http.put(`pos-users`, options.id, obj).then((res) => {
                    if(res.status){
                      
                      console.log(res.data);
                      Swal.fire(this.$t("messages.success")).then(() => {
                        this.getPosUsers();
                      });
                    }
                    else{
                      Swal.fire(this.$t("messages.errors.error"),res.msg).then(() => {
                        
                      });
                    }
                  });
                }
               else {
                delete obj.pass
                delete obj.old_pass
                this.http.put(`pos-users`, options.id, obj).then((res) => {
                  if(res.status){

                    console.log(res.data);
                    Swal.fire(this.$t("messages.success")).then(() => {
                      this.getPosUsers();
                    });
                  }
                  else{
                    Swal.fire(this.$t("messages.errors.error"))
                  
                  
                  }
                });
              }
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "light",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    getPosUsers() {
      // you MUST empty the table content every time i call pos-user api
      return new Promise((resolve)=>{

        this.realData = [];
        this.http
          .post(`pos-users/paginate`, { page: this.page, limit: this.limit },null, true)
          .then((res) => {
            this.total = res.tot;
  
            console.log("res :", res.data);
            res.data.map((e,index) => {
              this.realData.push({
                id: e.id,
                no: index +1,
                name: e.name,
                email: e.email,
                tel: e.tel,
                pos_user_roles: e.pos_user_roles.map(function (role) {
                
                    return localStorage["current_language"] =="en"?( role.pos_role.name_en.charAt(0).toUpperCase() +  role.pos_role.name_en.slice(1)  ) : ( role.pos_role.name.charAt(0).toUpperCase() +  role.pos_role.name.slice(1)  ) ;
                
                  })
                  .join(", ")
                  .toString(),
                created: new Date(e.created).toLocaleString("en-GB").toString(),
                // to use it in edit function 
                pos_roles: e.pos_user_roles.map( role => role.pos_role.code  )
              });
            });
  
            console.log("real data :", this.realData);
          });

      })
    },
    searchPosUsers(options) {
      this.http
        .post(`pos-users/search`, {
          col: options.col,
          search: options.search,
          page: 1,
          limit: 25,
        })
        .then((res) => {
          this.realData = [];
          console.log("search res", res);
          res.data.map((e,index) => {
            this.realData.push({
              id: index +1,
              name: e.name,
              email: e.email,
              tel: e.tel,
              pos_user_roles: e.pos_user_roles
                .map(function (role) {
                  return (
                    role.pos_role.code.charAt(0).toUpperCase() +
                    role.pos_role.code.slice(1)
                  );
                })
                .join(", ")
                .toString(),
              created: new Date(e.created).toLocaleString("en-GB").toString(),
              updated: new Date(e.updated).toLocaleString("en-GB").toString(),
            });
          });
        });
    },
    deletePosUser(record) {
      this.http.delete(`pos-users`, record.id).then((res) => {
        console.log("record :", record);
        Swal.fire("Deleted!", "Your file has been deleted.");

        this.getPosUsers();
      });
    },
    getPosRoles() {
      this.http.get(`pos-roles`, null, null, true).then((res) => {
        this.Select = res.data;
        console.log("select2 :", res.data);
        this.Select = this.Select.map((item) => ({
          id: item.id,
          label: this.$i18n.locale == "ar" ? item.name :item.name_en ,
          code : item.code
        }));
      });
    },
  },

  name: "userlists",
};
</script>
