// import { getFirebaseBackend } from "../../authUtils.js";

export const state = {
  currentUser: localStorage.getItem("pos_station"),
  token: null,
  // baseUrl: "http://localhost:5000/api/",
  // mediaUrl: "http://localhost:5000",
  // serverUrl: "http://localhost:5000",

  baseUrl: "https://smartpos.smart-node.net/api/",
  mediaUrl: "https://smartpos.smart-node.net/",
  serverUrl: "https://smartpos.smart-node.net/",
};
