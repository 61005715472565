<template>
            <pageaddheader :title="$t('topics.topics')" />

                <div class="card">
                    <div class="card-body">
                        <div class="table-top">
                            <div class="search-set">
                                <div class="search-input">
                                    <a class="btn btn-searchset"><img src="/assets/img/icons/search-white.svg"
                                            alt="img" /></a>
                                    <div id="DataTables_Table_0_filter" class="dataTables_filter">
                                        <label>
                                            <input type="search" v-model="search_term"
                                                @input="search({ search: search_term, col: 'name' })"
                                                class="form-control form-control-sm" placeholder="Search..."
                                                aria-controls="DataTables_Table_0" />
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="wordset">
                                <a @click="addTopic()" class="btn btn-add-modal" href="javascript:void(0);">
                                    <vue-feather type="plus" />
                                </a>
                            </div>
                        </div>

                        <table style="table-layout: auto">
                            <thead class="ant-table-thead">
                                <tr>
                                    <th class="ant-table-cell ant-table-selection-column" colstart="0" colend="0">
                                        <!---->
                                        <div class="ant-table-selection">
                                            <label class="ant-checkbox-wrapper"><span class="ant-checkbox"><input
                                                        type="checkbox" class="ant-checkbox-input" /><span
                                                        class="ant-checkbox-inner"></span></span><!----></label><!---->
                                        </div>
                                        <!---->
                                    </th>

                                    <th class="ant-table-cell   " colstart="1" colend="1">

                                        <span class="ant-table-column-title">id </span>
                                        <!---->
                                    </th>

                                    <th class="ant-table-cell " colstart="2" colend="2">

                                        <span class="ant-table-column-title"> {{ this.$t("topics.topics") }} </span>
                                        <!---->
                                    </th>
                                    <th class="ant-table-cell " colstart="3" colend="3">

                                        <span class="ant-table-column-title"> {{ this.$t("topics.status") }} </span>
                                        <!---->
                                    </th>

                                    <th class="ant-table-cell" colstart="4" colend="4">

                                        <span class="ant-table-column-title"> {{ this.$t("topics.descr") }} </span>
                                        <!---->
                                    </th>
                                    <th class="ant-table-cell" colstart="5" colend="5">

                                        <span class="ant-table-column-title"> {{ this.$t("topics.created") }} </span>
                                        <!---->
                                    </th>
                                    <th class="ant-table-cell" colstart="6" colend="6">

                                        <span class="ant-table-column-title"> {{ this.$t("topics.actions") }} </span>
                                        <!---->
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="ant-table-tbody">
                                <!---->
                                <tr v-for="(topic, index) in topics" :key="index"
                                    class="ant-table-row ant-table-row-level-0">
                                    <td class="ant-table-cell ant-table-selection-column">
                                        <label class="ant-checkbox-wrapper">
                                            <span class="ant-checkbox">
                                                <input type="checkbox" class="ant-checkbox-input" />
                                                <span class="ant-checkbox-inner"></span></span><!----></label><!---->
                                    </td>

                                    <td class="ant-table-cell"> {{ topic.id }} </td>

                                    <td class="ant-table-cell">{{ topic.title }} </td>
                                    <td class="ant-table-cell">
                                        <span class="badges bg-lightgreen" v-if="topic.status">{{
                                            this.$t('topics.opened') }}</span>
                                        <span class="badges bg-lightred" v-else>{{ this.$t('topics.closed')
                                        }}</span>

                                    </td>
                                    <td class="ant-table-cell"> {{ topic.descr }} </td>
                                    <td class="ant-table-cell">{{ new Date(topic.created).toLocaleString("en-GB") }} </td>
                                    <td class="ant-table-cell"> <router-link type="button"
                                            :to="{ name: 'chat', params: { topic_id: topic.id } }"
                                            class="btn btn-primary btn-lg btn-block "> {{ this.$t("topics.view") }}
                                        </router-link> </td>

                                    <!---->
                                </tr>
                            </tbody>

                            <!---->
                        </table>

                        <a-pagination class="d-flex justify-content-center mt-4" @change="onChange" v-model:current="page"
                            v-model:pageSize="limit" :total="total" />
                    </div>
                </div>




    
</template>

<script>
export default {
    data() {
        return {
            page: 1,
            limit: 10,
            total: 0,
           
            topics: []
        }
    },
    methods: {
        onChange(pageNumber, pageSize) {
            console.log("page :", pageNumber, "total :", pageSize);
        },

        getTopics() {
            const loggeduser = localStorage.pos_station;
            const decoded_user_data = JSON.parse(loggeduser);
            this.http.post("topics/topics", {
                user_type: "pos_admin",
                user_id: decoded_user_data.id,
                page: this.page,
                limit: this.limit
            }, null, true).then((res) => {
                console.log("res :", res.data)
                this.topics = res.data

            })
        },
        addTopic() {
            const loggeduser = localStorage.pos_station;
            const decoded_user_data = JSON.parse(loggeduser);
            console.log(' ff :', decoded_user_data)


            var data = {
                title: "topics.topic",
                inputs: [

                    {
                        label: 'topics.topics',
                        type: 'text',
                        model: 'title',

                    },
                    {
                        label: 'topics.descr',
                        type: "textarea",
                        model: "descr"
                    },


                ],
                buttons: [
                    {
                        text: "Add",
                        closer: true,
                        color: "primary",
                        handler: (obj) => {

                            console.log("OK Clicked", obj);
                            obj.type_id = decoded_user_data.id
                            obj.type = "pos_admin"
                            this.http.post(`topics`, obj, null, true).then((res) => {
                                console.log(res.data)
                                Swal.fire(this.$t("messages.success")).then(() => {
                                    this.getTopics()
                                })
                            })

                        },
                    },
                    {
                        text: "قفل",
                        closer: true,
                        color: "light",
                        handler: () => {
                            console.log("Like Clicked");
                        },
                    },
                ],
            };
            this.popup.modal(data);

        },
    },

    mounted() {
        this.getTopics()
    },


}
</script>
<style></style>