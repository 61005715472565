import tax from "../../../views/pages/tax/index.vue";

export default [
  {
    path: "tax",
    name: "tax",
    component: tax,
    meta: {
      headerClass: "header",
      auth_roles: ["admin", "hr_manager"],
      auth_type: ["pos_user"],
    },
  },
];
