import chat from "../../../views/pages/topic/chat.vue";
import topic from "../../../views/pages/topic/topics.vue";

export default [
  {
    path: "topic",
    name: "topic",
    component: topic,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      auth_roles: ["admin"],
      auth_type: ["pos_user"],
    },
  },
  {
    path: "topic/:topic_id",
    name: "chat",
    component: chat,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      auth_roles: ["admin"],
      auth_type: ["pos_user"],
    },
  },
];
