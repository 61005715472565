<template>
  <!-- Main Wrapper -->

        <pageaddheader @click.prevent="addPriceList()" :title="this.$t(title)" :items="items" />

        <!-- /product list -->
        <div class="card">
          <div class="card-body">
            <div class="table-top">
              <div class="search-set">

                <div class="search-input">
                  <a class="btn btn-searchset"><img src="/assets/img/icons/search-white.svg" alt="img" /></a>
                  <div id="DataTables_Table_0_filter" class="dataTables_filter">
                    <label>
                      <input type="search" v-model="search" @keyup="searchPriceList({ search: search, col: 'name' })"
                        class="form-control form-control-sm" placeholder="Search..." aria-controls="DataTables_Table_0" />
                    </label>
                    <!-- <button type="button" name="" id="" @click="addItemFromFormBuilder()"
                      class="btn btn-primary btn-lg btn-block"> g </button> -->
                  </div>
                </div>
              </div>
              <div class="wordset">
                <ul>
                  <li>
                    <a data-bs-toggle="tooltip" data-bs-placement="top" title="pdf"><img src="/assets/img/icons/pdf.svg"
                        alt="img" /></a>
                  </li>
                  <li>
                    <a data-bs-toggle="tooltip" data-bs-placement="top" title="excel"><img
                        src="/assets/img/icons/excel.svg" alt="img" /></a>
                  </li>
                  <li>
                    <a data-bs-toggle="tooltip" data-bs-placement="top" title="print"><img
                        src="/assets/img/icons/printer.svg" alt="img" /></a>
                  </li>
                </ul>
              </div>
            </div>
            <!-- /Filter -->

            <!-- /Filter -->
            <div class="table-responsive">
              <a-table :columns="columns" :data-source="realData" :pagination="false">
                <template #bodyCell="{ column, record }">

                  <template v-if="column.key === 'action'">
                    <a href="javascript:void(0);" @click.prevent="editPriceList(record)">
                      <img src="/assets/img/icons/edit.svg" alt="img" />
                    </a>
                    <a class="me-3 confirm-text" href="javascript:void(0);" @click="showAlert(record)">
                      <img src="/assets/img/icons/delete.svg" alt="img" />
                    </a>
                  </template>
                  <template v-else-if="column.key === 'id'">
                    <span class="badges bg-lightgreen" v-if="record.status">{{
                      this.$t('dashboard.active') }}</span>
                    <span @click="enable(record.id)" class="badges bg-lightred" v-else>{{ this.$t('dashboard.inActive')
                    }}</span>
                  </template>
                  <template v-else-if="column.key === 'profile'">
                    <router-link :to="{ name: 'priceListProducts', params: { id: record.id } }" class="profile">
                      <img src="/assets/img/icons/menu-icon-04.svg" alt="">
                    </router-link>
                  </template>
                </template>
              </a-table>
              <a-pagination class="d-flex justify-content-center mt-4" @change="onChange" v-model:current="page"
                v-model:pageSize="limit" :total="total" />
            </div>
          </div>
        </div>
        <!-- /product list -->
 

  <form-builder :modal_instance="form_modal" :modal-id="modalId" :formSchema="formSchema" />
</template>
<script>
import { ref } from "vue";
import formBuilder from "@/components/FormBuilder/formBuilder.vue"
import { Modal } from "bootstrap"
const currentDate = ref(new Date());
// const columns =;
const data = [
  {
    id: 1,
    Username: "Thomas",
    Phone: "+12163547758",
    email: "thomas@example.com",
    Role: "Admin",
    CreatedOn: "3/15/2022",
    Status: "Active",
  },
  {
    id: 2,
    Username: "504Benjamin",
    Phone: "123-456-888",
    email: "customer@example.com",
    Role: "Manager",
    CreatedOn: "2/27/2022",
    Status: "Restricted",
  },
  {
    id: 3,
    Username: "James 524",
    Phone: "+12163547758",
    email: "james@example.com",
    Role: "Salesman",
    CreatedOn: "2/27/2022",
    Status: "Restricted",
  },
  {
    id: 4,
    Username: "James 524",
    Phone: "+12163547758",
    email: "james@example.com",
    Role: "Salesman",
    CreatedOn: "2/27/2022",
    Status: "Restricted",
  },
  {
    id: 5,
    Username: "Bruklin2022",
    Phone: "123-456-888",
    email: "bruklin@example.com",
    Role: "Delivery Biker",
    CreatedOn: "2/27/2022",
    Status: "Active",
  },
  {
    id: 6,
    Username: "BeverlyWIN25",
    Phone: "+12163547758",
    email: "bruklin@example.com",
    Role: "Delivery Biker",
    CreatedOn: "2/27/2022",
    Status: "Active",
  },
  {
    id: 7,
    Username: "BHR256",
    Phone: "123-456-888",
    email: "Huber@example.com",
    Role: "Sales Executive",
    CreatedOn: "3/15/2022",
    Status: "Active",
  },
  {
    id: 8,
    Username: "Thomas",
    Phone: "+12163547758",
    email: "thomas@example.com",
    Role: "Admin",
    CreatedOn: "3/15/2022",
    Status: "Active",
  },
  {
    id: 9,
    Username: "504Benjamin",
    Phone: "123-456-888",
    email: "customer@example.com",
    Role: "Manager",
    CreatedOn: "2/27/2022",
    Status: "Restricted",
  },
  {
    id: 10,
    Username: "James 524",
    Phone: "+12163547758",
    email: "james@example.com",
    Role: "Salesman",
    CreatedOn: "2/27/2022",
    Status: "Restricted",
  },
  {
    id: 11,
    Username: "James 524",
    Phone: "+12163547758",
    email: "james@example.com",
    Role: "Salesman",
    CreatedOn: "2/27/2022",
    Status: "Restricted",
  },
  {
    id: 12,
    Username: "Bruklin2022",
    Phone: "123-456-888",
    email: "bruklin@example.com",
    Role: "Delivery Biker",
    CreatedOn: "2/27/2022",
    Status: "Active",
  },
  {
    id: 13,
    Username: "BeverlyWIN25",
    Phone: "+12163547758",
    email: "bruklin@example.com",
    Role: "Delivery Biker",
    CreatedOn: "2/27/2022",
    Status: "Active",
  },
  {
    id: 14,
    Username: "BHR256",
    Phone: "123-456-888",
    email: "Huber@example.com",
    Role: "Sales Executive",
    CreatedOn: "3/15/2022",
    Status: "Active",
  },
];

// you must start with empty Array for the a-table compoent to work
var realData = [];


export default {
  data() {
    return {

      page: 1,
      limit: 10,
      total: 0,
      search: '',
      realData,
      Select: '',
      formBuilderData: {},
      formSchema: {},
      modalId: 'modal-form-builder',
      form_modal: null,
      filter: false,

      title: "menu.price_list.title",
      items: [
        {
          text: "menu.price_list.add",
          addname: "",
        },
      ],
      columns:  [

        {
          title: this.$t("tables.productPriceList.id"),
          dataIndex: "no",
        },

        {
          title: this.$t("tables.productPriceList.name"),
          dataIndex: "name",

        },


        {
          title: this.$t("tables.productPriceList.pos_station"),
          dataIndex: "pos_stations_id",

        },
        {
          title: this.$t("tables.productPriceList.price_list_type"),
          dataIndex: "price_list_type_id"
        },
        {
          title: this.$t("tables.productPriceList.profile"),
          key: "profile"
        },
        {
          title: this.$t("tables.productPriceList.status"),
          dataIndex: "status",
          key: 'id'
        },
        {
          title: this.$t("tables.productPriceList.created"),
          dataIndex: "created",

        },
        {
          title: this.$t("tables.productPriceList.updated"),
          dataIndex: "updated",
        },

        {
          title: this.$t("tables.productPriceList.action"),
          key: "action",
        },
      ],
      
      startdate: currentDate,
      Disable: ["Disable", "Enable"],
    };
  },
  components: { formBuilder },
  mounted() {
    //   this.getPosUsers();

    this.form_modal = new Modal('#' + this.modalId)
    console.log('modal form :', this.form_modal)
    this.getPosStationPriceListType()
    this.getPriceList()

    this.$t(this.items[0].text)


    console.log("ggg");
  },
  methods: {
    addItemFromFormBuilder() {
      var data = {
        title: 'example title',
        data: [
          {
            label: 'name',
            type: 'text',

            model: 'name',
            required: true,
            invalid_msg: "real shit"
          },
          {
            label: 'email',
            type: 'email',

            model: 'email'
          },

          {
            label: 'password',
            type: 'password',
            value: '444246',
            model: 'pass'
          }, {
            label: "ck edit",
            type: "ckEditor",
            model: 'ck-ex',

          },
          {
            label: 'salary',
            type: 'number',

            model: 'salary'
          },
          {
            label: "file type",
            type: "file",
            model: 'img',

          },
          {
            label: "options ex",
            type: "select",
            model: "ex",
            options: [
              { value: 1, label: "na" },
              { value: 2, label: 'la' }
            ],

          }
        ],
        buttons: [
          {
            text: "Add",
            closer: true,
            task: "add",
            color: "success",
            handler: (obj) => {

              console.log('gg', obj)


            }
          },
          {
            text: "قفل",
            closer: true,
            task: "exit",
            color: "light",
            handler: () => {
              console.log("closer Clicked");
            },
          },

        ]
      }
      this.formSchema = data
      this.form_modal.show()
    },
    enable(id) {
      var loggeduser = localStorage.pos_station;
      var decoded_user_data = JSON.parse(loggeduser);

      Swal.fire(this.$t('messages.alert_msg'), this.$t('messages.alert.enable_confirm')).then(() => {



        this.http.put('pos-stations-price-list/enable', id, { "status": 1, "pos_stations_id": decoded_user_data.pos_station_id }).then(() => {
          Swal.fire(this.$t("messages.success")).then(() => {
            this.getPriceList()
          })


        })
      })




    },

    onChange(pageNumber, pageSize) {
      console.log("page :", pageNumber, "total :", pageSize)
      this.getPriceList()
    },
    showAlert(record) {
      // Use sweetalert2
      Swal.fire({
        title: this.$t("messages.alert.are_you_sure"),
        text: this.$t("messages.alert.unrevertable"),
        showCancelButton: true,
        confirmButtonColor: "#FF9F43",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          console.log('pop confirmed :', result)
          this.deletePriceList(record)
        }
      });
    },
    addPriceList() {
      const loggeduser = localStorage.pos_station;
      const decoded_user_data = JSON.parse(loggeduser);
      console.log(' ff :', decoded_user_data)
      var select_job = this.Select
      console.log('vv :', select_job)
      var data = {
        title: "menu.price_list.add",
        inputs: [

          {
            label: 'tables.productPriceList.name',
            type: 'text',
            model: 'name',

          },


          {
            label: 'tables.productPriceList.price_list_type',
            type: "select",
            options: select_job,
            model: "price_list_type_id"
          },


        ],
        buttons: [
          {
            text: "popups.submit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              obj.perecntage ? obj.perecntage = obj.perecntage : obj.perecntage = 0;
              obj.discount ? obj.discount = obj.discount : obj.discount = 0
              console.log("OK Clicked", obj);
              obj.pos_stations_id = decoded_user_data.pos_station_id
              this.http.post(`pos-stations-price-list`, obj, null, true).then((res) => {
                console.log(res.data)
                Swal.fire(this.$t("messages.success")).then(() => {
                  this.getPriceList()
                })
              })

            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "light",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);

    },
    editPriceList(options) {
      console.log('edit options :', options)
      var select_job = this.Select
      console.log('vv :', select_job)
      var data = {
        title: "menu.price_list.edit",
        inputs: [

          {
            label: 'tables.productPriceList.name',
            type: 'text',
            model: 'name',
            value: options.name

          },

          {
            label: 'tables.productPriceList.price_list_type',
            type: "select",
            options: select_job,
            model: "price_list_type_id",
            value: options.price_list_type_id
          },







        ],
        buttons: [
          {
            text: "popups.submit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              console.log("OK Clicked", obj);
              this.http.put(`pos-stations-price-list`, options.id, obj).then((res) => {

                console.log(res.data)
                Swal.fire(this.$t("messages.success")).then(() => {
                  this.getPriceList()
                })
              })

            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "light",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);

    },
    getPriceList() {
      const loggeduser = localStorage.pos_station;
      const decoded_user_data = JSON.parse(loggeduser);
      // i empty the table content every time i call pos-user api
      this.realData = []
      this.http.post(`pos-stations-price-list/paginate`, { page: this.page, limit: this.limit, pos_stations_id: decoded_user_data.pos_station_id }, null, true)
        .then((res) => {
          this.total = res.tot
          console.log("res :", res.data);
          res.data.map((e,index) => {
            this.realData.push({
              id: e.id,
              no: index+ 1,
              name: e.name,
              pos_stations_id: e.pos_stations_id,
              price_list_type_id: e.price_list_type.name,
              status: e.status,
              created: new Date(e.created).toLocaleString("en-GB").toString(),
              updated: new Date(e.updated).toLocaleString("en-GB").toString(),
            });
          });

          // console.log("real data :", this.realData);
        });
    },
    getPosStationPriceListType() {

      this.http.get(`pos-station-price-list-type`, null, null, true).then((res) => {
        this.Select = res.data
        this.Select = this.Select.map(item => ({ value: item.id, label: item.name }))

        console.log('fff :', this.Select)
      })
    },
    searchPriceList(options) {

      this.http.post(`pos-employees/search`, { "col": options.col, "search": options.search, "page": 1, "limit": 25, lang: localStorage.current_language }, null, true).then((res) => {
        this.realData = []
        console.log('search res', res)
        res.data.map((e,index) => {
          this.realData.push({
            id: index+ 1,

            name: e.name,
            birthdate: e.birthdate,
            basic_salary: e.basic_salary,
            // pos_user_roles: e.pos_user_roles.map(function (role) {  return ( role.pos_role.code.charAt(0).toUpperCase() + role.pos_role.code.slice(1) ); }).join(", ") .toString(),
            created: new Date(e.created).toLocaleString("en-GB").toString(),
            updated: new Date(e.updated).toLocaleString("en-GB").toString(),
          });
        });

      })

    },
    deletePriceList(record) {
      this.http.delete(`pos-stations-price-list`, record.id).then(() => {

        console.log('record :', record)
        Swal.fire("Deleted!", "Your file has been deleted.");

        this.getPriceList()
      })
    },


  },


};
</script>
  

<style scoped>
.profile img {
  width: 26px;
}
</style>