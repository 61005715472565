<template>
  <!-- Main Wrapper -->
  
        <pageaddheader @click.prevent="addFloor()" :title="this.$t(title)" :items="items" />

        <!-- /product list -->
        <div class="card">
          <div class="card-body">
            <div class="table-top">
              <div class="search-set">

                <div class="search-input">
                  <a class="btn btn-searchset"><img src="/assets/img/icons/search-white.svg" alt="img" /></a>
                  <div id="DataTables_Table_0_filter" class="dataTables_filter">
                    <label>
                      <input type="search" v-model="search" @keyup="searchFloor({ search: search, col: 'name' })"
                        class="form-control form-control-sm" placeholder="Search..." aria-controls="DataTables_Table_0" />
                    </label>
                  </div>
                </div>
              </div>
              <div class="wordset">
                <ul>
                  <li>
                    <a data-bs-toggle="tooltip" data-bs-placement="top" title="pdf"><img src="/assets/img/icons/pdf.svg"
                        alt="img" /></a>
                  </li>
                  <li>
                    <a data-bs-toggle="tooltip" data-bs-placement="top" title="excel"><img
                        src="/assets/img/icons/excel.svg" alt="img" /></a>
                  </li>
                  <li>
                    <a data-bs-toggle="tooltip" data-bs-placement="top" title="print"><img
                        src="/assets/img/icons/printer.svg" alt="img" /></a>
                  </li>
                </ul>
              </div>
            </div>
            <!-- /Filter -->

            <!-- /Filter -->
            <div class="table-responsive">
              <a-table :columns="columns" :data-source="realData" :pagination="false">
                <template #bodyCell="{ column, record }">

                  <template v-if="column.key === 'action'">
                    <a href="javascript:void(0);" @click.prevent="editFloor(record)">
                      <img src="/assets/img/icons/edit.svg" alt="img" />
                    </a>
                    <a class="me-3 confirm-text" href="javascript:void(0);" @click="showAlert(record)">
                      <img src="/assets/img/icons/delete.svg" alt="img" />
                    </a>
                  </template>
                  <template v-else-if="column.key === 'profile'">
                    <a class="me-3 confirm-text" href="javascript:void(0);" @click="floorTableModal(record)">
                      <img src="/assets/img/icons/plus-circle.svg" alt="img" />
                    </a>
                  </template>

                </template>
              </a-table>
              <a-pagination class="d-flex justify-content-center mt-4" @change="onChange()" v-model:current="page"
                v-model:pageSize="limit" :total="total" />
            </div>
          </div>
        </div>

        <!-- Modal trigger button -->

        <!--  Floor Table Modal Body ------>
        <!--------------------------------->
        <!--------------------------------->
        <!--------------------------------->
        <!--------------------------------->
        <!--------------------------------->
        <!--------------------------------->
        <div class="modal fade" id="floor-table-modal" tabindex="-1" role="dialog" aria-labelledby="modalTitleId"
          aria-hidden="true">
          <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="modalTitleId">Floor Tables</h5>
                <div class="search-set">

                  <div class="search-input">
                    <a class="btn btn-searchset"><img src="/assets/img/icons/search-white.svg" alt="img" /></a>
                    <div id="DataTables_Table_0_filter" class="dataTables_filter">
                      <label>
                        <input type="search" v-model="searchFloorTable"
                          @keyup="searchFloorTableFun({ search: searchFloorTable, col: 'name', id :floor_id })"
                          class="form-control form-control-sm" placeholder="Search..."
                          aria-controls="DataTables_Table_0" />
                      </label>
                    </div>
                  </div>
                </div>
                <button type="button" @click="addFloorTable(this.floor_id)" class="btn btn-primary"> {{ this.$t("menu.floors.floor_table.add") }} </button>
              </div>
              <div class="modal-body">
                <div class="ant-table mb-3"><!---->
                  <div class="ant-table-container">
                    <div class="ant-table-content">
                      <table style="table-layout: auto;">
                        <colgroup></colgroup>
                        <thead class="ant-table-thead">
                          <tr>
                            <th class="ant-table-cell" colstart="0" colend="0"><!---->{{this.$t("tables.floor_table.id")}}<!----></th>
                            <th class="ant-table-cell" colstart="1" colend="1"><!---->{{this.$t("tables.floor_table.name")}}<!----></th>
                            <th class="ant-table-cell" colstart="2" colend="2"><!---->{{this.$t("tables.floor_table.seats")}}<!----></th>
                            <th class="ant-table-cell" colstart="3" colend="3"><!---->{{this.$t("tables.floor_table.created")}}<!----></th>
                            <th class="ant-table-cell" colstart="4" colend="4"><!---->{{ this.$t("tables.floor_table.updated") }}<!----></th>
                            <th class="ant-table-cell" colstart="6" colend="6"><!---->{{ this.$t("tables.floor_table.action") }}<!----></th>
                          </tr>
                        </thead>
                        <tbody class="ant-table-tbody"><!---->
                          <tr v-for="(floorTable, index) in this.floorTables" :key="index"
                            class="ant-table-row ant-table-row-level-0">
                            <td class="ant-table-cell"><!---->{{ floorTable.id }}<!----></td>
                            <td class="ant-table-cell"><!---->{{ floorTable.name }}<!----></td>
                            <td class="ant-table-cell"><!---->{{ floorTable.seats }}<!----></td>
                            <td class="ant-table-cell"><!---->{{ new Date(floorTable.created).toLocaleString("en-GB")
                                                          }}<!----></td>
                            <td class="ant-table-cell"><!---->{{ new Date(floorTable.updated).toLocaleString("en-GB")
                                                          }}<!----></td>
                            <td class="ant-table-cell"><!---->
                              <a @click="editFloorTable(floorTable)" href="javascript:void(0);">
                                <img src="assets/img/icons/edit.svg" alt="img">
                              </a>
                              <a @click="deleteFloorTable(floorTable)" class="me-3 confirm-text"
                                href="javascript:void(0);">
                                <img src="assets/img/icons/delete.svg" alt="img">
                              </a><!---->
                            </td>
                          </tr><!---->

                        </tbody><!---->
                      </table>
                    </div>
                  </div><!---->
                </div>

                <a-pagination class="d-flex justify-content-center mt-4" @change="onChange2"
                  v-model:current="floorTablePage" v-model:pageSize="floorTableLimit" :total="floorTableTotal" />



              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>

              </div>
            </div>
          </div>
        </div>


        <!-- Optional: Place to the bottom of scripts -->

        <!-- /product list -->
    
</template>
<script>
import { ref } from "vue";
import { Modal } from "bootstrap";
const currentDate = ref(new Date());
// const columns =
// you must start with empty Array for the a-table compoent to work
var realData = [];

export default {
  data() {
    return {

      page: 1,
      limit: 10,
      total: 0,
      search: '',
      searchFloorTable:'',
      floor_id: null,
      realData,

      floorTablePage: 1,
      floorTableLimit: 5,
      floorTableTotal: null,
      floorTables: [],
      Select: '',
      filter: false,

      title: "menu.floors.title",
      items: [
        {
          text: "menu.floors.add",
          addname: "",
        },
      ],

      columns: [

        {
          title: this.$t("tables.floors.id"),
          dataIndex: "id",
        },
        {
          title: this.$t("tables.floors.name"),
          dataIndex: "name",

        },
        {
          title: this.$t("tables.floors.pos_station"),
          dataIndex: "pos_station"
        },

        {
          title: this.$t("tables.floors.created"),
          dataIndex: "created",

        },
        {
          title: this.$t("tables.floors.updated"),
          dataIndex: "updated",
        },
        {
          title: this.$t("tables.floors.tables"),
          key: "profile"
        },

        {
          title: this.$t("tables.floors.action"),
          key: "action",
        },
      ],
      startdate: currentDate,
      Disable: ["Disable", "Enable"],
    };
  },
  mounted() {
    //   this.getPosUsers();

    this.getFloor()

    this.$t(this.items[0].text)


    console.log("ggg");
  },
  methods: {
    onChange(pageNumber, pageSize) {
      console.log("page :", pageNumber, "total :", pageSize)
      this.getFloor()
    },

    onChange2(pageNumber, pageSize) {

      console.log("page :", pageNumber, "limit :", pageSize, 'total :', this.floorTableTotal)
      console.log("page :", this.floorTablePage, "limit :", this.floorTableLimit, "total :", this.floorTableTotal)
      this.getFloorTable(this.floor_id)

    },

    floorTableModal(record) {
      this.floorTables = []
      this.floorTables = record
      this.floor_id = record.id
      this.floorTablePage = 1
      console.log('floor id :', this.floor_id)
      this.getFloorTable(record.id)
      console.log('record : ', record)
      var modal = new Modal('#floor-table-modal');
      modal.show()
      
    },
    showAlert(record) {
      // Use sweetalert2
      Swal.fire({
        title: this.$t("messages.alert.are_you_sure"),
        text: this.$t("messages.alert.unrevertable"),
        showCancelButton: true,
        confirmButtonColor: "#FF9F43",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          console.log('pop confirmed :', result)
          this.deleteFloor(record)
        }
      });
    },
    addFloor() {
      const loggeduser = localStorage.pos_station;
      const decoded_user_data = JSON.parse(loggeduser);
      console.log(' ff :', decoded_user_data)
      var select_job = this.Select
      console.log('vv :', select_job)
      var data = {
        title: "menu.floors.add",
        inputs: [

          {
            label: 'tables.floors.name',
            type: 'text',
            model: 'name',
            required: true,
            invalid_msg: "messages.validation.require_name",


          },





        ],
        buttons: [
          {
            text: "popups.submit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              console.log("OK Clicked", obj);
              obj.pos_station_id = decoded_user_data.pos_station_id
              this.http.post(`pos-station-floors`, obj, null, true).then((res) => {
                console.log(res.data)
                Swal.fire(this.$t("messages.success")).then(() => {
                  this.getFloor()
                })
              })

            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "light",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);

    },
    editFloor(options) {
      console.log('edit options :', options)
      var select_job = this.Select
      console.log('vv :', select_job)
      var data = {
        title: "menu.floors.edit",
        inputs: [

          {
            label: 'tables.floors.name',
            type: 'text',
            model: 'name',
            value: options.name,
            required: true,
            invalid_msg: "messages.validation.require_name",



          },



        ],
        buttons: [
          {
            text: "popups.submit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              console.log("OK Clicked", obj);
              this.http.put(`pos-station-floors`, options.id, obj).then((res) => {

                console.log(res.data)
                Swal.fire(this.$t("messages.success")).then(() => {
                  this.getFloor()
                })
              })

            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "light",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);

    },
    getFloor() {
      const loggeduser = localStorage.pos_station;
      const decoded_user_data = JSON.parse(loggeduser);
      // i empty the table content every time i call pos-user api
      this.realData = []
      this.http.post(`pos-station-floors/paginate`,
        {
          page: this.page,
          limit: this.limit,
          pos_station_id: decoded_user_data.pos_station_id
        }, null, true
      ).then((res) => {
        this.total = res.tot
        console.log("res :", res.data);
        res.data.map((e) => {
          this.realData.push({
            id: e.id,
            name: e.name,
            pos_station: e.pos_station.name,
            pos_station_floor_tables: e.pos_station_floor_tables,
            created: new Date(e.created).toLocaleString("en-GB").toString(),
            updated: new Date(e.updated).toLocaleString("en-GB").toString(),
          });
        });

        // console.log("real data :", this.realData);
      });
    },

    searchFloor(options) {

      this.http.post(`pos-station-floors/search`, { "col": options.col, "search": options.search, "page": 1, "limit": 25, lang: localStorage.current_language }, null, true).then((res) => {
        this.realData = []
        console.log('search res', res)
        res.data.map((e) => {
          this.realData.push({
            id: e.id,
            name: e.name,
            pos_station: e.pos_station.name,
            created: new Date(e.created).toLocaleString("en-GB").toString(),
            updated: new Date(e.updated).toLocaleString("en-GB").toString(),
          });
        });

      })

    },
    deleteFloor(record) {
      this.http.delete(`pos-station-floors`, record.id).then(() => {

        console.log('record :', record)
        Swal.fire("Deleted!", "Your file has been deleted.");

        this.getFloor()
      })
    },


    addFloorTable(id) {
      console.log('ggg :', id)
      const loggeduser = localStorage.pos_station;
      const decoded_user_data = JSON.parse(loggeduser);
      console.log(' ff :', decoded_user_data)
      var select_job = this.Select
      console.log('vv :', select_job)
      var data = {
        title: "menu.dashboard.add_floor",
        inputs: [

          {
            label: 'tables.floor_table.name',
            type: 'text',
            model: 'name',
            required: true,
            invalid_msg: "messages.validation.require_name",
          },
          {
            label: "tables.floor_table.seats",
            type: "number",
            model: "seats",
            required: true,
            invalid_msg: "messages.validation.require_number",
          },


        ],
        buttons: [
          {
            text: "popups.submit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              console.log("OK Clicked", obj);
              obj.pos_station_id = decoded_user_data.pos_station_id
              obj.floor_id = id
              this.http.post(`pos-station-floor-tables`, obj, null, true).then((res) => {
                console.log(res.data)
                Swal.fire(this.$t("messages.success")).then(() => {
                  this.getFloorTable(this.floor_id)

                })
              })

            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "light",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);

    },


    getFloorTable(id) {
      const loggeduser = localStorage.pos_station;
      const decoded_user_data = JSON.parse(loggeduser);
      // i empty the table content every time i call pos-user api
      this.http.post(`pos-station-floor-tables/paginate`,
        {
          page: this.floorTablePage,
          limit: this.floorTableLimit,
          floor_id: id,
          pos_station_id: decoded_user_data.pos_station_id
        }
        , null, true
      ).then((res) => {
        this.floorTableTotal = res.tot
        console.log('floor table total :', this.floorTableTotal)
        console.log("res :", res.data);
        this.floorTables = res.data


        // console.log("real data :", this.realData);
      });
    },

    editFloorTable(options) {
      console.log('edit options :', options)
      var select_job = this.Select
      console.log('vv :', select_job)
      var data = {
        title: "menu.dashboard.edit_floor",
        inputs: [

          {
            label: 'name',
            type: 'text',
            model: 'name',
            required: true,
            value: options.name,
            invalid_msg: "messages.validation.require_name",
          },
          {
            label: "seats",
            type: "number",
            model: "seats",
            required: true,
            value: options.seats,
            invalid_msg: "messages.validation.require_number",
          },





        ],
        buttons: [
          {
            text: "Add",
            closer: true,
            color: "primary",
            handler: (obj) => {
              console.log("OK Clicked", obj);
              obj.floor_id = options.floor_id
              this.http.put(`pos-station-floor-tables`, options.id, obj).then((res) => {

                console.log(res.data)
                Swal.fire(this.$t("messages.success")).then(() => {
                  this.getFloorTable(this.floor_id)
                })
              })

            },
          },
          {
            text: "قفل",
            closer: true,
            color: "secondary",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);

    },

    searchFloorTableFun(options) {
      const loggeduser = localStorage.pos_station;
      const decoded_user_data = JSON.parse(loggeduser);
      this.http.post(`pos-station-floor-tables/search`, {
        "floor_id": options.id,
        "col": options.col,
        "search": options.search,
        "page": 1,
        "limit": 25,
        "pos_station_id": decoded_user_data.pos_station_id,
        "lang": localStorage.current_language
      }
      , null, true
      ).then((res) => {
        
        console.log('search res', res)
        this.floorTableTotal = res.tot
        this.floorTables = res.data
      })

    },
    deleteFloorTable(record) {
      this.http.delete(`pos-station-floor-tables`, record.id).then(() => {

        console.log('record :', record)
        Swal.fire("Deleted!", "Your file has been deleted.").then(()=>{

          this.getFloorTable(this.floor_id)

        });

      })
    },


  },


};
</script>
    