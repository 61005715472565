<template>
  <!-- Main Wrapper -->

        <pageaddheader :title="title" :title1="title1" />

        <!-- /product list -->
        <div class="card">
          <div class="card-body">
            <div class="table-top">
              <div class="search-set">
                
                <div class="search-input">
                  <a class="btn btn-searchset"><img src="/assets/img/icons/search-white.svg" alt="img" /></a>
                  <div id="DataTables_Table_0_filter" class="dataTables_filter">
                    <label>
                      <input type="search" class="form-control form-control-sm"
                        :placeholder="$t('purchases.purchase_order.search')" aria-controls="DataTables_Table_0" />
                    </label>
                  </div>
                </div>
              </div>
              <div class="wordset">
                <a class="btn btn-add-modal" href="javascript:void(0);" data-bs-toggle="modal"
                  data-bs-target="#add-purchase">
                  <vue-feather type="plus" />
                </a>
              </div>
            </div>
            <!-- /Filter -->
            
            <!-- /Filter -->
            <table style="table-layout: auto">
              <thead class="ant-table-thead">
                <tr>
                  

                  

                  <th class="ant-table-cell ant-table-column-has-sorters" colstart="2" colend="2">
                    <!----><!---->
                    <span class="ant-table-column-title">{{
                      $t("purchases.purchase_order.supplier")
                    }}</span>
                    <!---->
                  </th>

                  <th class="ant-table-cell ant-table-column-has-sorters" colstart="2" colend="2">
                    <!----><!---->
                    <span class="ant-table-column-title">{{
                      $t("purchases.purchase_order.total")
                    }}</span>
                    <!---->
                  </th>

                  <th class="ant-table-cell ant-table-column-has-sorters" colstart="2" colend="2">
                    <!----><!---->
                    <span class="ant-table-column-title">{{
                      $t("purchases.purchase_order.actions")
                    }}</span>
                    <!---->
                  </th>
                </tr>
              </thead>
              <tbody class="ant-table-tbody">
                <!---->
                <tr v-for="(purchase, index) in purchases" :key="purchase.id" class="ant-table-row ant-table-row-level-0">
                  

                  <td class="ant-table-cell">
                    <!---->{{ (index += 1)
                    }}<!---->
                  </td>

                  <td class="ant-table-cell">
                    <!---->{{ purchase?.supplier?.name
                    }}<!---->
                  </td>

                  <td class="ant-table-cell">
                    <!---->{{
                      `${purchase?.pos_purchases_order_detailes?.reduce(
                        (a, obj) => a + +obj?.qty * +obj?.unit_price,
                        0
                      )} $`
                    }}<!---->
                  </td>

                  <td class="ant-table-cell d-flex justify-content-start">
                    <a class="mx-2" @click="setModalData(purchase)" href="javascript:void(0);" data-bs-toggle="modal"
                      data-bs-target="#order-details">
                      <vue-feather type="eye" stroke="blue" />
                    </a>

                    <a class="mx-2" v-if="!purchase.excution_time" @click="setModalData(purchase)"
                      href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#edit-purchase">
                      <vue-feather type="edit" stroke="black" />
                    </a>

                    <a v-if="!purchase.excution_time" href="javascript:void(0);" @click="deleteItem(purchase)">
                      <vue-feather type="trash" stroke="red" />
                    </a>
                  </td>
                </tr>

                <!---->
              </tbody>
              <!---->
            </table>
          </div>
        </div>
        <!-- /product list -->

        <a-pagination class="d-flex justify-content-center mt-4" @change="onChange" v-model:current="page"
          v-model:pageSize="limit" :total="total" />

        <!-- add modal -->

        <!-- Main Wrapper -->
        <div class="modal fade" id="add-purchase" tabindex="-1" aria-hidden="true">
          <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">
                  {{ $t("purchases.purchase_order.add") }}
                </h5>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div class="modal-body">
                <form @submit.prevent="add()">
                  <div class="row py-4">
                    <div class="col-12">
                      <div class="form-group">
                        <label>{{
                          $t("purchases.purchase_order.supplier")
                        }}</label>
                        <select v-model="data.supplier_id" class="form-control" name="" id="">
                          <option :value="supplier.id" v-for="supplier in suppliers" :key="supplier.id">
                            {{ supplier?.name }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <span>
                      {{ $t("purchases.purchase_order.products") }}
                    </span>
                    <div class="col-12" v-for="(product, index) in data.products" :key="product">
                      <div class="d-flex align-items-end justify-content-between mb-4">
                        <!-- product select -->
                        <div>
                          <div class="d-flex align-items-end">
                            <div class="form-group me-2">
                              <label>{{
                                $t("purchases.purchase_order.category")
                              }}</label>
                              <select v-model="product.category" class="form-control" name="" id="">
                                <option :value="category" v-for="category in categories" :key="category.id">
                                  {{ category?.name }}
                                </option>
                              </select>
                            </div>

                            <div class="form-group" v-if="product.category">
                              <label>{{
                                $t("purchases.purchase_order.product_name")
                              }}</label>
                              <select v-model="product.product_id" class="form-control" name="" id="">
                                <option :value="pos_product.id" v-for="pos_product in product?.category
                                  ?.pos_products" :key="pos_product.id">
                                  {{ pos_product?.name }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="d-flex align-items-end">
                            <div class="form-group mb-0 me-2">
                              <label>{{
                                $t("purchases.purchase_order.qty")
                              }}</label>
                              <input class="form-control" v-model="product.qty" type="number" />
                            </div>

                            <div class="form-group mb-0">
                              <label>{{
                                $t("purchases.purchase_order.unit_price")
                              }}</label>
                              <input class="form-control" v-model="product.unit_price" type="number" />
                            </div>
                          </div>
                        </div>

                        <!-- quantaty & unit price select -->

                        <div class="wordset">
                          <a class="btn btn-add-modal" href="javascript:void(0);" @click="data.products.push({})">
                            <vue-feather type="plus" />
                          </a>
                          <a @click="remove_item(index, 'add')" class="btn btn-delete mx-2" href="javascript:void(0);">
                            <vue-feather type="trash" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <button type="submit" class="btn btn-submit me-2">
                      {{ $t("popups.submit") }}
                    </button>
                    <a class="btn btn-cancel add-cancel" data-bs-dismiss="modal">{{ $t("popups.cancel") }}</a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <!-- end add modal -->

        <!-- start edit modal -->
        <!-- Main Wrapper -->
        <div class="modal fade" id="edit-purchase" tabindex="-1" aria-hidden="true">
          <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">
                  <span>
                    {{ $t("purchases.purchase_order.edit") }}
                  </span>
                </h5>
                <button @click="
                  data = {
                    products: [{}],
                  }
                  " type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div class="modal-body">
                <form @submit.prevent="edit()">
                  <div class="row py-4">
                    <div class="col-12">
                      <div class="form-group">
                        <label>{{
                          $t("purchases.purchase_order.supplier")
                        }}</label>
                        <select v-model="data.supplier_id" class="form-control" name="" id="">
                          <option :value="supplier.id" v-for="supplier in suppliers" :key="supplier.id">
                            {{ supplier?.name }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="col-12" v-for="(
                        product, index
                      ) in data.pos_purchases_order_detailes" :key="product">
                      <span>
                        {{ $t("purchases.purchase_order.products") }}
                      </span>
                      <div class="d-flex align-items-end justify-content-between mb-4">
                        <!-- product select -->
                        <div>
                          <div class="d-flex align-items-end">
                            <div class="form-group me-2">
                              <label>{{
                                $t("purchases.purchase_order.category")
                              }}</label>
                              <select @change="
                                set_category_products(
                                  index,
                                  product.product.pos_category_id
                                )
                                " v-model="product.product.pos_category_id" class="form-control" name="" id="">
                                <option :value="category.id" v-for="category in categories" :key="category.id">
                                  {{ category?.name }}
                                </option>
                              </select>
                            </div>

                            <div class="form-group" v-if="product.category_products">
                              <label>{{
                                $t("purchases.purchase_order.product_name")
                              }}</label>
                              <select v-model="product.product_id" class="form-control" name="" id="">
                                <option :value="pos_product.id" v-for="pos_product in product?.category_products"
                                  :key="pos_product.id">
                                  {{ pos_product?.name }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="d-flex align-items-end">
                            <div class="form-group mb-0 me-2">
                              <label>{{
                                $t("purchases.purchase_order.qty")
                              }}</label>
                              <input class="form-control" v-model="product.qty" type="number" />
                            </div>

                            <div class="form-group mb-0">
                              <label>{{
                                $t("purchases.purchase_order.unit_price")
                              }}</label>
                              <input class="form-control" v-model="product.unit_price" type="number" />
                            </div>
                          </div>
                        </div>

                        <!-- quantaty & unit price select -->

                        <div class="wordset">
                          <a class="btn btn-add-modal" href="javascript:void(0);" @click="
                            data.pos_purchases_order_detailes.push({
                              product: {},
                            })
                            ">
                            <vue-feather type="plus" />
                          </a>
                          <a @click="remove_item(index, 'edit')" class="btn btn-delete mx-2" href="javascript:void(0);">
                            <vue-feather type="trash" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <button type="submit" class="btn btn-submit me-2">
                      {{ $t("popups.submit") }}
                    </button>
                    <a class="btn btn-cancel edit-cancel" data-bs-dismiss="modal">{{ $t("popups.cancel") }}</a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <!-- end edit modal -->

        <!-- start edit modal -->
        <!-- Main Wrapper -->
        <div class="modal fade" id="order-details" tabindex="-1" aria-hidden="true">
          <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">
                  {{ $t("purchases.purchase_order.details") }}
                </h5>
                <button @click="
                  data = {
                    products: [{}],
                  }
                  " type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div class="modal-body">
                <!-- /Filter -->
                <table class="print_order" style="table-layout: auto">
                  <thead class="ant-table-thead">
                    <tr>


                      <!-- detail id -->
                      <th class="ant-table-cell ant-table-column-has-sorters" colstart="2" colend="2">
                        <!----><!---->
                        <span class="ant-table-column-title">{{
                          $t("purchases.purchase_order.id")
                        }}</span>
                        <!---->
                      </th>

                      <!-- product name -->
                      <th class="ant-table-cell ant-table-column-has-sorters" colstart="2" colend="2">
                        <!----><!---->
                        <span class="ant-table-column-title">
                          {{ $t("purchases.purchase_order.product") }}
                        </span>
                        <!---->
                      </th>

                      <!-- product quantity -->
                      <th class="ant-table-cell ant-table-column-has-sorters" colstart="2" colend="2">
                        <!----><!---->
                        <span class="ant-table-column-title">
                          {{ $t("purchases.purchase_order.qty") }}
                        </span>
                        <!---->
                      </th>

                      <!-- unit price -->
                      <th class="ant-table-cell ant-table-column-has-sorters" colstart="2" colend="2">
                        <!----><!---->
                        <span class="ant-table-column-title">
                          {{ $t("purchases.purchase_order.unit_price") }}
                        </span>
                        <!---->
                      </th>

                      <!-- subtotal -->
                      <th class="ant-table-cell ant-table-column-has-sorters" colstart="2" colend="2">
                        <!----><!---->
                        <span class="ant-table-column-title">
                          {{ $t("purchases.purchase_order.sub_total") }}
                        </span>
                        <!---->
                      </th>
                    </tr>
                  </thead>
                  <tbody class="ant-table-tbody">
                    <!---->
                    <tr v-for="(
                        purchase, index
                      ) in data?.pos_purchases_order_detailes" :key="purchase.id"
                      class="ant-table-row ant-table-row-level-0">
                     

                      <td class="ant-table-cell">
                        <!---->{{ index + 1
                        }}<!---->
                      </td>

                      <td class="ant-table-cell">
                        <!---->{{ purchase?.product?.name
                        }}<!---->
                      </td>

                      <td class="ant-table-cell">
                        <!---->{{ purchase?.qty
                        }}<!---->
                      </td>

                      <td class="ant-table-cell">
                        <!---->{{ `${purchase?.unit_price} $`
                        }}<!---->
                      </td>

                      <td class="ant-table-cell">
                        <!---->{{ `${purchase?.unit_price * purchase?.qty} $`
                        }}<!---->
                      </td>
                    </tr>

                    <!-- total row -->
                    <tr class="ant-table-row ant-table-row-level-0">
                    
                      <td class="ant-table-cell"></td>

                      <td class="ant-table-cell"></td>
                      <td class="ant-table-cell"></td>
                      <td class="ant-table-cell">
                        <b>
                          {{ $t("purchases.purchase_order.total") }}
                        </b>
                      </td>

                      <td class="ant-table-cell">
                        <!---->{{
                          `${data?.pos_purchases_order_detailes?.reduce(
                            (a, obj) => a + +obj?.qty * +obj?.unit_price,
                            0
                          )} $`
                        }}<!---->
                      </td>
                    </tr>

                    <!---->
                  </tbody>
                  <!---->
                </table>
                <div class="col-lg-12">
                  <button v-if="!data.excution_time" @click="completeOrder" type="submit" class="btn btn-submit me-2">
                    {{ $t("popups.complete_order") }}
                  </button>

                  <button @click="print_order()" type="submit" class="btn btn-submit me-2">
                    {{ $t("popups.print") }}
                  </button>
                  <a class="btn btn-cancel complete-cancel" data-bs-dismiss="modal">{{ $t("popups.cancel") }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- end edit modal -->
   
</template>

<script>
export default {
  data() {
    return {
      page: 1,
      limit: 10,
      total: 0,
      filter: false,
      title: this.$t("purchases.purchase_order.title"),
      title1: this.$t("purchases.purchase_order.sub_title"),
      purchases: [],
      suppliers: [],
      categories: [],
      category_products: [],
      data: {
        products: [{}],
      },
    };
  },
  methods: {
    async deleteItem(purchase) {
      // Use sweetalert2
      this.$swal
        .fire({
          title: this.$t("messages.alert.are_you_sure"),
          text: this.$t("messages.alert.unrevertable"),
          showCancelButton: true,
          confirmButtonColor: "#FF9F43",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.http.delete("pos-purchases-order", purchase.id).then((res) => {
              this.getPurchases();
              this.$swal.fire("Deleted!", "Your file has been deleted.");
            });
          }
        });
    },
    async add() {
      console.log(this.data);
      const data = {
        order_data: {
          supplier_id: this.data.supplier_id,
        },
        order_details_data: this.data.products.map((product) => {
          return {
            product_id: product.product_id,
            qty: product.qty,
            unit_price: product.unit_price,
          };
        }),
      };

      const response = await this.http.post(
        "pos-purchases-order/create-order",
        data, null, true
      );
      this.getPurchases();

      document.querySelector(".btn-cancel").click();

      this.data = {
        products: [{}],
      };
      console.log(response);
    },
    async print_order() {
      const table = document.querySelector(".print_order");
      console.log(table);

      const response = await this.http.post("pos-purchases-order/print-order", {
        table: table.toString(),
      }, null, true
      );

      console.log(response);
    },
    async edit() {
      let order_details = this.data.pos_purchases_order_detailes.map((item) => {
        return {
          id: item.id,
          purchases_order_id: this.data.id,
          product_id: item.product_id,
          qty: item.qty,
          unit_price: item.unit_price,
        };
      });
      let removable_items_ids = this.data.pos_purchases_order_detailes.map(
        (item) => {
          return item.id ? item.id : 0;
        }
      );
      const order_edited_data = {
        order: {
          id: this.data.id,
          supplier_id: this.data.supplier_id,
        },
        removable_items_ids,
        order_details,
      };
      this.http
        .post("pos-purchases-order/update-order", order_edited_data)
        .then((resp) => {
          console.log(resp);
          this.getPurchases();

          document.querySelector(".edit-cancel").click();

          this.data = {
            products: [{}],
          };
        });
      console.log(order_edited_data);
    },
    async completeOrder() {
      const order_data = {
        order_id: this.data.id,
        products: this.data.pos_purchases_order_detailes.map((item) => {
          return { product_id: item.product_id, qty: item.qty };
        }),
      };
      this.http
        .post("pos-purchases-order/complete-order", order_data)
        .then((resp) => {
          console.log(resp);
          this.getPurchases();

          document.querySelector(".complete-cancel").click();

          this.data = {
            products: [{}],
          };
        });
    },
    set_category_products(index, id) {
      const category = this.categories.filter((category) => category.id == id);
      console.log(category[0].pos_products);
      this.data.pos_purchases_order_detailes[index].category_products =
        category[0].pos_products;
    },
    remove_item(index, type) {
      if (type == "add") {
        if (this.data.products.length == 1) {
          this.data.products.splice(index, 1);

          this.data = {
            products: [{}],
          };
        } else {
          this.data.products.splice(index, 1);
        }
      } else {
        if (this.data.pos_purchases_order_detailes.length == 1) {
          this.data.pos_purchases_order_detailes.splice(index, 1);

          this.data = {
            pos_purchases_order_detailes: [{}],
          };
        } else {
          this.data.pos_purchases_order_detailes.splice(index, 1);
        }
      }
    },
    setModalData(order) {
      // for make edit updated after update data
      this.data = Object.assign({}, order);
      this.data.pos_purchases_order_detailes.forEach((product, index) => {
        this.set_category_products(index, product?.product?.pos_category_id);
      });
    },
    async getPurchases() {
      this.http
        .post(`pos-purchases-order/paginate`, {
          page: this.page,
          limit: this.limit,
        })
        .then((res) => {
          this.total = res.tot;
          this.purchases = res.data;
        });
      console.log({ purchases: this.purchases });
    },
    async getSuppliers() {
      const res = await this.http.get("pos-suppliers", null, null, true);
      this.suppliers = res.data;
    },
    async getCategories() {
      const res = await this.http.get("pos-categories", null, null, true);
      this.categories = res.data;
    },
    search(options) {
      this.http
        .post(`pos-purchases-order/search`, {
          col: options.col,
          search: options.search,
          page: 1,
          limit: 25,
          lang: localStorage.current_language,
        })
        .then((res) => {
          this.purchases = res.data;
        });
    },
    onChange(pageNumber, pageSize) {
      console.log("page :", pageNumber, "total :", pageSize);
      this.getPurchases(); /* add your function that render you table data */
    },
  },
  created() {
    this.getPurchases();
    this.getSuppliers();
    this.getCategories();
  },
  name: "PurshcesList",
};
</script>
