export default [
  {
    path: "payroll_management",
    component: () =>
      import("../../views/pages/payroll/PayrollManagementPage.vue"),
    meta: {
      headerClass: "header",
      sidebarClass: "",
      auth_roles: ["admin", "manager"],
      auth_type: ["pos_user"],
    },
  },

  {
    path: "pos_salary_item_degrees",
    component: () =>
      import("../../views/pages/payroll/salaryItems/SalaryItemsDegree.vue"),
    meta: {
      headerClass: "header",
      sidebarClass: "",
      auth_roles: ["admin", "manager"],
      auth_type: ["pos_user"],
    },
  },

  {
    path: "pos_salary_item_degrees/:id",
    component: () =>
      import("../../views/pages/payroll/salaryItems/ItemsDegree.vue"),
    meta: {
      headerClass: "header",
      sidebarClass: "",
      auth_roles: ["admin", "manager"],
      auth_type: ["pos_user"],
    },
  },
  {
    path: "pos_salary_items",
    component: () =>
      import("../../views/pages/payroll/salaryItems/SalaryItems.vue"),
    meta: {
      headerClass: "header",
      sidebarClass: "",
      auth_roles: ["admin", "manager"],
      auth_type: ["pos_user"],
    },
  },
];
