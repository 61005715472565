import priceList from "../../../views/pages/pos station price list/priceList.vue";
import priceListProducts from "../../../views/pages/pos price list products/priceListProducts.vue";

export default [
  {
    path: "price-list",
    name: "priceList",
    component: priceList,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      auth_roles: ["admin", "hr_manager"],
      auth_type: ["pos_user"],
    },
  },
  {
    path: "/price-list-products/:id",
    name: "priceListProducts",
    component: priceListProducts,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      auth_roles: ["admin", "hr_manager"],
      auth_type: ["pos_user"],
    },
  },
];
